import styled from 'styled-components';
import CheckboxField from '../../../../../../components/CheckboxField';
import { AllCrewWorkGroups } from './filterConstants';

const OptionsContainer = styled.div`
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-wrap: break-word;
`;

const Container = styled.div`
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
`;

interface CrewWorkGroupFilterProps {
  selectedWorkCrew: string[];
  onChange: (state: string, checked: boolean) => void;
}

const CrewWorkGroupFilter = ({ selectedWorkCrew, onChange }: CrewWorkGroupFilterProps) => {
  const handleChange = (state: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(state, e.target.checked);
  };

  const Options = AllCrewWorkGroups.map(workGroup => {
    return (
      <Container>
        <CheckboxField
          id={workGroup}
          label={workGroup}
          checked={selectedWorkCrew.includes(workGroup)}
          onChange={handleChange(workGroup)}
        />
      </Container>
    );
  });

  return <OptionsContainer>{Options}</OptionsContainer>;
};

export default CrewWorkGroupFilter;
