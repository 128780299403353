import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Page from '../../../components/Page';
import { Downloader } from '../../../hooks/downloader';
import { WalkdownsProvider } from '../../../hooks/walkdowns';
import { WalkdownReviewProvider } from './hooks/useWalkdownReview';
import SingleWalkdownReview from './SingleWalkdownReview';
import WalkdownReviewInternal from './WalkdownReviewPage';
import isFeatureEnabled from '../../../utils/feature-flags';
import { BreadcrumbBroadcaster } from '../../../context/breadcrumbs';

const WalkdownReviewPage = ({ match }: any) => (
  <>
    <BreadcrumbBroadcaster pushCrumb="Walkdown review" />
    <Page title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Walkdown review'}>
      <Downloader>
        <WalkdownReviewProvider>
          <Switch>
            <Route path={`${match.path}/:walkdownId`}>
              <Downloader>
                <WalkdownsProvider>
                  <SingleWalkdownReview />
                </WalkdownsProvider>
              </Downloader>
            </Route>
            <Route
              path={`${match.path}/`}
              component={WalkdownReviewInternal}
            />
          </Switch>
        </WalkdownReviewProvider>
      </Downloader>
    </Page>
  </>
);

export default WalkdownReviewPage;
