import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useState, useEffect, useCallback, useRef } from 'react';
import api from '../../../../api';

export type SearchColumnOptions =
  | 'wonum'
  | 'parent'
  | 'location'
  | 'wopriority'
  | 'unit'
  | 'worktype'
  | 'bpplanningctr'
  | 'bpschedulebacklog'
  | 'specifications'
  | 'bpspm'
  | 'crewworkgroup'
  | 'schedstart';

export interface SearchAutoCompleteFilterProps {
  onChange: (newValue: string[]) => void;
  label: string;
  searchColumn: SearchColumnOptions;
}

export const SearchAutoCompleteFilter = ({ onChange, label, searchColumn }: SearchAutoCompleteFilterProps) => {
  const [options, setOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(
    async (inputValueToFetch: string, searchColumnToFetch: SearchColumnOptions) => {
      setLoading(true);
      const ret = await api.cwt.searchTasks(inputValueToFetch, searchColumnToFetch, 50);
      setLoading(false);
      setOptions(ret);
    },
    [api]
  );

  useEffect(() => {
    fetchData(inputValue, searchColumn);
  }, [fetchData]);

  const useThrottle = (callback: any, delay: any) => {
    const lastCall = useRef(0);

    return (...args: any) => {
      const now = Date.now();
      if (now - lastCall.current >= delay) {
        lastCall.current = now;
        callback(...args);
      }
    };
  };

  const throttledFetchData = useThrottle(fetchData, 750);

  useEffect(() => {
    throttledFetchData(inputValue, searchColumn);
  }, [inputValue, searchColumn]);

  return (
    <Autocomplete
      multiple
      freeSolo={false}
      options={options}
      getOptionLabel={option => option.label}
      loading={loading}
      value={selectedValues}
      onChange={(_, newValues) => {
        setSelectedValues(newValues);
        onChange(newValues.map(value => value.label));
      }}
      onInputChange={(_, value) => setInputValue(value)}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
