import { FormControl, Grid, InputLabel, MenuItem, Paper, Select } from '@material-ui/core';
import Page from '../../../../components/Page';
import useMyAssignments from '../../../../hooks/my-assignments';
import useSelectedItem from '../../../../hooks/selected-item';
import ElementFilterPanel from '../../../../components/ElementFilterPanel';
import { ElementIndicatorsHealthTrendChart } from './ElementIndicatorsHealthTrendChart';
import { useState } from 'react';
import isFeatureEnabled from '../../../../utils/feature-flags';
import { BreadcrumbBroadcaster } from '../../../../context/breadcrumbs';

const HealthTrendsPage = () => {
  const currentYears = [new Date().getFullYear(), new Date().getFullYear() - 1, new Date().getFullYear() - 2];
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const { myAssignments } = useMyAssignments();
  const { selectedItem: selectedAssignment, selectItem: selectAssignment } = useSelectedItem(
    myAssignments,
    'elementId'
  );
  return (
    <>
      <BreadcrumbBroadcaster pushCrumb="Health Trends" />
      <Page
        title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Health Trends'}
        bodyComponent={<Paper square />}
      >
        <ElementFilterPanel
          elements={myAssignments}
          selectedElement={selectedAssignment ?? undefined}
          onChangeElement={selectAssignment}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Year</InputLabel>
              <Select
                defaultValue={selectedYear}
                onChange={e => setSelectedYear(e.target.value as number)}
              >
                {currentYears.map(year => (
                  <MenuItem
                    key={year}
                    value={year}
                  >
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </ElementFilterPanel>
        {selectedAssignment && selectedYear && (
          <ElementIndicatorsHealthTrendChart
            selectedAssignment={selectedAssignment}
            selectedYear={selectedYear}
          />
        )}
      </Page>
    </>
  );
};

export default HealthTrendsPage;
