import { ScheduleInputs } from '../../../types/cwt/schedule';
import { Path, Remote } from '../../types';

const getEntireSchedule = (path: Path, remote: Remote) => (workorderId?: number, minStart?: Date, maxEnd?: Date) =>
  remote.get(path(`/`), { params: { workorderId, minStart, maxEnd } });

const getAllScheduleStatuses = (path: Path, remote: Remote) => () => remote.get(path(`/statuses`));

const createSchedule = (path: Path, remote: Remote) => (schedule: ScheduleInputs) =>
  remote.post(path(`/`), { schedule });

const updateSchedule = (path: Path, remote: Remote) => (schedule: ScheduleInputs) =>
  remote.put(path(`/${schedule.id}`), { schedule });

const removeSchedule = (path: Path, remote: Remote) => (scheduleId: number) => remote.del(path(`/${scheduleId}`));

const getAllScheduleNotes = (path: Path, remote: Remote) => (workorderId: number) =>
  remote.get(path(`/notes/${workorderId}`));

const createScheduleNotes = (path: Path, remote: Remote) => (workorderId: number, note: string) =>
  remote.post(path(`/notes/${workorderId}`), { note });

const schedule = (path: Path, remote: Remote) => ({
  getEntireSchedule: getEntireSchedule(path, remote),
  createSchedule: createSchedule(path, remote),
  updateSchedule: updateSchedule(path, remote),
  getAllScheduleStatuses: getAllScheduleStatuses(path, remote),
  removeSchedule: removeSchedule(path, remote),
  getAllScheduleNotes: getAllScheduleNotes(path, remote),
  createScheduleNotes: createScheduleNotes(path, remote),
});

export default schedule;
