import { format, startOfWeek } from 'date-fns';
import { TPlusOneWorkOrderFilters, WorkOrder, WorkOrderFilters } from '../../../types/cwt/workorder';
import isFeatureEnabled from '../../../utils/feature-flags';

export const downloadBlob = (blob: Blob, filename: string) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${filename}.csv`;
  link.click();
};

export const generateMaximoLink = (id: string, type: string) => {
  return `https://prod-maximo.corp.brucepower.com/maximo/ui/maximo.jsp?event=loadapp&value=${type}&uniqueid=${id}`;
};

export const filterByField = <T extends { toString: () => string }>(field: string[], value: T) => {
  return field.length === 0 || (value && field.includes(value.toString()));
};

export const filterByArrayField = <T>(field: string[], values: T[], key: keyof T) => {
  return (
    field.length === 0 ||
    values.some(value => {
      const fieldValue = value[key];
      return fieldValue && field.includes(fieldValue.toString());
    })
  );
};

export const filterBySubArrayField = <T, S>(field: string[], values: T[], key: keyof T, sub_key: keyof S) => {
  return (
    field.length === 0 ||
    values.some(value => {
      const fieldValue = value[key];
      return (
        Array.isArray(fieldValue) &&
        fieldValue.some((sub_value: S) => {
          const subFieldValue = sub_value[sub_key];
          return subFieldValue && field.includes(subFieldValue.toString());
        })
      );
    })
  );
};

const filterMondayToSunday = (field: string, values: string[]): boolean => {
  if (values.length === 0) return true; // No filters, include everything
  const date = new Date(field);
  const fieldMonday = format(startOfWeek(date, { weekStartsOn: 1 }), 'ddMMMyyyy').toUpperCase();
  return values.includes(fieldMonday);
};
const checkWorkOrderFlags = (wo: WorkOrder, filters: WorkOrderFilters) => {
  const readyConditions = [wo.assessingReady, wo.engReady, wo.scReady, wo.opsReady, wo.otherReady];

  const {
    readyOnly,
    readyForVerification,
    waitingAssessing,
    waitingEng,
    waitingSc,
    waitingOps,
    waitingOther,
    verifiedReady,
  } = filters;

  const flags = [
    { filter: waitingAssessing, flag: !wo.assessingReady },
    { filter: waitingEng, flag: !wo.engReady },
    { filter: waitingSc, flag: !wo.scReady },
    { filter: waitingOps, flag: !wo.opsReady },
    { filter: waitingOther, flag: !wo.otherReady },
  ];

  if (isFeatureEnabled('cwtWorkorderVerification')) {
    const readyForVerificationCheck = !readyForVerification || readyConditions.every(cond => cond);

    const flagChecks = flags.every(({ filter, flag }) => !filter || flag);

    if (readyForVerification && !verifiedReady) {
      return readyForVerificationCheck && flagChecks && !wo.woReady;
    }

    return readyForVerificationCheck && flagChecks;
  } else {
    const readyOnlyCheck = !readyOnly || readyConditions.every(cond => cond);

    const flagChecks = flags.every(({ filter, flag }) => !filter || flag);

    return readyOnlyCheck && flagChecks;
  }
};

const checkWorkOrderHighPriority = (wo: WorkOrder, filters: WorkOrderFilters) => {
  if (!filters.highPriority) return true;
  const result = checkHighPriority(wo);
  return result;
};

export const checkHighPriority = (wo: Partial<WorkOrder>) => {
  const result = (wo.facilityId === 'ba' && BAHighPriority(wo)) || (wo.facilityId === 'bb' && BBHighPriority(wo));
  return result;
};

const BAHighPriority = (wo: Partial<WorkOrder>) => {
  if (!wo.bpplanningctr || !wo.specifications || !wo.wopriority || !wo.worktype) return false;

  const BAisPriorityBp = ['OPB', 'OPA'].includes(wo.bpplanningctr);
  const BAisHighPriorityWork = [1, 2].includes(wo.wopriority) || ['CC', 'DC'].includes(wo.worktype);
  const BAisHighPrioritySpec = wo.specifications.some(spec => ['OWA', 'OWB', 'CRE'].includes(spec));
  const BruceACriteria = BAisPriorityBp && (BAisHighPriorityWork || BAisHighPrioritySpec);
  return BruceACriteria;
};

const BBHighPriority = (wo: Partial<WorkOrder>) => {
  if (!wo.bpplanningctr || !wo.specifications || !wo.wopriority || !wo.worktype) return false;

  const BBisPriorityBp = ['OPB', 'OPA'].includes(wo.bpplanningctr);
  const BBisHighPriorityWork = [1, 2].includes(wo.wopriority) || ['CC', 'DC', 'CN'].includes(wo.worktype);
  const BBisHighPrioritySpec = wo.specifications.some(spec => ['OWA', 'OWB', 'CRE', 'GVA'].includes(spec));
  const BBHighPriorityDNs = ['DN'].includes(wo.worktype) && wo.specifications.some(spec => ['OPT'].includes(spec));
  const BruceBCriteria = BBisPriorityBp && (BBisHighPriorityWork || BBisHighPrioritySpec || BBHighPriorityDNs);
  return BruceBCriteria;
};

const checkSchedStart = (wo: WorkOrder, filters: WorkOrderFilters) => {
  if (filters.schedstart.length === 0) return true;
  const woDate = new Date(wo.schedstart);
  return filters.schedstart.some((range: string) => {
    const [start, end] = range.split(' to ').map((date: string | Date) => new Date(date));
    return woDate >= start && woDate <= end;
  });
};

const checkWoReady = (wo: WorkOrder, filters: WorkOrderFilters) => {
  if (!filters.verifiedReady || filters.readyForVerification) return true;
  return wo.woReady;
};

export const checkWorkOrderFilters = (wo: WorkOrder, filters: WorkOrderFilters) => {
  const {
    wonum,
    unit,
    wopriority,
    worktype,
    status,
    crewworkgroup,
    bpplanningctr,
    location,
    bpschedulebacklog,
    workorderActionFlags,
    specifications,
    workorderFlags,
  } = filters;

  return (
    filterByField(wonum, wo.wonum) &&
    filterByField(unit, wo.unit) &&
    filterByField(wopriority, wo.wopriority) &&
    filterByField(worktype, wo.worktype) &&
    filterByField(status, wo.status) &&
    filterByField(crewworkgroup, wo.crewworkgroup) &&
    filterByField(bpplanningctr, wo.bpplanningctr) &&
    filterByField(location, wo.location) &&
    filterByField(bpschedulebacklog, wo.bpschedulebacklog) &&
    filterByArrayField(workorderFlags, wo.workorderFlags, 'label') &&
    filterBySubArrayField(workorderActionFlags, wo.actions, 'flags', 'label') &&
    (specifications.length === 0 || specifications.some((flag: string) => wo.specifications.includes(flag))) &&
    checkSchedStart(wo, filters) &&
    checkWorkOrderFlags(wo, filters) &&
    checkWoReady(wo, filters) &&
    checkWorkOrderHighPriority(wo, filters)
  );
};

export const checkTPlusOneWorkOrderFilters = (wo: WorkOrder, filters: TPlusOneWorkOrderFilters) => {
  const {
    wonum,
    unit,
    wopriority,
    worktype,
    status,
    crewworkgroup,
    bpplanningctr,
    location,
    bpschedulebacklog,

    specifications,
    workorderFlags,
    minLife,
    maxLife,
    delayReasons,
  } = filters;

  return (
    filterByField(wonum, wo.wonum) &&
    filterByField(unit, wo.unit) &&
    filterByField(wopriority, wo.wopriority) &&
    filterByField(worktype, wo.worktype) &&
    filterByField(status, wo.status) &&
    filterByField(crewworkgroup, wo.crewworkgroup) &&
    filterByField(bpplanningctr, wo.bpplanningctr) &&
    filterByField(location, wo.location) &&
    filterByField(bpschedulebacklog, wo.bpschedulebacklog) &&
    filterByArrayField(workorderFlags, wo.workorderFlags, 'label') &&
    filterByArrayField(delayReasons, wo.woDelayReasons, 'label') &&
    (specifications.length === 0 || specifications.some((flag: string) => wo.specifications.includes(flag))) &&
    (minLife.length === 0 || wo.totallife >= minLife[0]) &&
    (maxLife.length === 0 || wo.totallife <= maxLife[0]) &&
    filterMondayToSunday(wo.actfinish, filters.compweek)
  );
};
