import Page from '../../../../components/Page';
import { Box, Grid, Tooltip, Typography, FormControlLabel, Switch, IconButton } from '@material-ui/core';
import DownloadButton from '../../../../components/DownloadButton';
import WODetailsModal from '../../components/WODetailsModal';
import { WODetailsModalContextProvider } from '../../hooks/useWODetailsModal';
import styled from 'styled-components';
import TaskTable from './TaskTable';
import { TaskFilters, useAssessing } from '../../hooks/useAssessing';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ActionButton from '../../../../components/ActionButton';
import { SearchAutoCompleteFilter, SearchColumnOptions } from './SearchAutoCompleteFilter';
import AutocompleteFilterInput from '../../components/AutocompleteFilterInput';
import { getOnlineStartOptions } from '../../wo/components/utils';
import { useMemo } from 'react';
import { useWorkOrders } from '../../hooks/useWorkOrders';

const RefreshBacklogIcon = styled(AutorenewIcon)`
  cursor: pointer;
  padding-top: 0;
  color: ${({ theme }) => theme.colours.teals.teal1};
  width: 25px;
  height: 25px;
`;

const WaitSwitch = styled(Switch)`
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: red;
  }
  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: rgba(0, 255, 0, 0.04);
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: red;
  }
`;

const AssessingHeader = () => {
  const {
    workorders,
    woLoading,
    taskLoading,
    exportCSV,
    fetchTasks,
    tasksTotalCount,
    selectedWonum,
    getWorkorderByWonum,
    filtersTasks,
    setFilterTasks,
  } = useAssessing();

  const { workorders: allWorkorders } = useWorkOrders();

  const handleFilterChange = (column: keyof TaskFilters, values: string[]) => {
    const filter = {
      ...filtersTasks,
      [column]: values,
    };
    setFilterTasks(filter);
    fetchTasks();
  };

  const ASSESSING_TASK_FILTERS_TOP: { label: string; column: SearchColumnOptions; component: 'search' | 'online' }[] = [
    { label: 'Task Work Order', column: 'wonum', component: 'search' },
    { label: 'Work Order', column: 'parent', component: 'search' },
    { label: 'Location', column: 'location', component: 'search' },
    { label: 'Online Start', column: 'schedstart', component: 'online' },
    { label: 'Unit', column: 'unit', component: 'search' },
    { label: 'Priority', column: 'wopriority', component: 'search' },
  ];
  const ASSESSING_TASK_FILTERS: { label: string; column: SearchColumnOptions; component: 'search' | 'online' }[] = [
    { label: 'Work Type', column: 'worktype', component: 'search' },
    { label: 'Planning Center', column: 'bpplanningctr', component: 'search' },
    { label: 'Schedule Backlog', column: 'bpschedulebacklog', component: 'search' },
    { label: 'WO Specifications', column: 'specifications', component: 'search' },
    { label: 'SPM', column: 'bpspm', component: 'search' },
    { label: 'Crew', column: 'crewworkgroup', component: 'search' },
  ];
  return (
    <Page title="Assessing">
      <WODetailsModalContextProvider workorder={getWorkorderByWonum(selectedWonum)}>
        <Grid
          container
          spacing={2}
          style={{ marginLeft: '10px', paddingRight: '10px' }}
        >
          <Grid
            item
            xs={3}
            key="count"
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              marginBottom="20px"
              marginTop="10px"
              marginLeft="10px"
            >
              <Box
                width="100%"
                height="100px"
                minWidth={'120px'}
                borderRadius="5px"
                textAlign="center"
                boxShadow="rgb(191, 190, 190) 0px 2px 7px 0px;"
                marginRight="10px"
              >
                <Typography style={{ fontWeight: '500', fontSize: '45px' }}>
                  {woLoading || taskLoading ? '...' : tasksTotalCount}
                </Typography>
                <Typography style={{ fontSize: '12px' }}>Current Backlog</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={9}
            spacing={2}
            key="count"
          >
            {ASSESSING_TASK_FILTERS_TOP.map(({ label, column, component }) => (
              <Grid
                item
                xs={4}
                key={column}
              >
                {component === 'search' && (
                  <SearchAutoCompleteFilter
                    onChange={values => handleFilterChange(column, values)}
                    label={label}
                    searchColumn={column}
                  />
                )}
                {component === 'online' && (
                  <AutocompleteFilterInput
                    label="Online Start"
                    options={useMemo(
                      () =>
                        getOnlineStartOptions(allWorkorders).map(option => ({ ...option, label: option.label || '' })),
                      [workorders]
                    )}
                    onChange={value => {
                      setFilterTasks({ ...filtersTasks, schedstart: value });
                      fetchTasks();
                    }}
                    value={filtersTasks.schedstart}
                    multiple
                    allowInvalidValuesInOptions
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{ marginLeft: '10px', paddingRight: '10px' }}
        >
          {ASSESSING_TASK_FILTERS.map(({ label, column, component }) => (
            <Grid
              item
              xs={3}
              key={column}
            >
              {component === 'search' && (
                <SearchAutoCompleteFilter
                  onChange={values => handleFilterChange(column, values)}
                  label={label}
                  searchColumn={column}
                />
              )}
              {component === 'online' && (
                <AutocompleteFilterInput
                  label="Online Start"
                  options={useMemo(
                    () =>
                      getOnlineStartOptions(allWorkorders).map(option => ({ ...option, label: option.label || '' })),
                    [workorders]
                  )}
                  onChange={value => {
                    setFilterTasks({ ...filtersTasks, schedstart: value });
                    fetchTasks();
                  }}
                  value={filtersTasks.schedstart}
                  multiple
                  allowInvalidValuesInOptions
                />
              )}
            </Grid>
          ))}

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <WaitSwitch
                  checked={filtersTasks.highPriority}
                  onChange={e => {
                    setFilterTasks({
                      ...filtersTasks,
                      highPriority: e.target.checked,
                    });
                    fetchTasks();
                  }}
                  name="highPriority"
                />
              }
              label="High Priority"
            />
          </Grid>
        </Grid>

        <Box
          width={'100%'}
          justifyContent={'space-between'}
          display="flex"
          marginTop={'10px'}
        >
          <Tooltip
            title="Refresh Backlog"
            placement="right"
          >
            <IconButton onClick={fetchTasks}>
              <RefreshBacklogIcon />
            </IconButton>
          </Tooltip>

          <Tooltip
            title="Export CSV"
            placement="top"
          >
            <DownloadButton onClick={exportCSV}></DownloadButton>
          </Tooltip>
        </Box>

        <TaskTable />
        <WODetailsModal />
      </WODetailsModalContextProvider>
      <Box
        width={'100%'}
        justifyContent={'flex-end'}
        display="flex"
        marginTop={'15px'}
      >
        <ActionButton
          color="primary"
          variant="text"
        >
          Save Entry
        </ActionButton>
      </Box>
    </Page>
  );
};

export default AssessingHeader;
