import { ReactNode, createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import Api from '../../../api';
import { WorkOrder } from '../../../types/cwt/workorder';
import { checkHighPriority, downloadBlob, filterByField } from '../utils';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types';
import { useError } from '../components/ErrorContextProvider';
import { FilterBaseType } from '../../../types/cwt/bookmarks';

interface useAssessingValue {
  workorders: Map<number, WorkOrder>;
  filteredWorkorders: WorkOrder[];
  filtersWO: WorkOrderFilters;
  taskFilters: TypeFilterValue;
  setTaskFilters: (filters: TypeFilterValue) => void;
  setWOFilters: (filters: WorkOrderFilters) => void;
  fetchWorkorders: () => void;
  setSelectedWonum: (wonum: string) => void;
  selectedWonum: string | undefined;
  woLoading: boolean;
  getWorkorderByWonum: (wonum: string | undefined) => WorkOrder | undefined;
  gridRef: any;
  setGridRef: (gridRef: any) => void;
  exportCSV: () => void;
  setActiveView: (view: string) => void;
  activeView: string;
  taskLoading: boolean;
  setTaskLoading: (loading: boolean) => void;
  fetchTasks: () => void;
  dataSource: (props: any) => any[];
  tasksTotalCount: number;
  handleSetFilteredWorkorders: (workorders: Map<number, WorkOrder>) => void;
  filtersTasks: TaskFilters;
  setFilterTasks: (filters: TaskFilters) => void;
}

interface Props {
  children: ReactNode;
  wonum?: string;
}
const AssessingContext = createContext<useAssessingValue | undefined>(undefined);
interface WorkOrderFilters {
  wonum: string[];
  assessor: string[];
  wopriority: string[];
  worktype: string[];
  location: string[];
  crewworkgroup: string[];
  spm: string[];
  highPriority: boolean;
}

export interface TaskFilters extends FilterBaseType {
  wonum: string[];
  parent: string[];
  location: string[];
  unit: string[];
  wopriority: string[];
  worktype: string[];
  bpplanningctr: string[];
  bpschedulebacklog: string[];
  schedstart: string[];
  highPriority: boolean;
  specifications: string[];
  bpspm: string[];
  crewworkgroup: string[];
}

export const AssessingContextProvider = ({ children, wonum }: Props) => {
  const [workorders, setWorkorders] = useState<Map<number, WorkOrder>>(new Map());
  const [filteredWorkorders, setFilteredWorkorders] = useState<WorkOrder[]>([]);
  const [selectedWonum, setSelectedWonum] = useState<string | undefined>(wonum ? wonum : undefined);
  const [taskLoading, setTaskLoading] = useState<boolean>(true);
  const [woLoading, setWoLoading] = useState<boolean>(false);
  const [tasksTotalCount, setTasksTotalCount] = useState(0);
  const { handleError } = useError();

  const [activeView, setActiveView] = useState('wo-view');

  const [filtersWO, setWOFilters] = useState<WorkOrderFilters>({
    wonum: [],
    assessor: [],
    wopriority: [],
    worktype: [],
    location: [],
    crewworkgroup: [],
    spm: [],
    highPriority: false,
  });

  const [filtersTasks, setFilterTasks] = useState<TaskFilters>({
    wonum: [],
    parent: [],
    location: [],
    unit: [],
    wopriority: [],
    worktype: [],
    bpplanningctr: [],
    schedstart: [],
    highPriority: false,
    specifications: [],
    bpspm: [],
    crewworkgroup: [],
    bpschedulebacklog: [],
  });

  const defaultFilterValue = [
    { name: 'wonum', type: 'string', operator: 'contains', value: '' },
    { name: 'parent', type: 'string', operator: 'contains', value: '' },
    { name: 'plusplanner', type: 'string', operator: 'contains', value: '' },
    { name: 'wopriority', type: 'string', operator: 'contains', value: '' },
    { name: 'worktype', type: 'string', operator: 'contains', value: '' },
    { name: 'location', type: 'string', operator: 'contains', value: '' },
    { name: 'status', type: 'string', operator: 'contains', value: '' },
    { name: 'crewworkgroup', type: 'string', operator: 'contains', value: '' },
    { name: 'ownergroup', type: 'string', operator: 'contains', value: '' },
    { name: 'bpspm', type: 'string', operator: 'contains', value: '' },
  ];

  const [taskFilters, setTaskFilters] = useState<TypeFilterValue>(defaultFilterValue);

  const validStatuses = ['APPR', 'INPRG', 'ONHOLD', 'REVIEW', 'WAPPR', 'WCANCEL', 'WMATL', 'WPLAN'];

  const handleSetFilteredWorkorders = (workorders: Map<number, WorkOrder>) => {
    const result = Array.from(workorders.values()).filter(wo => {
      return (
        filterByField(filtersWO.wonum, wo.wonum) &&
        filterByField(filtersWO.assessor, wo.plusplanner) &&
        filterByField(filtersWO.wopriority, wo.wopriority) &&
        filterByField(filtersWO.worktype, wo.worktype) &&
        filterByField(filtersWO.location, wo.location) &&
        filterByField(filtersWO.crewworkgroup, wo.crewworkgroup) &&
        filterByField(filtersWO.spm, wo.bpspm) &&
        (!filtersWO.highPriority || checkHighPriority(wo))
      );
    });
    setFilteredWorkorders(result);
  };

  const handleSetWorkorders = (workorders: Map<number, WorkOrder>) => {
    setWorkorders(workorders);
    handleSetFilteredWorkorders(workorders);
  };

  const fetchWorkorders = () => {
    setWoLoading(true);
  };

  const fetchTasks = () => {
    setTaskLoading(true);
  };

  const getWorkorderByWonum = (wonum: string | undefined) => {
    if (!wonum || !workorders) return undefined;
    return Array.from(workorders.values()).find(wo => wo.wonum === wonum);
  };

  useEffect(() => {
    handleSetFilteredWorkorders(workorders);
  }, [filtersWO, workorders]);

  const assessingConditions = (wo: WorkOrder) => {
    return validStatuses.includes(wo.status) && !wo.assessingReady;
  };

  useEffect(() => {
    if (activeView === 'task-view') {
      setTaskLoading(true);
    } else if (!wonum) {
      setWoLoading(true);
    }
  }, [activeView]);

  useEffect(() => {
    const getAllWorkorders = async () => {
      try {
        const res = (await Api.cwt.getAllWorkorders()).filter(assessingConditions);
        handleSetWorkorders(new Map(res.map((wo: WorkOrder) => [wo.id, wo])));
      } catch (error) {
        handleError(error as Error);
      }
      setWoLoading(false);
    };
    if (woLoading) {
      void getAllWorkorders();
    }
  }, [woLoading]);

  const getTasksData = ({ skip, limit, filterValue }: any) => {
    const page = skip / limit + 1;
    const pageSize = limit;

    return Api.cwt
      .getTasks(page, pageSize, filterValue, filtersTasks)
      .then((res: { data: WorkOrder[]; pagination: { totalEntries: number } }) => {
        setTaskLoading(false);
        setTasksTotalCount(res.pagination.totalEntries);
        return {
          data: res.data,
          count: res.pagination.totalEntries,
        };
      });
  };

  const dataSource = useCallback(getTasksData, [taskLoading]);

  //CSV Export
  const [gridRef, setGridRef] = useState<any>(null);

  const exportCSV = async () => {
    const header = gridRef.current.visibleColumns
      .filter((column: any) => column.header !== 'Actions')
      .map((column: any) => column.header)
      .join(',');

    const filtered = activeView === 'wo-view' ? filteredWorkorders : gridRef.current.data;

    const rows = filtered.map((row: any) => {
      return gridRef.current.visibleColumns
        .filter((column: any) => column.header !== 'Actions' && column.header !== 'Notes')
        .map((column: any) => {
          const value = row[column.id];
          if (typeof value === 'string') {
            return `"${value.replace(/"/g, '""')}"`;
          }
          return value;
        })
        .join(',');
    });

    const name = activeView === 'wo-view' ? 'workorders' : 'tasks';

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
    downloadBlob(blob, `assessing-${name}-${new Date().toISOString()}`);
  };

  const contextValue = useMemo(
    () => ({
      workorders,
      filteredWorkorders,
      fetchWorkorders,
      filtersWO,
      taskFilters,
      setTaskFilters,
      setWOFilters,
      setSelectedWonum,
      selectedWonum,
      woLoading,
      setActiveView,
      activeView,
      getWorkorderByWonum,
      gridRef,
      setGridRef,
      exportCSV,
      taskLoading,
      setTaskLoading,
      fetchTasks,
      dataSource,
      tasksTotalCount,
      handleSetFilteredWorkorders,
      filtersTasks,
      setFilterTasks,
    }),
    [
      workorders,
      filteredWorkorders,
      filtersWO,
      taskFilters,
      selectedWonum,
      woLoading,
      activeView,
      gridRef,
      taskLoading,
      tasksTotalCount,
      filtersTasks,
      setFilterTasks,
    ]
  );

  return <AssessingContext.Provider value={contextValue}>{children}</AssessingContext.Provider>;
};

export const useAssessing = () => {
  const context = useContext(AssessingContext);
  if (context === undefined) {
    throw new Error('useAssessing must be used within an AssessingContextProvider');
  }
  return context;
};
