import ReactDataGrid from '@inovua/reactdatagrid-community';
import { TypeDataGridProps } from '@inovua/reactdatagrid-community/types';
import CWTToolTip from '../../../CWTToolTip';
import { renderMaximoLink } from '../../../../wo/components/utils';
import { useOverview } from '../../../../hooks/useOverview';
import { WorkOrder } from '../../../../../../types/cwt/workorder';

interface RelatedWosTableProps {
  wo: WorkOrder;
  style: TypeDataGridProps['style'];
}

const RelatedWosTable = ({ wo, style }: RelatedWosTableProps) => {
  const { wos, wosMessage } = useOverview();

  const columns = [
    {
      name: 'wonum',
      header: 'WO Number',
      defaultWidth: 120,
      render: ({ data }: any) => renderMaximoLink(data.workorderid, data.wonum),
    },
    {
      name: 'status',
      header: 'Status',
      defaultWidth: 100,
    },
    {
      name: 'statusdate',
      header: 'Status Date',
      defaultWidth: 150,
    },
    {
      name: 'worktype',
      header: 'Work Type',
      defaultWidth: 120,
    },
    {
      name: 'wopriority',
      header: 'Priority',
      defaultWidth: 90,
    },
    {
      name: 'plusoutagecode',
      header: 'Outage Code',
      defaultWidth: 130,
    },
    {
      name: 'location',
      header: 'Location',
      defaultWidth: 150,
    },
    {
      name: 'bpschedulebacklog',
      header: 'BP Schedule Backlog',
      defaultWidth: 150,
    },
    {
      name: 'description',
      header: 'Description',
      defaultWidth: 400,
      render: ({ value }: any) => <CWTToolTip value={value}></CWTToolTip>,
    },
  ];

  return (
    <ReactDataGrid
      idProperty="id"
      style={style}
      enableKeyboardNavigation={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuLockOptions={false}
      showColumnMenuTool={false}
      columns={columns}
      dataSource={wos.filter(wol => wol.location === wo.location && wol.wonum !== wo.wonum)}
      rowHeight={null}
      emptyText={wosMessage}
    />
  );
};

export default RelatedWosTable;
