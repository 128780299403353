import React from 'react';
import * as R from 'ramda';
import { useParams } from 'react-router-dom';
import useApproveReport from '../../../../../hooks/approve-report';
import useRejectReport from '../../../../../hooks/reject-report';
import ReportReview from '../../../../../components/ReportReview';
import useReport from '../../../../../hooks/report';
import useCallWithRedirect from '../../../../../hooks/call-with-redirect';
import ReportStateChangeCommentDialog from '../../../../../components/ReportStateChangeCommentDialog';
import { Downloader } from '../../../../../hooks/downloader';
import { BreadcrumbTitleBroadcaster } from '../../../../../context/breadcrumbs';
import helpers from '../../../../../components/ReportReview/helpers';

const ReportPreviewContainer = ({ report }) => {
  const { approvingReport, confirmApproveReport, cancelApproveReport, approveReport } = useApproveReport([report]);
  const { rejectingReport, confirmRejectReport, cancelRejectReport, rejectReport } = useRejectReport([report]);
  const handleApproveReport = useCallWithRedirect('/app/approval/report-review', approveReport);
  const handleRejectReport = useCallWithRedirect('/app/approval/report-review', rejectReport);

  return (
    <>
      <BreadcrumbTitleBroadcaster pushTitle={helpers.generateReportTitle(report)} />
      <ReportReview
        report={report}
        primaryText="Approve"
        onPrimary={R.thunkify(confirmApproveReport)(report.id)}
        secondaryText="Do Not Approve"
        onSecondary={R.thunkify(confirmRejectReport)(report.id)}
      />
      {approvingReport && (
        <ReportStateChangeCommentDialog
          title="Report Approved"
          commentRequired={false}
          onSave={handleApproveReport}
          onCancel={cancelApproveReport}
        />
      )}
      {rejectingReport && (
        <ReportStateChangeCommentDialog
          title="Report Not Approved"
          commentRequired={true}
          onSave={handleRejectReport}
          onCancel={cancelRejectReport}
        />
      )}
    </>
  );
};

const ReportPreviewPage = () => {
  const { reportId } = useParams();
  const { report } = useReport(reportId);

  return R.isNil(report) ? <div /> : <ReportPreviewContainer report={report} />;
};

const ReportPreview = () => (
  <Downloader>
    <ReportPreviewPage />
  </Downloader>
);

export default ReportPreview;
