import { Box, Grid, Typography } from '@material-ui/core';
import RelatedWosTable from './components/RelatedWosTable';
import ActionsTable from '../ActionsTab/ActionsTable';
import RelatedRecordsTable from './components/RelatedRecordsTable';
import { ControlDate } from '../../../../../form/ControlDate';
import { useOverview } from '../../../hooks/useOverview';
import ActionButton from '../../../../../components/ActionButton';
import RelatedPMsTable from './components/RelatedPMsTable';
import OverrideButtonGroup from './components/OverrideButtonGroup';
import { ControlAutocomplete } from '../../../../../form/ControlAutocomplete';
import { useCrews } from '../../../hooks/useCrews';
import { useWODetailsModal } from '../../../hooks/useWODetailsModal';

import ReadyButtonGroup from './components/ReadyButtonGroup';
import { Alert } from '@material-ui/lab';
import isFeatureEnabled from '../../../../../utils/feature-flags';

const OverviewTab = () => {
  const { crews } = useCrews();
  const { handleSubmit, control, errors, woReady, handleSetWorkorderCanBeReady } = useOverview();
  const { workorder, tplus1 } = useWODetailsModal();

  return (
    <Box style={{ height: '80vh' }}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="h6"
            gutterBottom
          >
            Related Records (ENG, SC, & TCCs)
          </Typography>
          <RelatedRecordsTable style={{ minHeight: 200 }} />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Typography
            variant="h6"
            gutterBottom
          >
            Actions
          </Typography>
          <ActionsTable
            style={{ minHeight: 200 }}
            onEditAction={handleSetWorkorderCanBeReady}
          />
        </Grid>
        {!tplus1 && (
          <>
            {isFeatureEnabled('cwtWorkorderVerification') && (
              <Grid container>
                <ReadyButtonGroup />
              </Grid>
            )}

            <Grid
              item
              md={9}
            >
              <Typography
                variant="h6"
                gutterBottom
              >
                Overrides
              </Typography>

              {woReady && isFeatureEnabled('cwtWorkorderVerification') && (
                <Alert
                  variant="outlined"
                  severity="warning"
                  style={{ marginBottom: '10px' }}
                >
                  Caution: Modyfing overrides after a Work Order has been Manually Verified as Ready MAY cause the Work
                  Order to require re-verification
                </Alert>
              )}
              <Box
                display="flex"
                flexWrap={'wrap'}
              >
                <OverrideButtonGroup group="assessing" />
                <OverrideButtonGroup group="eng" />
                <OverrideButtonGroup group="sc" />
                <OverrideButtonGroup group="ops" />
                <OverrideButtonGroup group="other" />
              </Box>
            </Grid>
          </>
        )}
        {!tplus1 && (
          <Grid
            item
            xs={12}
            md={3}
          >
            <Typography
              variant="h6"
              gutterBottom
            >
              Schedule
            </Typography>
            <ControlDate
              label="Start"
              control={control}
              name={'start'}
              error={!!errors.start}
              required={true}
            />
            <ControlDate
              label="End"
              control={control}
              name={'end'}
              error={!!errors.end}
              required={true}
            />
            <ControlAutocomplete
              control={control}
              name="crewId"
              label="Crew"
              required
              error={!!errors.crewId}
              options={crews.map(crew => ({ label: crew.id, value: crew.id }))}
            />
            <ActionButton
              color="primary"
              onClick={handleSubmit}
              style={{ width: '50%', marginTop: 10 }}
            >
              Save Schedule
            </ActionButton>
          </Grid>
        )}
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="h6"
            gutterBottom
          >
            Related WO Location Matches
          </Typography>
          {workorder && (
            <RelatedWosTable
              style={{ minHeight: 200, minWidth: '100%' }}
              wo={workorder}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="h6"
            gutterBottom
          >
            Related PM Location Matches
          </Typography>
          <RelatedPMsTable style={{ minHeight: 200, minWidth: '100%' }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverviewTab;
