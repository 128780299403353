import { useEffect, useState } from 'react';
import api from '../../../api';
import { useUsers } from './useUsers';
import { WorkOrder } from '../../../types/cwt/workorder';
import { useError } from '../components/ErrorContextProvider';

const useAddNewWorkorder = () => {
  const [workOrderNumber, setWorkOrderNumber] = useState('');
  const [workOrder, setWorkOrder] = useState<{ id: number; title: string } | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [manualWorkOrders, setManualWorkOrders] = useState<WorkOrder[]>([]);
  const [loading, setLoading] = useState(true);
  const [isBacklog, setIsBacklog] = useState(false);
  const [selectedWorkOrderId, setSelectedWorkOrderId] = useState<string>('');
  const { userSite } = useUsers();
  const { handleError } = useError();
  const fetchWorkOrder = async () => {
    try {
      const response = await api.cwt.getNewWorkorder(workOrderNumber);
      if (response?.message) {
        setErrorMessage(response.message);
        setWorkOrder(null);
      } else {
        setWorkOrder(response);
        setErrorMessage('');
      }
    } catch (error) {
      handleError(error as Error);
    }
  };

  const removeManualWorkorder = async () => {
    try {
      await api.cwt.removeManuallyAddedWorkorder(selectedWorkOrderId);
    } catch (error) {
      handleError(error as Error);
    }
    setSelectedWorkOrderId('');
    setLoading(true);
  };

  const addNewWorkOrder = async () => {
    try {
      await api.cwt.insertNewWorkorder(workOrderNumber);
    } catch (error) {
      handleError(error as Error);
    }
    setWorkOrder(null);
    setWorkOrderNumber('');
    setLoading(true);
  };

  const fetchManualWorkOrders = async () => {
    setLoading(true);
  };

  useEffect(() => {
    const getManualWorkOrders = async () => {
      try {
        const res = await api.cwt.getAllWorkorders({ manuallyAdded: true, status: isBacklog ? 'active' : undefined });
        setManualWorkOrders(res);
      } catch (error) {
        handleError(error as Error);
      }
      setLoading(false);
    };

    if (loading) void getManualWorkOrders();
  }, [loading]);

  useEffect(() => {
    fetchManualWorkOrders();
  }, [userSite, isBacklog]);

  return {
    fetchWorkOrder,
    workOrder,
    setWorkOrderNumber,
    workOrderNumber,
    addNewWorkOrder,
    errorMessage,
    setWorkOrder,
    loading,
    setIsBacklog,
    isBacklog,
    removeManualWorkorder,
    setSelectedWorkOrderId,
    selectedWorkOrderId,
    fetchManualWorkOrders,
    manualWorkOrders,
    setManualWorkOrders,
  };
};

export default useAddNewWorkorder;
