import { Box, Grid, Typography } from '@material-ui/core';
import ActionButton from '../../../../../components/ActionButton';
import { useSchedule } from '../../../hooks/useSchedule';
import { ControlAutocomplete } from '../../../../../form/ControlAutocomplete';

import { ControlDate } from '../../../../../form/ControlDate';
import ScheduleCalendar from './components/ScheduleCalendar';
import { useTurnover } from '../../../hooks/useTurnover';

import { useCrews } from '../../../hooks/useCrews';
import ScheduleNoteModal from './components/ScheduleNoteModal';
import { useWODetailsModal } from '../../../hooks/useWODetailsModal';

const ScheduleTurnoverTab = () => {
  const { crews } = useCrews();
  const { control, errors, statuses, handleAddEditSchedule, events, handleRemoveSchedule } = useSchedule();
  const { turnover } = useTurnover();
  const { workorder } = useWODetailsModal();

  return (
    <Box
      display="flex"
      justifyContent={'space-between'}
    >
      <Box width="70%">
        <ScheduleCalendar />
        <ScheduleNoteModal workorder={workorder} />
      </Box>
      <Box width="25%">
        <ControlDate
          control={control}
          dateFormat="ddMMMyyyy"
          name="start"
          label="Start"
          required={true}
          error={!!errors.start}
          disableTyping
        />
        <ControlDate
          control={control}
          dateFormat="ddMMMyyyy"
          name="end"
          label="End"
          required={true}
          error={!!errors.end}
          disableTyping
        />
        <ControlAutocomplete
          control={control}
          name="crewId"
          label="Crew"
          required
          error={!!errors.crewId}
          options={crews.map(crew => ({ label: crew.id, value: crew.id }))}
        />
        <ControlAutocomplete
          control={control}
          name="statusId"
          label="Status"
          error={!!errors.statusId}
          required
          options={statuses.map(status => ({ label: status.label, value: status.id.toString() }))}
        />
        <ActionButton
          color="primary"
          fullWidth
          onClick={handleAddEditSchedule}
        >
          Save All Schedule Changes
        </ActionButton>
        {events.length > 0 && (
          <ActionButton
            className="error"
            fullWidth
            onClick={async () => {
              await handleRemoveSchedule();
            }}
          >
            Remove from Schedule
          </ActionButton>
        )}
        {turnover.length > 0 && (
          <>
            <Typography
              variant="h6"
              style={{ marginTop: '30px' }}
            >
              Recent Turnover Notes
            </Typography>
            <Box
              border={1}
              borderColor={'rgb(191, 190, 190)'}
              borderRadius={'5px'}
              padding={'10px'}
              marginTop={'10px'}
              maxHeight={'300px'}
              overflow={'scroll'}
            >
              {turnover.map((turnover, index) => (
                <Box
                  key={index}
                  marginTop={'5px'}
                  marginBottom={'10px'}
                  bgcolor={'rgb(255,255,255, 1)'}
                  borderRadius={'5px'}
                  padding={'10px'}
                  color={'black'}
                  width={'100%'}
                  style={{ wordWrap: 'break-word' }}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                      {turnover?.crewId} - {new Date(turnover.createdAt).toLocaleString()}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    {turnover.turnoverNotes}
                  </Grid>
                </Box>
              ))}
            </Box>
          </>
        )}
        {turnover.length === 0 && (
          <Typography
            variant="h6"
            style={{ marginTop: '30px' }}
          >
            No Turnover Notes
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ScheduleTurnoverTab;
