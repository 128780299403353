import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import navPolygonSvg from '../assets/nav-polygon.svg';

const NavPolygon = styled.img.attrs({
  height: 16,
  src: navPolygonSvg,
})`
  display: inline-block;
  margin-left: 11px;
  margin-right: 11px;
`;
type BreadcrumbBroadcasterProps = {
  isTitleCrumb?: boolean;
  crumbDepth?: number;
  pushCrumb?: string | null | boolean | React.ReactNode;
  replaceCrumb?: string | null | boolean | React.ReactNode;
};
type BreadcrumbTitleBroadcasterProps = {
  pushTitle?: string | null | boolean | React.ReactNode;
  replaceTitle?: string | null | boolean | React.ReactNode;
};
type PushSegment = {
  isTitleCrumb?: boolean;
  render: string | React.ReactNode;
};
type BaseSegment = {
  isTitleCrumb?: boolean;
  render: null | PushSegment['render'];
  depth?: number;
};
type ReplaceSegment = Required<{
  render: BaseSegment['render'];
  depth: number;
}> & {
  isTitleCrumb?: boolean;
};
type Segment = ReplaceSegment & { appended: PushSegment | ReplaceSegment };
type RemoveBreadcrumbParams = { segment: BaseSegment; isPush?: boolean; isDestructive?: boolean };

export type ContextType = {
  current: Segment[];
  remove: (payload: RemoveBreadcrumbParams & { isWarningMuted?: boolean }) => void;
  unmountRemove: (payload: RemoveBreadcrumbParams) => void;
  push: (segment: PushSegment) => void;
  replace: (segment: ReplaceSegment) => void;
};
export const List = styled.ul`
  display: inline-block;
  clear: both;
  flex-wrap: wrap;
  &,
  & > li {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    margin: 0px;
    list-style: none;
  }
  & > li {
    flex-wrap: nowrap;
  }
  & > li.breadCrumb {
    color: ${({ theme }) => theme.colours.greys.darkGrey5};
    font-family: Roboto, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  & > li.currentBreadCrumb {
    color: ${({ theme }) => theme.colours.black};
  }
  & > li.titleBreadCrumb {
    padding-top: 12px;
    color: ${({ theme }) => theme.colours.purples.purple3};
    font-family: Roboto, Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    flex: 1 1 100%;
  }
`;
export const defaultContext = {
  current: [],
  remove: () => undefined,
  unmountRemove: () => undefined,
  push: () => undefined,
  replace: () => undefined,
};
export const BreadcrumbsContext = createContext<ContextType>(defaultContext);
const BreadcrumbsProvider = ({ children }: React.PropsWithChildren) => {
  const [current, setCurrent] = useState<Segment[]>([]);
  const pushBreadcrumb = useCallback(
    (segment: PushSegment) => {
      setCurrent((prev: Segment[]) => [...prev, { ...segment, depth: prev.length, appended: segment }]);
    },
    [setCurrent]
  );
  const replaceBreadcrumb = useCallback(
    (segment: ReplaceSegment) => {
      setCurrent((prev: any[]) => {
        const arr = [...prev].splice(0, segment.depth);
        return [...arr, { ...segment, appended: segment }];
      });
    },
    [setCurrent]
  );
  const removeBreadcrumb = useCallback(
    (params: RemoveBreadcrumbParams & { isWarningMuted?: boolean }) => {
      const { isWarningMuted, isDestructive, segment } = params;
      setCurrent((prev: Segment[]) => {
        const stateMutate = (pos: number) => {
          if (pos !== -1) {
            if (isDestructive) {
              return [...prev].splice(0, pos);
            }
            const beforeArr = [...prev].splice(0, pos);
            const afterArr = [...prev].splice(pos + 1, prev.length);
            return [...beforeArr, ...afterArr];
          }
          return null;
        };
        const segMatched = stateMutate(prev.findIndex(s => segment === s.appended));
        if (segMatched) {
          return segMatched;
        }
        const applyDepth = segment?.depth;
        const depth = typeof applyDepth === 'number' ? applyDepth : parseInt(applyDepth || '0', 10);
        const depthMatched = stateMutate(prev.findIndex(s => segment.render === s.render && depth === s?.depth));
        if (!depthMatched) {
          !isWarningMuted && console.warn(`Could not find requested segment to remove at depth ${depth}`, segment);
          return prev;
        }
        return depthMatched;
      });
    },
    [setCurrent]
  );
  const unmountRemove = useCallback(
    (params: RemoveBreadcrumbParams) => {
      removeBreadcrumb({ ...params, isWarningMuted: true });
    },
    [removeBreadcrumb]
  );

  const contextValue = {
    current,
    depthIndex: current.length,
    unmountRemove,
    remove: removeBreadcrumb,
    push: pushBreadcrumb,
    replace: replaceBreadcrumb,
  };
  return <BreadcrumbsContext.Provider value={contextValue}>{children}</BreadcrumbsContext.Provider>;
};

export const useBreadcrumbsContext = () => {
  const context = useContext(BreadcrumbsContext);
  if (!context) {
    throw new Error('useBreadcrumbsContext must be used inside an BreadcrumbsContext');
  }
  return context;
};
export const BreadcrumbTitleBroadcaster = (props: React.PropsWithChildren & BreadcrumbTitleBroadcasterProps) => {
  const { replaceTitle, pushTitle, children } = props;
  return (
    <BreadcrumbBroadcaster
      {...{ ...(replaceTitle ? { replaceCrumb: replaceTitle } : {}), ...(pushTitle ? { pushCrumb: pushTitle } : {}) }}
      isTitleCrumb
    >
      {children}
    </BreadcrumbBroadcaster>
  );
};
export const BreadcrumbBroadcaster = (props: React.PropsWithChildren & BreadcrumbBroadcasterProps) => {
  const { crumbDepth, pushCrumb, replaceCrumb, isTitleCrumb, children } = props;
  const { unmountRemove, push, replace } = useBreadcrumbsContext();
  const renderForReplace = typeof replaceCrumb !== 'string' && 'children' in props ? children : replaceCrumb;
  const renderForPush = typeof pushCrumb !== 'string' && 'children' in props ? children : pushCrumb;

  useEffect(() => {
    const isPushCrumb = 'pushCrumb' in props;
    const isReplaceCrumb = 'replaceCrumb' in props;
    const isValidMutation = !(isPushCrumb && isReplaceCrumb) && (renderForReplace || renderForPush);

    if (!isValidMutation) {
      if (isPushCrumb && isReplaceCrumb) {
        console.warn(`Please only provide one prop 'pushCrumb' or 'replaceCrumb' not both`);
      }
      return;
    }
    if (isReplaceCrumb) {
      const replaceSegment = {
        depth: crumbDepth as number,
        render: renderForReplace,
        isTitleCrumb,
      };
      replace(replaceSegment);
      return () => {
        unmountRemove({ segment: replaceSegment });
      };
    }
    const pushSegment = { render: renderForPush, isTitleCrumb };
    push(pushSegment);
    return () => {
      unmountRemove({ segment: pushSegment, isPush: true, isDestructive: true });
    };
  }, [replace, push, unmountRemove, renderForReplace, renderForPush]);

  return null;
};
export const Breadcrumbs = () => {
  const { current } = useBreadcrumbsContext();
  if (current.length === 0) {
    return null;
  }
  const renderable = current.filter(({ render }) => !!render);
  const crumbs = renderable.filter(({ isTitleCrumb }) => !isTitleCrumb);
  const titles = renderable.filter(({ isTitleCrumb }) => isTitleCrumb);
  return (
    <List data-testid="breadcrumbs">
      {crumbs.map(({ render, depth }, i) => (
        <li
          className={`breadCrumb${i === crumbs.length - 1 ? ' currentBreadCrumb' : ''}`}
          key={`depth-${depth}`}
          data-testid={`breadcrumbs-item-${depth}`}
        >
          {render}
          {i !== crumbs.length - 1 && <NavPolygon aria-hidden />}
        </li>
      ))}
      {titles.map(({ render, depth }) => (
        <li
          className="titleBreadCrumb"
          key={`title-depth-${depth}`}
          data-testid={`breadcrumbs-title-item-${depth}`}
        >
          {render}
        </li>
      ))}
    </List>
  );
};
export default BreadcrumbsProvider;
