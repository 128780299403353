import { Paper } from '@material-ui/core';
import Page from '../../../components/Page';
import { useState } from 'react';
import { RiskTable } from '../../../components/RiskTable';
import useRiskManagement from '../../../context/risk-management';
import isFeatureEnabled from '../../../utils/feature-flags';
import { BreadcrumbBroadcaster } from '../../../context/breadcrumbs';

export default () => {
  const [elementId, setElementId] = useState<string>('');
  const { currentRiskManagementUserRoles, riskApprovers, myElements } = useRiskManagement();
  return (
    <>
      <BreadcrumbBroadcaster pushCrumb="Risk Approval" />
      <Page
        title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Risk Review Page'}
        bodyComponent={<Paper square />}
      >
        <RiskTable
          elementId={elementId}
          setElementId={setElementId}
          state={'approval'}
          currentRiskManagementUserRoles={currentRiskManagementUserRoles}
          riskApprovers={riskApprovers}
          myElements={myElements}
        />
      </Page>
    </>
  );
};
