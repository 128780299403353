import { Paper } from '@material-ui/core';
import { useState } from 'react';
import NoRecords from '../../../components/NoRecords';
import Page from '../../../components/Page';
import ReportStateChangeCommentDialog from '../../../components/ReportStateChangeCommentDialog';
import useDeferralsRequests, { DeferralsRequestsProvider } from '../../../hooks/deferral-requests';
import { Downloader } from '../../../hooks/downloader';
import DeferralsReviewTable from './components/DeferralsReviewTable';
import isFeatureEnabled from '../../../utils/feature-flags';
import { BreadcrumbBroadcaster } from '../../../context/breadcrumbs';

const DeferralReviewPage = () => {
  const { deferrals, approveDeferralRequest, rejectDeferralRequest } = useDeferralsRequests();
  const [rejectDeferralId, setRejectDialogOpen] = useState<number | false>(false);
  const [approvalDeferralId, setApprovalDialogOpen] = useState<number | false>(false);

  return (
    <>
      <BreadcrumbBroadcaster pushCrumb="Deferral Review" />
      <Page
        title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Deferral Review Page'}
        bodyComponent={<Paper square />}
      >
        {deferrals.length > 0 ? (
          <Downloader>
            <DeferralsReviewTable
              deferrals={deferrals}
              onAccept={setApprovalDialogOpen}
              onReject={setRejectDialogOpen}
            />
          </Downloader>
        ) : (
          <NoRecords />
        )}

        {rejectDeferralId && (
          <ReportStateChangeCommentDialog
            title="Reject Report Deferral"
            commentRequired={true}
            message={false}
            onSave={(comment: string) => {
              rejectDeferralRequest(rejectDeferralId, comment);
              setRejectDialogOpen(false);
            }}
            onCancel={() => {
              setRejectDialogOpen(false);
            }}
          />
        )}

        {approvalDeferralId && (
          <ReportStateChangeCommentDialog
            title="Approve Report Deferral"
            commentRequired={false}
            message={false}
            onSave={(comment: string) => {
              approveDeferralRequest(approvalDeferralId, comment);
              setApprovalDialogOpen(false);
            }}
            onCancel={() => {
              setApprovalDialogOpen(false);
            }}
          />
        )}
      </Page>
    </>
  );
};

export default () => (
  <DeferralsRequestsProvider>
    <DeferralReviewPage />
  </DeferralsRequestsProvider>
);
