import React from 'react';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import useToggle from '../../../../../../hooks/toggle';
import ControlButton from '../../../../../../components/ControlButton';
import LongDescription from '../../../../../../components/LongDescription';
import DetailHeader from './DetailHeader';
import TaskTitle from './TaskTitle';
import { WorkGridContainerDetail, WorkGridItemDetail } from './WorkGridItemDetail';
import { type WorkRequest } from '../../../../../../context/work-request';

const workRequestUrl = (number: string | number) =>
  `https://prod-maximo.corp.brucepower.com/maximo/ui/maximo.jsp?event=loadapp&value=plusca&uniqueid=${number}`;

export type WorkRequestDetailsRefreshUiProps = {
  dense?: boolean;
  workRequest: WorkRequest;
};
const WorkRequestDetailsRefreshUi = ({ dense, workRequest }: WorkRequestDetailsRefreshUiProps) => {
  const {
    equipmentTag,
    ticketuid,
    workRequestNumber,
    status,
    title,
    description,
    location,
    ownergroup,
    plusoutagecode,
    targetfinish,
    actualfinish,
    critCat,
  } = workRequest;
  const { value: showMore, onToggle: onToggleMore } = useToggle();

  return (
    <Grid
      container
      direction="column"
      spacing={1}
    >
      <Grid item>
        <DetailHeader>
          {R.isNil(ticketuid) ? (
            `WR #${workRequestNumber}`
          ) : (
            <a
              target="_blank"
              rel="noreferrer"
              href={workRequestUrl(ticketuid)}
            >{`WR #${workRequestNumber}`}</a>
          )}
        </DetailHeader>
      </Grid>
      {title && (
        <Grid item>
          <TaskTitle>{`Task title: ${title}`}</TaskTitle>
        </Grid>
      )}
      <Grid item>
        <WorkGridContainerDetail justifyContent="space-between">
          <WorkGridItemDetail>{`Status: ${status || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Equi Comp Tag: ${equipmentTag || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Location: ${location || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Description: ${description || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Owner Group: ${ownergroup || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Outage: ${plusoutagecode || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`SCHD Date: ${targetfinish || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`COMP Date: ${actualfinish || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`CC: ${critCat || 'N/A'}`}</WorkGridItemDetail>
        </WorkGridContainerDetail>
      </Grid>
      {!dense && (
        <>
          <Grid
            item
            container
            justifyContent="flex-end"
          >
            <ControlButton onClick={onToggleMore}>View {showMore ? 'less' : 'more'}</ControlButton>
          </Grid>
          <Grid item>
            <Collapse
              in={showMore}
              unmountOnExit
            >
              <Grid
                container
                direction="column"
                spacing={2}
              >
                <Grid item>
                  <TaskTitle>Work Request Details</TaskTitle>
                </Grid>
                <Grid item>
                  <LongDescription text={R.propOr('', 'details', workRequest)} />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default WorkRequestDetailsRefreshUi;
