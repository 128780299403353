import Page from '../../../../components/Page';
import { Box, FormControlLabel, Grid, IconButton, Switch, Tooltip, Typography } from '@material-ui/core';
import DownloadButton from '../../../../components/DownloadButton';
import WOHeaderTable from './WOHeaderTable';
import { useWorkOrders } from '../../hooks/useWorkOrders';
import WODetailsModal from '../../components/WODetailsModal';
import { WODetailsModalContextProvider } from '../../hooks/useWODetailsModal';
import styled from 'styled-components';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import { useMemo } from 'react';
import {
  getUnitOptions,
  getWorkorderOptions,
  getPriorityOptions,
  getStatusOptions,
  getWorkGroupOptions,
  getScheduleBacklogOptions,
  getPlanningCtrOptions,
  getSpecificationsOptions,
  getWorkTypeOptions,
  getOnlineStartOptions,
  getLocationOptions,
  getWorkorderFlagOptions,
  T_PLUS_ONE_ROW_COLOR,
  WORKORDER_VERIFIED_COLOR,
  getActionFlagOptions,
} from './utils';
import { ScheduleContextProvider } from '../../hooks/useSchedule';
import { BookmarkMenu } from '../../bookmarks/BookmarkMenu';
import { FilterEntityType } from '../../bookmarks/hook/useBookmarks';
import isFeatureEnabled from '../../../../utils/feature-flags';
import AutocompleteFilterInput from '../../components/AutocompleteFilterInput';

const VerifiedSwitch = styled(Switch)`
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: green;
  }
  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: rgba(0, 255, 0, 0.04);
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: green;
  }
`;

const ReadyForVerification = styled(Switch)`
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${T_PLUS_ONE_ROW_COLOR};
  }
  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: ${T_PLUS_ONE_ROW_COLOR};
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: ${T_PLUS_ONE_ROW_COLOR};
  }
`;
const WaitSwitch = styled(Switch)`
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: red;
  }
  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: rgba(0, 255, 0, 0.04);
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: red;
  }
`;

const RefreshBacklogIcon = styled(AutorenewIcon)`
  cursor: pointer;
  padding-top: 0;
  color: ${({ theme }) => theme.colours.teals.teal1};
  width: 25px;
  height: 25px;
`;
const WOHeaderPage = () => {
  const {
    workorders,
    selectedWorkorderId,
    woloading,
    exportCSV,
    filters,
    setFilters,
    filteredWorkorders,
    fetchWorkorders,
    getWorkorderById,
  } = useWorkOrders();
  return (
    <Page title="WO Header">
      <WODetailsModalContextProvider workorder={getWorkorderById(selectedWorkorderId)}>
        <Grid
          container
          style={{ paddingLeft: '10px' }}
        >
          <Grid
            item
            xs={3}
          >
            <Box
              height="100px"
              minWidth={'120px'}
              borderRadius="5px"
              textAlign="center"
              boxShadow="rgb(191, 190, 190) 0px 2px 7px 0px;"
              marginRight="10px"
              padding="10px"
            >
              <Typography style={{ fontWeight: '500', fontSize: '45px' }}>
                {woloading ? '...' : filteredWorkorders.length}
              </Typography>
              <Typography style={{ fontSize: '12px' }}>Work Orders</Typography>
            </Box>
          </Grid>
          <Grid
            container
            spacing={2}
            item
            xs={9}
          >
            <Grid
              item
              xs={4}
            >
              <AutocompleteFilterInput
                label="Work Order"
                options={useMemo(() => getWorkorderOptions(workorders), [workorders])}
                onChange={value => {
                  setFilters({ ...filters, wonum: value });
                }}
                value={filters.wonum}
                multiple
                allowInvalidValuesInOptions
              />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <AutocompleteFilterInput
                label="Unit"
                options={useMemo(() => getUnitOptions(workorders), [workorders])}
                onChange={value => {
                  setFilters({ ...filters, unit: value });
                }}
                value={filters.unit}
                multiple
                selectAll
                allowInvalidValuesInOptions
              />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <AutocompleteFilterInput
                label="Priority"
                options={useMemo(() => getPriorityOptions(workorders), [workorders])}
                onChange={value => {
                  setFilters({ ...filters, wopriority: value });
                }}
                value={filters.wopriority}
                multiple
                selectAll
                allowInvalidValuesInOptions
              />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <AutocompleteFilterInput
                label="Work Type"
                options={useMemo(() => getWorkTypeOptions(workorders), [workorders])}
                onChange={value => {
                  setFilters({ ...filters, worktype: value });
                }}
                value={filters.worktype}
                multiple
                selectAll
                allowInvalidValuesInOptions
              />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <AutocompleteFilterInput
                label="Lead Crew"
                options={useMemo(() => getWorkGroupOptions(workorders), [workorders])}
                onChange={value => {
                  setFilters({ ...filters, crewworkgroup: value });
                }}
                value={filters.crewworkgroup}
                multiple
                selectAll
                allowInvalidValuesInOptions
              />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <AutocompleteFilterInput
                label="Planning Center"
                options={useMemo(() => getPlanningCtrOptions(workorders), [workorders])}
                onChange={value => {
                  setFilters({ ...filters, bpplanningctr: value });
                }}
                value={filters.bpplanningctr}
                multiple
                selectAll
                allowInvalidValuesInOptions
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            xs={12}
          >
            <Grid
              item
              xs={3}
            >
              <AutocompleteFilterInput
                label="Schedule Backlog"
                options={useMemo(() => getScheduleBacklogOptions(workorders), [workorders])}
                onChange={value => {
                  setFilters({ ...filters, bpschedulebacklog: value });
                }}
                value={filters.bpschedulebacklog}
                multiple
                selectAll
                allowInvalidValuesInOptions
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <AutocompleteFilterInput
                label="Online Start"
                options={useMemo(
                  () => getOnlineStartOptions(workorders).map(option => ({ ...option, label: option.label || '' })),
                  [workorders]
                )}
                onChange={value => {
                  setFilters({ ...filters, schedstart: value });
                }}
                value={filters.schedstart}
                multiple
                allowInvalidValuesInOptions
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <AutocompleteFilterInput
                label="Location"
                options={useMemo(
                  () => getLocationOptions(workorders).map(option => ({ ...option, label: option.label || '' })),
                  [workorders]
                )}
                onChange={value => {
                  setFilters({ ...filters, location: value });
                }}
                value={filters.location}
                multiple
                allowInvalidValuesInOptions
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <AutocompleteFilterInput
                label="WO Status"
                options={useMemo(() => getStatusOptions(workorders), [workorders])}
                onChange={value => {
                  setFilters({ ...filters, status: value });
                }}
                value={filters.status}
                multiple
                selectAll
                allowInvalidValuesInOptions
              />
            </Grid>

            <Grid
              item
              xs={3}
            >
              <AutocompleteFilterInput
                label={'WO Specifications'}
                options={useMemo(() => getSpecificationsOptions(workorders), [workorders])}
                onChange={value => {
                  setFilters({ ...filters, specifications: value });
                }}
                value={filters.specifications}
                multiple
                selectAll
                allowInvalidValuesInOptions
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              {isFeatureEnabled('workorderFlags') && (
                <AutocompleteFilterInput
                  label="WO Flags"
                  options={useMemo(() => getWorkorderFlagOptions(workorders), [workorders])}
                  onChange={value => {
                    setFilters({ ...filters, workorderFlags: value });
                  }}
                  value={filters.workorderFlags}
                  multiple
                  allowInvalidValuesInOptions
                />
              )}
            </Grid>
            <Grid
              item
              xs={3}
            >
              <AutocompleteFilterInput
                label="Action Flags"
                options={useMemo(() => getActionFlagOptions(workorders), [workorders])}
                onChange={value => {
                  setFilters({ ...filters, workorderActionFlags: value });
                }}
                value={filters.workorderActionFlags}
                multiple
                allowInvalidValuesInOptions
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FormControlLabel
                control={
                  isFeatureEnabled('cwtWorkorderVerification') ? (
                    <VerifiedSwitch
                      checked={filters.verifiedReady}
                      onChange={e => {
                        setFilters({
                          ...filters,
                          verifiedReady: e.target.checked,
                        });
                      }}
                      name="verifiedReady"
                    />
                  ) : (
                    <VerifiedSwitch
                      checked={filters.readyOnly}
                      onChange={e => {
                        setFilters({
                          ...filters,
                          readyOnly: e.target.checked,
                        });
                      }}
                      name="readyOnly"
                    />
                  )
                }
                label={
                  isFeatureEnabled('cwtWorkorderVerification') ? (
                    <Box
                      display="flex"
                      alignItems="center"
                    >
                      <Box
                        width="14px"
                        height="14px"
                        border={0.1}
                        bgcolor={WORKORDER_VERIFIED_COLOR} // Solid color box
                        style={{ marginRight: 10 }}
                      />
                      Verified Ready
                    </Box>
                  ) : (
                    'Ready Only'
                  )
                }
              />
              {isFeatureEnabled('cwtWorkorderVerification') && (
                <FormControlLabel
                  control={
                    <ReadyForVerification
                      checked={filters.readyForVerification}
                      onChange={e => {
                        setFilters({
                          ...filters,
                          waitingAssessing: !e.target.checked ? filters.waitingAssessing : false,
                          waitingEng: !e.target.checked ? filters.waitingEng : false,
                          waitingSc: !e.target.checked ? filters.waitingSc : false,
                          waitingOps: !e.target.checked ? filters.waitingOps : false,
                          waitingOther: !e.target.checked ? filters.waitingOther : false,
                          readyForVerification: e.target.checked,
                        });
                      }}
                      name="readyForVerification"
                    />
                  }
                  label={
                    <Box
                      display="flex"
                      alignItems="center"
                    >
                      <Box
                        width="14px"
                        height="14px"
                        border={0.1}
                        bgcolor={T_PLUS_ONE_ROW_COLOR} // Solid color box
                        style={{ marginRight: 10 }}
                      />
                      Ready for Verification
                    </Box>
                  }
                />
              )}
              <FormControlLabel
                control={
                  <WaitSwitch
                    checked={filters.waitingAssessing}
                    onChange={e => {
                      setFilters({
                        ...filters,
                        readyOnly: !e.target.checked ? filters.readyOnly : false,
                        waitingAssessing: e.target.checked,
                      });
                    }}
                    name="waitingAssessing"
                  />
                }
                label="Waiting for Assess."
              />
              <FormControlLabel
                control={
                  <WaitSwitch
                    checked={filters.waitingEng}
                    onChange={e => {
                      setFilters({
                        ...filters,
                        readyOnly: !e.target.checked ? filters.readyOnly : false,
                        waitingEng: e.target.checked,
                      });
                    }}
                    name="waitingEngg"
                  />
                }
                label="Waiting for Eng."
              />
              <FormControlLabel
                control={
                  <WaitSwitch
                    checked={filters.waitingSc}
                    onChange={e => {
                      setFilters({
                        ...filters,
                        readyOnly: !e.target.checked ? filters.readyOnly : false,
                        waitingSc: e.target.checked,
                      });
                    }}
                    name="waitingSc"
                  />
                }
                label="Waiting for SC."
              />
              <FormControlLabel
                control={
                  <WaitSwitch
                    checked={filters.waitingOps}
                    onChange={e => {
                      setFilters({
                        ...filters,
                        readyOnly: !e.target.checked ? filters.readyOnly : false,
                        waitingOps: e.target.checked,
                      });
                    }}
                    name="waitingOps"
                  />
                }
                label="Waiting for Ops."
              />
              <FormControlLabel
                control={
                  <WaitSwitch
                    checked={filters.waitingOther}
                    onChange={e => {
                      setFilters({
                        ...filters,
                        readyOnly: !e.target.checked ? filters.readyOnly : false,
                        waitingOther: e.target.checked,
                      });
                    }}
                    name="waitingOther"
                  />
                }
                label="Waiting for Other."
              />
              <FormControlLabel
                control={
                  <WaitSwitch
                    checked={filters.highPriority}
                    onChange={e => {
                      setFilters({
                        ...filters,
                        highPriority: e.target.checked,
                      });
                    }}
                    name="highPriority"
                  />
                }
                label="High Priority"
              />
            </Grid>
          </Grid>
        </Grid>

        <Box
          width={'100%'}
          justifyContent={'space-between'}
          display="flex"
          marginTop={'15px'}
        >
          {isFeatureEnabled('cwtBookmarks') && (
            <BookmarkMenu
              type={FilterEntityType.WorkorderFilter}
              filter={filters}
              setFilters={setFilters}
            />
          )}
          <Box>
            <Tooltip
              title="Refresh Backlog"
              placement="right"
            >
              <IconButton onClick={fetchWorkorders}>
                <RefreshBacklogIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Export CSV"
              placement="top"
            >
              <DownloadButton onClick={exportCSV} />
            </Tooltip>
          </Box>
        </Box>
        <ScheduleContextProvider allSchedules>
          <WOHeaderTable />
        </ScheduleContextProvider>
        <WODetailsModal />
      </WODetailsModalContextProvider>
    </Page>
  );
};

export default WOHeaderPage;
