// List of Crew Work Groups
export const AllCrewWorkGroups = [
  'ASAC',
  'ASBC',
  'BMB1',
  'BMEA',
  'BMGA',
  'BMGE',
  'BMIA',
  'BMMA',
  'BMOA',
  'BMPA',
  'BMSA',
  'BMSE',
  'BMX5',
  'BMXE',
  'BOPA',
  'BREM',
  'BTB4',
  'BTBM',
  'BTC1',
  'BTC4',
  'BTC6',
  'BTC7',
  'BTC9',
  'BTCE',
  'BTCM',
  'BTE2',
  'BTE5',
  'BTE6',
  'BTE8',
  'BTEB',
  'BTEE',
  'BTEM',
  'BTGE',
  'BTGM',
  'BTI0',
  'BTI2',
  'BTI3',
  'BTI6',
  'BTIC',
  'BTIM',
  'BTL4',
  'BTLA',
  'BTM8',
  'BTMA',
  'BTMB',
  'BTME',
  'BTMM',
  'BTNA',
  'BTP1',
  'BTP4',
  'BTP6',
  'BTPA',
  'BTPE',
  'BTS1',
  'BTS3',
  'BTT2',
  'BTX1',
  'BTX6',
  'BTXM',
  'BWNB',
  'BWNC',
  'BWND',
  'BWNI',
  'BWNR',
  'CAHE',
  'CAHM',
  'CCMP',
  'CCNE',
  'CESE',
  'CFAO',
  'CNDU',
  'CTAL',
  'CTBL',
  'EMNB',
  'FCBX',
  'FCEG',
  'FCFR',
  'FEC1',
  'FEE4',
  'FEFL',
  'FEM6',
  'FER1',
  'FHEB',
  'FMFB',
  'FOBS',
  'FOBX',
  'FOFB',
  'FOX2',
  'FOX3',
  'FOX5',
  'FOX6',
  'FOX7',
  'GCBP',
  'GRAF',
  'HONI',
  'ISNA',
  'ISNE',
  'ISNM',
  'ISNT',
  'ISOG',
  'ITSE',
  'KCSB',
  'MCAB',
  'MCAG',
  'MCAM',
  'MCAO',
  'MCAQ',
  'MCAR',
  'MCAS',
  'MCAT',
  'MCAV',
  'MCAZ',
  'MCB4',
  'MCBC',
  'MCBG',
  'MCBI',
  'MCBK',
  'MCBT',
  'MCBZ',
  'MCG1',
  'MCGF',
  'MCGL',
  'MCGR',
  'MCGS',
  'MCND',
  'MCNP',
  'MCNQ',
  'MCNR',
  'MCPA',
  'MCPB',
  'MCTT',
  'MCVA',
  'MERE',
  'MFAP',
  'MFBC',
  'MFBO',
  'MMA4',
  'MMAH',
  'MMAS',
  'MMAV',
  'MMAX',
  'MMBH',
  'MMBS',
  'MMBW',
  'MMGH',
  'MMGS',
  'MMHX',
  'MMLX',
  'MMNB',
  'MMND',
  'MMNF',
  'MMNQ',
  'MMNT',
  'MMNW',
  'MMWA',
  'MMWB',
  'MMWD',
  'MON3',
  'MON5',
  'MON6',
  'MON7',
  'MON8',
  'MONT',
  'NOCRAFT',
  'OFAG',
  'OFAS',
  'OFBA',
  'OFDS',
  'OPA0',
  'OPA3',
  'OPA4',
  'OPA5',
  'OPAB',
  'OPAP',
  'OPAS',
  'OPAZ',
  'OPBB',
  'OPBF',
  'OPBP',
  'OPBS',
  'OPGB',
  'OPGC',
  'OPGW',
  'OPSS',
  'PCLE',
  'PCNA',
  'PCNB',
  'PDCD',
  'PDDE',
  'PDDM',
  'PDED',
  'PDIC',
  'PDMP',
  'PDRD',
  'PEEA',
  'PEEB',
  'PENA',
  'PENB',
  'PESS',
  'PGMN',
  'PMCE',
  'PMCI',
  'PMCL',
  'PMCM',
  'PMCS',
  'PMCX',
  'PMMC',
  'PMPM',
  'RANK',
  'RPAM',
  'RRTA',
  'RRTB',
  'RSAA',
  'RSAH',
  'RSBA',
  'RSBF',
  'RSBS',
  'RSBV',
  'RSGS',
  'SPBF',
  'SSAA',
  'SSAD',
  'SSAE',
  'TEA9',
  'TWPFLM',
  'ACON',
  'BMI0',
  'BTN5',
  'BTNM',
  'ERTA',
  'ERTD',
  'ERTE',
  'ESEM',
  'ESEP',
  'MSA4',
  'MSA5',
  'MSAC',
  'MSAK',
  'MSAR',
  'MSAS',
  'MSAX',
  'MSBI',
  'MSBJ',
  'MSBL',
  'MSBS',
  'MSBX',
  'MSG2',
  'MSGB',
  'MSGC',
  'MSGE',
  'MSGM',
  'MSGO',
  'MSGP',
  'MSGU',
  'MSGY',
  'MSNI',
  'MSNW',
  'MSNX',
];

// List of Default Selection Crew Work Groups
export const DefaultCrewWorkGroups = [
  'ASAC',
  'ASBC',
  'BMB1',
  'BMEA',
  'BMGA',
  'BMGE',
  'BMIA',
  'BMMA',
  'BMOA',
  'BMPA',
  'BMSA',
  'BMSE',
  'BMX5',
  'BMXE',
  'BOPA',
  'BREM',
  'BTB4',
  'BTBM',
  'BTC1',
  'BTC4',
  'BTC6',
  'BTC7',
  'BTC9',
  'BTCE',
  'BTCM',
  'BTE2',
  'BTE5',
  'BTE6',
  'BTE8',
  'BTEB',
  'BTEE',
  'BTEM',
  'BTGE',
  'BTGM',
  'BTI0',
  'BTI2',
  'BTI3',
  'BTI6',
  'BTIC',
  'BTIM',
  'BTL4',
  'BTLA',
  'BTM8',
  'BTMA',
  'BTMB',
  'BTME',
  'BTMM',
  'BTNA',
  'BTP1',
  'BTP4',
  'BTP6',
  'BTPA',
  'BTPE',
  'BTS1',
  'BTS3',
  'BTT2',
  'BTX1',
  'BTX6',
  'BTXM',
  'BWNB',
  'BWNC',
  'BWND',
  'BWNI',
  'BWNR',
  'CAHE',
  'CAHM',
  'CCMP',
  'CCNE',
  'CESE',
  'CFAO',
  'CNDU',
  'CTAL',
  'CTBL',
  'EMNB',
  'FCBX',
  'FCEG',
  'FCFR',
  'FEC1',
  'FEE4',
  'FEFL',
  'FEM6',
  'FER1',
  'FHEB',
  'FMFB',
  'FOBS',
  'FOBX',
  'FOFB',
  'FOX2',
  'FOX3',
  'FOX5',
  'FOX6',
  'FOX7',
  'GCBP',
  'GRAF',
  'HONI',
  'ISNA',
  'ISNE',
  'ISNM',
  'ISNT',
  'ISOG',
  'ITSE',
  'KCSB',
  'MCAB',
  'MCAG',
  'MCAM',
  'MCAO',
  'MCAQ',
  'MCAR',
  'MCAS',
  'MCAT',
  'MCAV',
  'MCAZ',
  'MCB4',
  'MCBC',
  'MCBG',
  'MCBI',
  'MCBK',
  'MCBT',
  'MCBZ',
  'MCG1',
  'MCGF',
  'MCGL',
  'MCGR',
  'MCGS',
  'MCND',
  'MCNP',
  'MCNQ',
  'MCNR',
  'MCPA',
  'MCPB',
  'MCTT',
  'MCVA',
  'MERE',
  'MFAP',
  'MFBC',
  'MFBO',
  'MMA4',
  'MMAH',
  'MMAS',
  'MMAV',
  'MMAX',
  'MMBH',
  'MMBS',
  'MMBW',
  'MMGH',
  'MMGS',
  'MMHX',
  'MMLX',
  'MMNB',
  'MMND',
  'MMNF',
  'MMNQ',
  'MMNT',
  'MMNW',
  'MMWA',
  'MMWB',
  'MMWD',
  'MON3',
  'MON5',
  'MON6',
  'MON7',
  'MON8',
  'MONT',
  'NOCRAFT',
  'OFAG',
  'OFAS',
  'OFBA',
  'OFDS',
  'OPA0',
  'OPA3',
  'OPA4',
  'OPA5',
  'OPAB',
  'OPAP',
  'OPAS',
  'OPAZ',
  'OPBB',
  'OPBF',
  'OPBP',
  'OPBS',
  'OPGB',
  'OPGC',
  'OPGW',
  'OPSS',
  'PCLE',
  'PCNA',
  'PCNB',
  'PDCD',
  'PDDE',
  'PDDM',
  'PDED',
  'PDIC',
  'PDMP',
  'PDRD',
  'PEEA',
  'PEEB',
  'PENA',
  'PENB',
  'PESS',
  'PGMN',
  'PMCE',
  'PMCI',
  'PMCL',
  'PMCM',
  'PMCS',
  'PMCX',
  'PMMC',
  'PMPM',
  'RANK',
  'RPAM',
  'RRTA',
  'RRTB',
  'RSAA',
  'RSAH',
  'RSBA',
  'RSBF',
  'RSBS',
  'RSBV',
  'RSGS',
  'SPBF',
  'SSAA',
  'SSAD',
  'SSAE',
  'TEA9',
  'TWPFLM',
];
