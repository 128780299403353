import styled from 'styled-components';

import NavHeader from './NavHeader';

const Container = styled.div``;
const Body = styled.div`
  border: ${({ theme }) => theme.borders.thin.solid1};
`;

interface Props {
  children: any;
  className?: string;
  onNext(): void;
  onPrev(): void;
  reportTitle: string;
}

const ReportPreview = ({ children, className, onNext, onPrev, reportTitle }: Props) => (
  <Container className={className}>
    <NavHeader
      onNext={onNext}
      onPrev={onPrev}
    >
      {reportTitle}
    </NavHeader>
    <Body>{children}</Body>
  </Container>
);

export default ReportPreview;
