import { OverviewSchedule } from '../../../types/cwt/overview';
import { Path, Remote } from '../../types';

const getOverview = (path: Path, remote: Remote) => (workorderId?: number) => remote.get(path(`/${workorderId}`));

const updateScheduleOverview = (path: Path, remote: Remote) => (workorderId: number, data: OverviewSchedule) =>
  remote.put(path(`/`), { ...data, workorderId });

const getWorkorderRelatedWOsAndPMs = (path: Path, remote: Remote) => (location?: string, siteId?: string) =>
  remote.get(path(`/wos/${location}`), { params: { siteId } });

const overview = (path: Path, remote: Remote) => ({
  getOverview: getOverview(path, remote),
  updateScheduleOverview: updateScheduleOverview(path, remote),
  getWorkorderRelatedWOsAndPMs: getWorkorderRelatedWOsAndPMs(path, remote),
});

export default overview;
