import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import Api from '../../../api';
import { Control, FieldErrors, useForm } from 'react-hook-form';
import { Overrides, OverviewSchedule } from '../../../types/cwt/overview';
import { PM, WorkOrder } from '../../../types/cwt/workorder';
import { useWODetailsModal } from '../hooks/useWODetailsModal';
import { useError } from '../components/ErrorContextProvider';

interface useOverviewValue {
  pms: PM[];
  control: Control<OverviewSchedule, any>;
  errors: FieldErrors<OverviewSchedule>;
  handleSubmit: any;
  wos: WorkOrder[];
  overrides: Overrides;
  handleChangeOverride: (groupId: number, overrideValue: boolean | null) => void;
  woReady: boolean | null;
  woReadyInfo: ReadyInfo | null;
  handleSetOverride: (woReadyValue: boolean | null) => void;
  handleGetReadyState: () => void;
  woReadyApiError: string;
  setWoReadyApiError: (error: string) => void;
  workorder?: WorkOrder;
  workorderCanReady: boolean | undefined;
  handleSetWorkorderCanBeReady: () => void;
  pmsMessage: string;
  wosMessage: string;
}

interface Props {
  children: ReactNode;
}
interface ReadyInfo {
  woReadyBy: string;
  woReadyAt: string;
}
const OverviewContext = createContext<useOverviewValue | undefined>(undefined);

export const OverviewContextProvider = ({ children }: Props) => {
  const [pms, setPms] = useState<PM[]>([]);
  const [pmsMessage, setPmsMessage] = useState<string>('No records available');
  const [wos, setWOs] = useState<WorkOrder[]>([]);
  const [wosMessage, setWOsMessage] = useState<string>('No records available');
  const { workorder } = useWODetailsModal();
  const { handleError } = useError();
  const [woReady, setWoReady] = useState<boolean | null>(null);
  const [woReadyInfo, setWoReadyInfo] = useState<ReadyInfo | null>(null);
  const [woReadyApiError, setWoReadyApiError] = useState<string>('');
  const [workorderCanReady, setWorkorderCanReady] = useState<boolean | undefined>(undefined);

  const [overrides, setOverrides] = useState<Overrides>({
    assessing: null,
    sc: null,
    eng: null,
    ops: null,
    other: null,
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<OverviewSchedule>({
    defaultValues: {
      start: undefined,
      end: undefined,
      crewId: workorder?.crewworkgroup,
    },
  });

  const getRelatedWOsAndPMs = useCallback(async () => {
    if (workorder) {
      try {
        const res = await Api.cwt.getWorkorderRelatedWOsAndPMs(workorder.location, workorder.facilityId);
        if (typeof res.relatedPMs === 'string') {
          setPmsMessage(res.relatedPMs);
          setPms([]);
        } else {
          const relatedPMs = res.relatedPMs.filter((pm: PM) => pm.siteid.toLowerCase() === workorder.facilityId);
          setPms(relatedPMs);
        }
        if (typeof res.relatedWOs === 'string') {
          setWOsMessage(res.relatedWOs);
          setWOs([]);
        } else {
          const relatedWOs = res.relatedWOs.filter((wo: WorkOrder) => wo.facilityId === workorder.facilityId);
          setWOs(relatedWOs);
        }
      } catch (error) {
        handleError(error as Error);
      }
    }
  }, [workorder]);

  const getOverview = useCallback(async () => {
    if (!workorder?.id) return;
    try {
      const overview = await Api.cwt.getOverview(workorder?.id);
      if (!overview) throw new Error('overview null');
      setValue('start', overview.start);
      setValue('end', overview.end);
      if (overview.crewId) setValue('crewId', overview.crewId);
      setOverrides(overview.groupStatusOverrides);
    } catch (error) {
      handleError(error as Error);
    }
  }, [workorder, woReady]);

  const handleChangeOverride = async (groupId: number, overrideValue: boolean | null) => {
    if (!workorder?.id) return;
    try {
      try {
        await Api.cwt.updateOverrides(workorder.id, groupId, overrideValue);
      } catch (error) {
        handleError(error as Error);
      }
      await handleGetReadyState();
      await handleSetWorkorderCanBeReady();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const setup = async () => {
      await getOverview();
      await getRelatedWOsAndPMs();
      await handleSetWorkorderCanBeReady();
    };
    void setup();
  }, [workorder]);

  const onSubmit = async (data: OverviewSchedule) => {
    if (!workorder?.id) return;
    try {
      await Api.cwt.updateScheduleOverview(workorder.id, data);
    } catch (e) {
      handleError(e as Error);
    }
  };
  //WO Ready Functions
  const handleSetOverride = useCallback(async (woReadyValue: boolean | null) => {
    if (!workorder?.id) return;
    try {
      await Api.cwt.updateWorkorderMetadata(workorder?.id, { woReady: woReadyValue });
    } catch (e) {
      setWoReadyApiError('Failed to ready workorder, Please refresh to pull in the latest information');
      console.error(e);
    }
  }, []);

  const handleGetReadyState = useCallback(async () => {
    if (!workorder?.id) return;
    try {
      const res = await Api.cwt.getWorkorderMetadata(workorder?.id);
      if (!res) return;
      setWoReady(res.woReady);
      setWoReadyInfo({
        woReadyBy: res.updatedByName,
        woReadyAt: res.updatedAt,
      });
    } catch (e) {
      console.error(e);
    }
  }, [workorder]);

  const handleSetWorkorderCanBeReady = async () => {
    if (!workorder?.id) return;
    try {
      const res: WorkOrder = await Api.cwt.getWorkorder(workorder.id);
      if (!res) return;
      const canBeReady = res.assessingReady && res.scReady && res.engReady && res.opsReady && res.otherReady;
      setWorkorderCanReady(canBeReady);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <OverviewContext.Provider
      value={{
        pms,
        control,
        errors,
        handleSubmit: handleSubmit(onSubmit),
        wos,
        overrides: overrides,
        handleChangeOverride,
        woReady,
        woReadyInfo,
        handleSetOverride,
        handleGetReadyState,
        woReadyApiError,
        setWoReadyApiError,
        workorderCanReady,
        workorder,
        handleSetWorkorderCanBeReady,
        pmsMessage,
        wosMessage,
      }}
    >
      {children}
    </OverviewContext.Provider>
  );
};

export const useOverview = () => {
  const context = useContext(OverviewContext);
  if (context === undefined) {
    throw new Error('useOverview must be used within a OverviewContextProvider');
  }
  return context;
};
