import styled from 'styled-components';
import UnstyledPaper from '@material-ui/core/Paper';
import useMyAssignments from '../../../../hooks/my-assignments';
import useSelectedItem from '../../../../hooks/selected-item';
import Page from '../../../../components/Page';
import ElementFilterPanel from '../../../../components/ElementFilterPanel';
import isFeatureEnabled from '../../../../utils/feature-flags';
import ChartPanel from './ChartPanel';
import { BreadcrumbBroadcaster } from '../../../../context/breadcrumbs';
import { type MyElement } from '../../../../types/my';

const Paper = styled(UnstyledPaper)`
  min-height: calc(100vh - 100px);
`;

const ForecastPage = () => {
  const { myAssignments } = useMyAssignments();
  const { selectedItem: selectedAssignment, selectItem: selectAssignment } = useSelectedItem(
    myAssignments,
    'elementId'
  );

  return (
    <>
      <BreadcrumbBroadcaster pushCrumb="Forecast" />
      <Page
        title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Element Forecast'}
        bodyComponent={<Paper square />}
      >
        <ElementFilterPanel
          elements={myAssignments}
          selectedElement={selectedAssignment as MyElement}
          onChangeElement={selectAssignment}
        />
        {selectedAssignment ? <ChartPanel selectedAssignment={selectedAssignment} /> : <div />}
      </Page>
    </>
  );
};

export default ForecastPage;
