import React from 'react';
import Paper from '@material-ui/core/Paper';
import Page from '../../../../components/Page';
import AdminCategoriesTable from './AdminCategoriesTable';
import useAdminCategories from '../../../../hooks/admin_categories';
import isFeatureEnabled from '../../../../utils/feature-flags';
import { BreadcrumbBroadcaster } from '../../../../context/breadcrumbs';

const AdminCategoriesPage = () => {
  const { categories, deleteCategory, addCategory, updateCategory } = useAdminCategories();
  return (
    <>
      <BreadcrumbBroadcaster pushCrumb="Admin Categories" />
      <Page
        title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Admin - Admin Categories'}
        bodyComponent={<Paper square />}
      >
        <AdminCategoriesTable
          categories={categories}
          onDelete={deleteCategory}
          onAddCategory={addCategory}
          onEdit={updateCategory}
        />
      </Page>
    </>
  );
};

export default AdminCategoriesPage;
