import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { format } from 'date-fns';
import { RenderMaximoTicketLink } from '../../utils';
import CWTToolTip from '../../../CWTToolTip';
import { useRelatedRecords } from '../../../../hooks/useRelatedRecords';
const gridStyle = { minWidth: '100%' };

const EngHoldsTable = ({ style }: any) => {
  const { getEngRecords, loading } = useRelatedRecords();

  const columns = [
    {
      name: 'ticketid',
      header: 'Ticket ID',
      defaultWidth: 100,
      render: RenderMaximoTicketLink,
    },
    {
      name: 'description',
      header: 'Description',
      defaultWidth: 150,
      defaultFlex: 1,
      render: ({ value }: any) => <CWTToolTip value={value}></CWTToolTip>,
    },
    {
      name: 'status',
      header: 'Status',
      defaultWidth: 150,
    },
    {
      name: 'statusdate',
      header: 'Status Date',
      defaultWidth: 150,
      render: ({ value }: any) => (value ? format(new Date(value), 'ddMMMyyyy HH:mm').toUpperCase() : undefined),
    },
    {
      name: 'targetfinish',
      header: 'Target Finish',
      defaultWidth: 150,
      render: ({ value }: any) => (value ? format(new Date(value), 'ddMMMyyyy HHmm').toUpperCase() : undefined),
    },
  ];

  return (
    <ReactDataGrid
      idProperty="id"
      style={{ ...gridStyle, ...style }}
      enableKeyboardNavigation={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuLockOptions={false}
      showColumnMenuTool={false}
      columns={columns}
      dataSource={getEngRecords()}
      rowHeight={null}
      loading={loading}
    />
  );
};

export default EngHoldsTable;
