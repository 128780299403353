// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { StylesProvider } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { History } from 'history';
import isFeatureEnabled from './utils/feature-flags';

import LoginPage from './pages/login/LoginPage';
import theme from './theme';
import DefaultLayout from './layouts/default/DefaultLayout';
import CWTLayout from './layouts/cwt/CWTLayout';
import { MsalProvider, MsalAuthenticationTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import msalInstance from './auth/msalInstance';
import { isAllowedForRoles } from './auth/roles';
import BreadcrumbProvider from './context/breadcrumbs';
import 'material-icons/iconfont/material-icons.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/300.css';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Roboto, 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    font-size: 100%;
  }
  * {
    box-sizing: border-box;
  }
  ::-webkit-scrollbar {
    width: 5px;
    background-color: inherit;
  }

  ::-webkit-scrollbar-thumb {
    background-color: lightGrey;
    border-radius: 10px;
  }
`;

// Users should never see this page. It exists only as a authentication redirect.
const LoggedInPage = () => (
  <>
    <h1>ECHO</h1>
    <p>You have been authenticated</p>
  </>
);

const UnauthenticatedRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/login"
        component={LoginPage}
      />
      <Route
        exact
        path="/auth"
        component={LoggedInPage}
      />
    </Switch>
  );
};

// This standalone component is required for correct routing behaviour to role-limited routes.
// Routes need to re-render after the user has logged in to pick up user roles.
const AuthenticatedRoutes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/app/dashboard"
      />
      {isFeatureEnabled('cwt') && (
        <Redirect
          exact
          from="/cwt"
          to="/cwt/wo"
        />
      )}
      <Route
        path="/app"
        component={DefaultLayout}
      />
      {isFeatureEnabled('cwt') &&
        (isFeatureEnabled('cwtAllowAllBPUsers') || isAllowedForRoles(['CWTUser', 'CWTAdmin'])) && (
          <Route
            path="/cwt"
            component={CWTLayout}
          />
        )}
      <Route path="*">
        <Redirect to="/app/dashboard" />
      </Route>
    </Switch>
  );
};

interface Props {
  history: History;
}
const AppContent = () => {
  if (isFeatureEnabled('echoBreadcrumbsUi')) {
    return (
      <BreadcrumbProvider>
        <UnauthenticatedTemplate>
          <UnauthenticatedRoutes />
        </UnauthenticatedTemplate>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <AuthenticatedRoutes />
        </MsalAuthenticationTemplate>
      </BreadcrumbProvider>
    );
  }
  return (
    <>
      <UnauthenticatedTemplate>
        <UnauthenticatedRoutes />
      </UnauthenticatedTemplate>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <AuthenticatedRoutes />
      </MsalAuthenticationTemplate>
    </>
  );
};
const App = ({ history }: Props) => (
  <StylesProvider injectFirst>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <MsalProvider instance={msalInstance}>
        <ConnectedRouter history={history}>
          <AppContent />
        </ConnectedRouter>
      </MsalProvider>
    </ThemeProvider>
  </StylesProvider>
);

export default App;
