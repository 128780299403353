import { format } from 'date-fns';
import { Box } from '@material-ui/core';
import { getActionStatusColor } from '../../../actions/utils';
import { getLuminance } from 'polished';

export const renderDateAdded = ({ data }: any) => {
  if (!data.createdAt) return;
  return <Box>{format(new Date(data.createdAt), 'ddMMMyyyy').toUpperCase()}</Box>;
};
export const renderTCD = ({ data }: any) => {
  if (!data.tcd) return;
  return <Box>{format(new Date(data.tcd), 'ddMMMyyyy').toUpperCase()}</Box>;
};
export const renderTBE = ({ data }: any) => {
  if (!data.tbe) return;
  return <Box>{format(new Date(data.tbe), 'ddMMMyyyy').toUpperCase()}</Box>;
};
export const onRenderRow = ({ data }: any) => {
  const backgroundColor = getActionStatusColor(data.statusId);
  return { backgroundColor, color: getLuminance(backgroundColor) < 0.5 ? 'white' : undefined };
};
