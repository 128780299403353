import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Api from '../../../api';
import * as actionTypes from '../../../state/action-types';
import * as pageActions from './actions';
import * as recordsSummaryActions from '../../../state/records-summary/actions';

function* startReport(action) {
  try {
    const { elementId, year, quarter } = action.payload;
    const { id: reportId } = yield call(Api.createReport, elementId, year, quarter);
    yield put(push(`/app/health-reports/${reportId}`, { isFromNew: true }));
  } catch (e) {
    yield put(pageActions.startReportFailure(e, e.response.data.message));
  }
}

function* updateIndicatorRecord(action) {
  try {
    const { indicatorId, recordId, elementId, applicable, options } = action.payload;
    yield call(Api.updateIndicatorRecord, indicatorId, recordId, applicable, options);
    yield put(recordsSummaryActions.loadRecordsSummaryRequest(indicatorId, elementId));
  } catch (e) {
    yield put(pageActions.updateIndicatorRecordFailure(e));
  }
}

function* root() {
  yield takeEvery(actionTypes.HEALTH_REPORT_START_REPORT_REQUEST, startReport);
  yield takeEvery(actionTypes.HEALTH_REPORT_UPDATE_INDICATOR_RECORD_REQUEST, updateIndicatorRecord);
}

export default root;
