import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import Api from '../../../api';
import { Record } from '../../../types/cwt/relatedrecords';
import { WorkOrder } from '../../../types/cwt/workorder';
import { useError } from '../components/ErrorContextProvider';
interface useRelatedRecordsValue {
  records: Record[];
  loading: boolean;
  getEngRecords: () => Record[];
  getSCRecords: () => Record[];
  getTCCRecords: () => Record[];
}

interface Props {
  children: ReactNode;
  workorder?: WorkOrder;
}
const RelatedRecordsContext = createContext<useRelatedRecordsValue | undefined>(undefined);

export const RelatedRecordsContextProvider = ({ children, workorder }: Props) => {
  const [records, setRecords] = useState<Record[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { handleError } = useError();

  useEffect(() => {
    const getRelatedRecords = async () => {
      if (!workorder) return;
      try {
        const res = await Api.cwt.getWorkorderRelatedRecords(workorder.id);
        setRecords(res);
      } catch (error) {
        handleError(error as Error);
      }
      setLoading(false);
    };

    void getRelatedRecords();
  }, [workorder]);

  const getEngRecords = () => {
    return records.filter(record => record.group === 'ENG');
  };
  const getSCRecords = () => {
    return records.filter(record => record.group === 'SC');
  };
  const getTCCRecords = () => {
    return records.filter(record => record.group === 'TCC');
  };

  return (
    <RelatedRecordsContext.Provider
      value={{
        records,
        loading,
        getEngRecords,
        getSCRecords,
        getTCCRecords,
      }}
    >
      {children}
    </RelatedRecordsContext.Provider>
  );
};

export const useRelatedRecords = () => {
  const context = useContext(RelatedRecordsContext);
  if (context === undefined) {
    throw new Error('useRelatedRecords must be used within a RelatedRecordsContextProvider');
  }
  return context;
};
