import { Box, Grid } from '@material-ui/core';
import styled from 'styled-components';
import ActionButton from '../../../../../../components/ActionButton';
import FormDialog from '../../../../../../components/FormDialog';
import { useForm } from 'react-hook-form';
import { ControlTextField } from '../../../../../../form/ControlTextField';
import { ScheduleNotes } from '../../../../../../types/cwt/schedule';
import { useState } from 'react';
import Api from '../../../../../../api'; // Adjust the import path as necessary
import { useSchedule } from '../../../../hooks/useSchedule';
import { useError } from '../../../ErrorContextProvider';
import { ScheduleNotesTable } from './ScheduleNotesTable';

const ScheduleNoteModal = ({ workorder }: any) => {
  const [openAddNotesDialog, setOpenAddNotesDialog] = useState<boolean>(false);
  const { fetchScheduleNotes } = useSchedule();
  const { handleError } = useError();
  const onAddNotes = async (data: ScheduleNotes) => {
    if (workorder) {
      try {
        try {
          await Api.cwt.createScheduleNotes(workorder.id, data.note);
        } catch (error) {
          handleError(error as Error);
        }
        handleCloseAddNotesDialog();
        await fetchScheduleNotes();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const AddNoteButton = styled(ActionButton)`
    margin-bottom: 10px;
    margin-top: 10px;
  `;

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<ScheduleNotes>();

  const handleOpenAddNotesDialog = () => {
    setOpenAddNotesDialog(true);
  };
  const handleCloseAddNotesDialog = () => {
    setOpenAddNotesDialog(false);
    reset();
  };

  return (
    <Box>
      <AddNoteButton
        color="primary"
        onClick={handleOpenAddNotesDialog}
      >
        Add Note
      </AddNoteButton>

      {openAddNotesDialog && (
        <FormDialog
          title={'Add Schedule Note'}
          onCancel={handleCloseAddNotesDialog}
          onSave={handleSubmit(onAddNotes)}
        >
          <Grid
            container
            style={{ minWidth: '400px' }}
          >
            <Grid
              item
              xs={12}
            >
              <ControlTextField
                control={control}
                name="note"
                label="Note"
                fullWidth
                multiline
                variant="outlined"
                placeholder="Character Limitation: 1000 Characters"
                required={true}
                error={!!errors.note}
                maxLength={1000}
                minLength={1}
                minRows={3}
              />
            </Grid>
          </Grid>
        </FormDialog>
      )}
      <ScheduleNotesTable />
    </Box>
  );
};

export default ScheduleNoteModal;
