import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Page from '../../../../components/Page';
import useElements from '../../../../hooks/elements';
import useQuery from '../../../../hooks/query';
import ElementList from './ElementList';
import Element from './Element';
import DropdownAutocomplete from '../../../../components/DropdownAutocomplete';
import { ElementDataProvider } from '../../hooks/element-data';
import CreateElementForm from './CreateElementPopup';
import { ElementCreatorProvider } from '../../hooks/element-creator';
import isFeatureEnabled from '../../../../utils/feature-flags';
import { BreadcrumbBroadcaster } from '../../../../context/breadcrumbs';

const ScrollWrapper = styled.div`
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
`;

const ElementsPage = () => {
  const [filter, setFilter] = useState<string | null>('none');
  const history = useHistory();
  const { elementId } = useQuery() as any;
  const { elements } = useElements();
  const selectedElement = useMemo(() => {
    if (R.isEmpty(elements) || R.isNil(elementId)) {
      return undefined;
    }
    return R.find(R.propEq('id', parseInt(elementId, 10)), elements);
  }, [elements, elementId]);

  const handleChangeElement = useCallback(
    (newElementId: any) => {
      history.replace(`/app/admin/elements?elementId=${newElementId}`);
    },
    [history]
  );

  const filteredElements = useMemo(
    () => elements.filter(e => (filter !== 'none' ? e.type === filter : e)),
    [elements, filter]
  );

  return (
    <>
      <BreadcrumbBroadcaster pushCrumb="Elements" />
      <Page title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Admin - Elements'}>
        <ElementDataProvider>
          <ElementCreatorProvider>
            <CreateElementForm />
          </ElementCreatorProvider>

          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <DropdownAutocomplete
                onChange={val => setFilter(val)}
                label="Element Type"
                options={[
                  { value: 'none', label: 'None' },
                  { value: 'component', label: 'Component' },
                  { value: 'system', label: 'System' },
                  { value: 'program', label: 'Program' },
                ]}
              />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <ScrollWrapper>
                <ElementList
                  elements={filteredElements}
                  selectedElementId={elementId}
                  onSelectElementId={handleChangeElement}
                />
              </ScrollWrapper>
            </Grid>
            <Grid
              item
              xs={8}
            >
              {selectedElement && <Element element={selectedElement} />}
            </Grid>
          </Grid>
        </ElementDataProvider>
      </Page>
    </>
  );
};

export default ElementsPage;
