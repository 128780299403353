import axios from 'axios';
import migrate, { MAX_DB_REVISION, SCHEMA_VERSION } from './migrate';
import {
  checkBroadcastDebug,
  axiosRequestInterceptor,
  axiosResponseInterceptor,
  broadcastMessageHandler,
} from './helpers';
import { IndexedDbEvents, type MigrateParams, type ClientDb, type ApiRequestRow } from './types';

const isBroadcastDebug = checkBroadcastDebug();
const windowOriginId = `${new Date().getTime()}-${window.btoa(Math.floor(Math.random() * 1000000).toString())}`;

const axiosResponseInterceptors = (responseEvent: any) => {
  axiosResponseInterceptor(clientDb, responseEvent);
  return responseEvent;
};
axios.interceptors.response.use(axiosResponseInterceptors);
axios.interceptors.request.use(axiosRequestInterceptor);

const clientDb: ClientDb = {
  eventHandler: new IndexedDbEvents(),
  broadcastChannel: new BroadcastChannel('echo-client-bc'),
  broadcastDebug: (...args) => {
    isBroadcastDebug && console.log('[LOCAL-BROADCAST-DEBUG]', ...(args instanceof Array ? args : [args]));
    clientDb.broadcastChannel.postMessage(JSON.stringify({ eventType: 'log', origin: windowOriginId, payload: args }));
  },
  truncate: async () => {
    const db = clientDb.db as IDBDatabase;
    const transaction = db.transaction(['api_requests'], 'readwrite');
    const objectStore = transaction.objectStore('api_requests');
    await clientDb.transact(() => objectStore.clear());
  },
  httpRegistrations: [],
  registerHttp: reg => {
    clientDb.httpRegistrations.push(reg);
  },
  unregisterHttp: reg => {
    const pos = clientDb.httpRegistrations.findIndex(r => r === reg);
    if (pos < 0) {
      return false;
    }
    // mutates original and returns array of removed
    clientDb.httpRegistrations.splice(pos, 1);
    return true;
  },
  subscribe: (eventName, fn) => {
    clientDb.eventHandler.addEventListener(eventName, fn);
  },
  unsubscribe: (eventName, fn) => {
    clientDb.eventHandler.removeEventListener(eventName, fn);
  },
  transact: async action => {
    const readResult = await new Promise((resolve, reject) => {
      const job = action();
      job.onsuccess = (event: any) => {
        if (!event?.target?.result) {
          resolve(null);
          return;
        }
        resolve(event?.target?.result as ApiRequestRow);
      };
      job.onerror = (event: any) => {
        reject(event?.target?.error ?? new Error('Something went wrong'));
      };
    });
    return readResult;
  },
  transactUpdate: async (getAction, action) => {
    const result = await clientDb.transact(getAction);
    if (!result) {
      throw new Error('Row does not exist!');
    }
    const writeResult = await new Promise((resolve, reject) => {
      const job = action(result);
      job.onsuccess = (event: any) => {
        if (!event?.target?.result) {
          resolve(null);
          return;
        }
        resolve(event?.target?.result as ApiRequestRow);
      };
      job.onerror = (event: any) => {
        reject(event?.target?.error ?? new Error('Something went wrong with the update'));
      };
    });
    return writeResult;
  },
  bodyEncoder: (bodyObject: string) => window.btoa(JSON.stringify(bodyObject)),
  getRow: async keyPath => {
    const db = clientDb.db as IDBDatabase;
    const transaction = db.transaction(['api_requests'], 'readonly');
    const objectStore = transaction.objectStore('api_requests');
    const dbRow = await clientDb.transact(() => objectStore.get(keyPath));
    return dbRow;
  },
  generateRequestUrlSlug: ({ userId, requestUrl, requestBody }) => {
    let slug = `${userId}@${requestUrl}`;
    if (requestBody) {
      slug = slug + `:BODY(::${clientDb.bodyEncoder(JSON.stringify(requestBody))}::)`;
    }
    return slug;
  },
  write: async (params, options = {}) => {
    const { isEventMuted = false } = options;
    const db = clientDb.db as IDBDatabase;
    const {
      requestContext,
      requestContext: { requestUrl, requestBody, userId },
      response,
    } = params;
    const lastUpdated =
      params.lastUpdated instanceof Date ? params.lastUpdated : new Date(params.lastUpdated ?? Date.now());
    const lastChecked =
      params.lastChecked instanceof Date ? params.lastChecked : new Date(params.lastChecked ?? Date.now());
    // checking date from server trim MS off
    lastUpdated.setMilliseconds(0);
    lastChecked.setMilliseconds(0);
    const requestUrlSlug = clientDb.generateRequestUrlSlug({ userId, requestUrl, requestBody });
    const transaction = db.transaction(['api_requests'], 'readwrite');
    const objectStore = transaction.objectStore('api_requests');
    await clientDb.transact(() =>
      objectStore.add({
        schema: SCHEMA_VERSION,
        requestUrlSlug,
        lastUpdated,
        lastChecked,
        requestContext,
        response,
      })
    );
    if (!isEventMuted) {
      const originId = params.originId || `${new Date().getTime()}|WINDOWID:${windowOriginId}`;
      const globalWritePayload = {
        originId,
        keyPath: requestUrlSlug,
        params: { ...params, requestUrlSlug, lastChecked, lastUpdated },
      };
      const specificWritePayload = { keyPath: requestUrlSlug, params };
      clientDb.eventHandler.dispatchEvent(new CustomEvent('write', { detail: globalWritePayload }));
      clientDb.eventHandler.dispatchEvent(new CustomEvent(`write:${requestUrlSlug}`, { detail: specificWritePayload }));
      clientDb.broadcastChannel.postMessage(
        JSON.stringify({ eventType: 'write', origin: windowOriginId, payload: globalWritePayload })
      );
      clientDb.broadcastChannel.postMessage(
        JSON.stringify({ eventType: `write:${requestUrlSlug}`, origin: windowOriginId, payload: specificWritePayload })
      );
    }
  },
  update: async (keyPath, params, options = {}) => {
    const { isEventMuted = false } = options;
    if (!params.lastChecked) {
      throw new Error(`When updating please provided the Last Checked. Got 'lastChecked' ${params.lastChecked}`);
    }
    const db = clientDb.db as IDBDatabase;
    const transaction = db.transaction(['api_requests'], 'readwrite');
    const objectStore = transaction.objectStore('api_requests');
    const isOnlyDateCheckedUpdated = !!(params && Object.keys(params).length === 1 && 'lastChecked' in params);
    await clientDb.transactUpdate(
      () => objectStore.openCursor(keyPath),
      row => {
        if (!row?.value) {
          return;
        }
        const paramsCopy = { ...params };
        if ('requestUriSlug' in paramsCopy) {
          // do not change key!
          delete paramsCopy.requestUriSlug;
        }
        if ('eltIds' in paramsCopy) {
          // do not change key!
          delete paramsCopy.eltIds;
        }
        const lastUpdated = params.lastUpdated instanceof Date ? params.lastUpdated : row.value.lastUpdated;
        const lastChecked = params.lastChecked instanceof Date ? params.lastChecked : row.value.lastChecked;
        // checking date from server trim MS off
        lastUpdated.setMilliseconds(0);
        lastChecked.setMilliseconds(0);

        return row.update({ ...row.value, ...paramsCopy, lastUpdated, lastChecked });
      }
    );
    if (!isEventMuted && !isOnlyDateCheckedUpdated) {
      const dbRow = await clientDb.transact(() => objectStore.get(keyPath));
      const { requestUrlSlug } = dbRow;
      const globalWritePayload = {
        keyPath: requestUrlSlug,
        params: dbRow,
      };
      const specificWritePayload = { keyPath: requestUrlSlug, params };
      clientDb.eventHandler.dispatchEvent(new CustomEvent('write', { detail: globalWritePayload }));
      clientDb.eventHandler.dispatchEvent(new CustomEvent(`write:${requestUrlSlug}`, { detail: specificWritePayload }));
      clientDb.broadcastChannel.postMessage(
        JSON.stringify({ eventType: 'write', origin: windowOriginId, payload: globalWritePayload })
      );
      clientDb.broadcastChannel.postMessage(
        JSON.stringify({ eventType: `write:${requestUrlSlug}`, origin: windowOriginId, payload: specificWritePayload })
      );
    }
  },
  remove: async (keyPath, options = {}) => {
    const { isEventMuted = false } = options;
    const db = clientDb.db as IDBDatabase;
    const dbRow = await clientDb.getRow(keyPath);
    const {
      requestUrlSlug,
      requestContext: { requestUrl },
    } = dbRow;
    if (!dbRow) {
      throw new Error(`Record not found from keyPath of '${keyPath}'`);
    }
    await new Promise((resolve, reject) => {
      const transaction = db.transaction(['api_requests'], 'readwrite');
      const objectStore = transaction.objectStore('api_requests');
      transaction.oncomplete = () => {
        if (isEventMuted) {
          return;
        }
        const globalWritePayload = { keyPath, params: { ...dbRow, requestUrl } };
        const specificWritePayload = { keyPath, params: dbRow };
        clientDb.eventHandler.dispatchEvent(new CustomEvent('remove', { detail: globalWritePayload }));
        clientDb.eventHandler.dispatchEvent(
          new CustomEvent(`remove:${requestUrlSlug}`, { detail: specificWritePayload })
        );
        clientDb.broadcastChannel.postMessage(
          JSON.stringify({ eventType: 'remove', origin: windowOriginId, payload: globalWritePayload })
        );
        clientDb.broadcastChannel.postMessage(
          JSON.stringify({
            eventType: `remove:${requestUrlSlug}`,
            origin: windowOriginId,
            payload: specificWritePayload,
          })
        );

        resolve(dbRow);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      objectStore.delete(keyPath);
    });
  },
  initPromise: null,
  db: null,
  rq: window.indexedDB.open('echo-client-db', MAX_DB_REVISION),
};

export const setup = async () => {
  const { rq } = clientDb;

  if (clientDb.initPromise) {
    return clientDb.initPromise;
  }

  isBroadcastDebug && clientDb.broadcastDebug(`INIT`);

  clientDb.initPromise = new Promise((resolve, reject) => {
    rq.onerror = (event: any) => {
      reject(event.target.error);
    };
    rq.onupgradeneeded = (event: any) => {
      if (!event?.target?.result) {
        return;
      }
      const eventTarget = event?.target;
      const db = eventTarget?.result as IDBDatabase;
      const eventNewVersion = event?.newVersion;
      const eventOldVersion = event?.oldVersion;
      const newVersion = typeof eventNewVersion !== 'number' ? parseInt(eventNewVersion) : eventNewVersion;
      const oldVersion = typeof eventOldVersion !== 'number' ? parseInt(eventOldVersion) : eventOldVersion;
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      migrate({ db, oldVersion, newVersion } as MigrateParams)
        .then(console.log.bind(console, '\nMigration Successful!'))
        .catch(console.error.bind(console, '\nMigration Failed!\n'));
    };
    rq.onsuccess = (event: any) => {
      const db = event?.target?.result;
      resolve(db as IDBDatabase);
    };
  });

  clientDb.broadcastChannel.onmessage = broadcastMessageHandler(clientDb);
  clientDb.db = await clientDb.initPromise;

  return clientDb;
};

export default clientDb;
