import { useCallback, useEffect, useState } from 'react';
import EventEmitter from 'events';
import { RiskEnvironmentGroupFormInputs, USER_ROLES } from '../../../../types/risk-management';
import { Control, FieldErrors, SubmitHandler, useForm } from 'react-hook-form';
import Api from '../../../../api';
import { useLocation } from 'react-router-dom';
import * as uiActions from '../../../../state/ui/actions';
import { useDispatch } from 'react-redux';
import { useRiskManagementUsers } from '../../../../context/business-equipment-risk-admin';
import { SubmitTextStatus } from '../SubmitStatusText';
import useDelayedRedirect from '../../../../hooks/delayed-redirect';

export type RiskEnvironmentGroupForm = {
  loading: boolean;
  control: Control<RiskEnvironmentGroupFormInputs>;
  handleSubmit: () => Promise<void>;
  submitTextStatus: SubmitTextStatus;
  probability: RiskEnvironmentGroupFormInputs['probability'];
  consequence: RiskEnvironmentGroupFormInputs['consequence'];
  errors: FieldErrors<RiskEnvironmentGroupFormInputs>;
  environmentCategories: Record<string, string>;
  significantEnvironmentalActivities: Record<string, string>;
  consequenceOptions: { label: string; value: number }[];
  probabilityOptions: { label: string; value: number }[];
  isReadOnly: boolean;
  saveDraft: () => Promise<void>;
  watchEnvTeamContacts: string;
  watchEnvConsequenceDescription: string;
};

const arrayToObj = (acc: any, curr: any) => {
  acc[curr.id] = curr.name;
  return acc;
};

const ENVIRONMENT_CONSEQUENCE = [
  { label: 'None (0)', value: 0 },
  { label: 'Negligible (1)', value: 1 },
  { label: 'Low (2)', value: 2 },
  { label: 'Medium (3)', value: 3 },
  { label: 'High (4)', value: 4 },
  { label: 'Severe (5)', value: 5 },
];

const ENVIRONMENT_PROBABILITY = [
  { label: 'Will Not Occur (0)', value: 0 },
  { label: 'Remote (1)', value: 1 },
  { label: 'Low (2)', value: 2 },
  { label: 'Medium (3)', value: 3 },
  { label: 'High (4)', value: 4 },
  { label: 'Certain / Almost Certain (5)', value: 5 },
];

const INITIAL_VALUES: RiskEnvironmentGroupFormInputs = {
  peerGroupOversight: 'No',
  continuousMonitoring: false,
  significantEnvironmentalActivities: {},
  environmentCategories: {},
  envConsequenceDescription: '',
  envTeamContacts: '',
  consequence: 0,
  probability: 1,
};

export const useRiskEnvironmentGroupForm = (riskManagementId?: number, status?: string): RiskEnvironmentGroupForm => {
  const { redirect } = useDelayedRedirect({ targetPath: '/app/business-equipment-risks' });
  const [generalEnvironmentCategories, setGeneralEnvironmentCategories] = useState<Record<string, string>>({});
  const [significantEnvironmentalActivities, setSignificantEnvironmentalActivities] = useState<Record<string, string>>(
    {}
  );
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const currentUserRoles = useRiskManagementUsers();
  const [loading, setLoading] = useState(false);
  const [submitTextStatus, setSubmitTextStatus] = useState<SubmitTextStatus>('default');

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    reset,
  } = useForm<RiskEnvironmentGroupFormInputs>({
    defaultValues: INITIAL_VALUES,
  });

  const resetSubmitState = () => {
    setSubmitTextStatus('default');
    setLoading(false);
  };

  const onSubmit: SubmitHandler<RiskEnvironmentGroupFormInputs> = async data => {
    setLoading(true);
    setSubmitTextStatus('submitting');
    try {
      if (!riskManagementId) return;
      await Api.submitEnvGroupForm(riskManagementId, data);
      dispatch(uiActions.genericMessage('Environmental Review Submitted'));
      setSubmitTextStatus('success');
      const eventEmitter = new EventEmitter();
      const callReset = () => resetSubmitState();
      eventEmitter.once('redirect-miss', callReset);
      eventEmitter.once('clear', callReset);
      await redirect({ targetPath: '/app/business-equipment-risks', eventEmitter });
      callReset();
    } catch (e) {
      console.error(e);
      dispatch(uiActions.error('', 'Error: Unable to submit or update environmental review'));
      resetSubmitState();
    }
  };

  const onSaveDraft = async () => {
    if (!riskManagementId) {
      return;
    }
    try {
      const data = getValues();
      await Api.updateEnvGroupForm(riskManagementId, data);
      dispatch(uiActions.genericMessage('Environmental Review Draft Saved'));
    } catch (e) {
      console.error(e);
      dispatch(uiActions.error('', 'Error: Unable to submit or update environmental review'));
    }
  };

  const getEnvironmentalCategories = useCallback(async () => {
    if (!riskManagementId) {
      return;
    }
    const data = await Api.getEnvironmentalCategories();
    const ge = data.generalEnvironmentCategories.reduce(arrayToObj, {});
    setGeneralEnvironmentCategories(ge);
    setSignificantEnvironmentalActivities(data.significantEnvironmentalActivities.reduce(arrayToObj, {}));
    const envGroup = await Api.getRiskEnvironmentGroup(riskManagementId);
    if (!envGroup) {
      reset(INITIAL_VALUES);
      return;
    }
    reset(envGroup);
  }, [riskManagementId]);

  useEffect(() => {
    void getEnvironmentalCategories();
  }, [riskManagementId]);

  return {
    loading,
    control,
    handleSubmit: handleSubmit(onSubmit),
    submitTextStatus,
    errors,
    probability: watch('probability'),
    consequence: watch('consequence'),
    environmentCategories: generalEnvironmentCategories,
    significantEnvironmentalActivities: significantEnvironmentalActivities,
    consequenceOptions: ENVIRONMENT_CONSEQUENCE,
    probabilityOptions: ENVIRONMENT_PROBABILITY,
    isReadOnly:
      loading ||
      pathname.includes('/archive') ||
      (pathname.includes('/review') && status === 'SUBMITTED') ||
      !currentUserRoles.includes(USER_ROLES.ENVIRONMENT_GROUP),
    saveDraft: onSaveDraft,
    watchEnvTeamContacts: watch('envTeamContacts'),
    watchEnvConsequenceDescription: watch('envConsequenceDescription'),
  };
};
