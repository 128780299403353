import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as R from 'ramda';
import { useRouteMatch } from 'react-router-dom';
import UnstyledPaper from '@material-ui/core/Paper';
import { Downloader } from '../../../../hooks/downloader';
import StartReportDialog from '../../components/StartReportDialog';
import ReportStateChangeCommentDialog from '../../../../components/ReportStateChangeCommentDialog';
import FilterPanel from './FilterPanel';
import ReportList from './ReportList';
import useReports, { type Filters } from '../../../../hooks/reports';
import useReportStarter from '../../hooks/report-starter';
import Page from '../../../../components/Page';
import * as reportActions from '../../../../state/reports/actions';
import FilterOptionsPanel from './FilterOptionsPanel';
import HistoricalReportList from './HistoricalReportList';
import { HistoricalReportsProvider } from '../../hooks/historical-reports';
import HistoricalReportFilter from '../../../../types/historical-report-filter';
import { CircularProgress, Typography } from '@material-ui/core';
import isFeatureEnabled from '../../../../utils/feature-flags';
import { BreadcrumbBroadcaster } from '../../../../context/breadcrumbs';
import { type Report } from '../../../../hooks/report';

const UpdatingText = styled(Typography)`
  font-size: 12px;
  margin-left: 9px;
`;
const UpdatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const Paper = styled(UnstyledPaper).attrs({
  square: true,
})``;

type FilterParams = HistoricalReportFilter & {
  facility: string;
};
type FilterSponge = { reports: Report[] | null; filters: FilterParams };
type TabsEnum = 'in-progress' | 'archived' | 'historical';
type RenderStateSponge = {
  componentStatus: 'init' | 'ready';
  currentTab: TabsEnum;
  filters: FilterParams;
  reports: {
    'in-progress': Report[] | null;
    archived: Report[] | null;
    historical: Report[] | null;
  };
  filtered: {
    'in-progress': FilterSponge | null;
    archived: FilterSponge | null;
    historical: FilterSponge | null;
  };
};
const tabToStates: Record<string, string[]> = {
  'in-progress': ['unsubmitted', 'pending-review', 'changes-requested'],
  archived: ['approved'],
  historical: [],
};

const useCloseReport = (reports: any) => {
  const dispatch = useDispatch();
  const [closingReport, setClosingReport] = useState(null);

  const confirmCloseReport = useCallback(
    (reportId: any) => {
      const report = R.find(R.propEq('id', reportId), reports);
      setClosingReport(report as any);
    },
    [reports, setClosingReport]
  );

  const cancelCloseReport = useCallback(() => {
    setClosingReport(null);
  }, [setClosingReport]);

  const closeReport = useCallback(() => {
    dispatch(reportActions.closeRequest(closingReport, 'Yup'));
    setClosingReport(null);
  }, [dispatch, closingReport, setClosingReport]);

  return {
    closingReport,
    confirmCloseReport,
    cancelCloseReport,
    closeReport,
  };
};

const LandingPage = () => {
  const [currentTab, setCurrentTab] = useState<TabsEnum>('in-progress');
  const [filters, setFilters] = useState<FilterParams>({
    elementName: 'none',
    facility: 'none',
    elementType: 'none',
    quarter: 'none',
    year: 'none',
  });
  const [renderStateSponge, setRenderStateSponge] = useState<RenderStateSponge>({
    componentStatus: 'init',
    currentTab: currentTab as RenderStateSponge['currentTab'],
    filters,
    reports: {
      'in-progress': null,
      archived: null,
      historical: null,
    },
    filtered: {
      'in-progress': { reports: null, filters },
      archived: { reports: null, filters },
      historical: { reports: null, filters },
    },
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const { url } = useRouteMatch();
  const { reports, getFilteredReports, years, isLoading } = useReports(tabToStates[currentTab], true);
  const { startReport } = useReportStarter();
  const { closingReport, confirmCloseReport, cancelCloseReport, closeReport } = useCloseReport(reports);

  const handleStartReport = (elementId: string, year: string, quarter: string) => {
    startReport(elementId, year, quarter);
    setDialogOpen(false);
  };
  const updateReportState = useCallback(() => {
    if (isLoading) {
      return;
    }

    setRenderStateSponge((prev: RenderStateSponge) => {
      // don't update state on first life-cycle-event - isLoading default is false
      if (prev.componentStatus === 'init') {
        return {
          ...prev,
          componentStatus: 'ready',
        };
      }
      return {
        ...prev,
        reports: {
          ...prev.reports,
          [currentTab]: reports,
        },
        filtered: {
          ...prev.filtered,
          [currentTab]: { reports: getFilteredReports(filters as Filters), filters },
        },
      };
    });
  }, [isLoading, filters, reports, getFilteredReports, setRenderStateSponge]);
  const updateReportStateFilters = useCallback(
    (updatedFilters: FilterParams) => {
      setRenderStateSponge((prev: RenderStateSponge) => ({
        ...prev,
        filters: updatedFilters,
        filtered: {
          ...Object.entries(prev.filtered).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: value && value.filters === updatedFilters ? value : { reports: null, filters: null },
            }),
            {} as RenderStateSponge['filtered']
          ),
          [prev.currentTab]: { reports: getFilteredReports(updatedFilters as Filters), filters: updatedFilters },
        },
      }));
    },
    [setRenderStateSponge]
  );
  const updateReportStateCurrentTab = useCallback(
    (selectedTab: TabsEnum) => {
      setRenderStateSponge((prev: RenderStateSponge) => ({
        ...prev,
        currentTab: selectedTab,
      }));
    },
    [setRenderStateSponge]
  );
  const onChangeTab = useCallback(
    (selectedTab: TabsEnum) => {
      if (!(selectedTab in tabToStates)) {
        return;
      }
      setCurrentTab(selectedTab);
    },
    [setCurrentTab]
  );
  const [historicalFilters, setHistoricalFilter] = useState<HistoricalReportFilter>({
    elementName: '',
    elementType: '',
    facilityId: '',
    year: '',
    quarter: '',
  });
  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (renderStateSponge.filters !== filters || renderStateSponge.currentTab !== currentTab) {
      if (renderStateSponge.filters !== filters) {
        // handle filter change - no http-updates
        updateReportStateFilters(filters);
      }
      // handle tab change - has http-updates
      if (renderStateSponge.currentTab !== currentTab) {
        updateReportStateCurrentTab(currentTab);
      }
      updateReportState();
      return;
    }

    // handle first loading
    const whichReports =
      Object.entries(filters).filter(([, value]) => value && value !== 'none').length === 0
        ? renderStateSponge.reports[currentTab]
        : renderStateSponge.filtered[currentTab]?.reports;
    if (whichReports) {
      return;
    }
    updateReportState();
  }, [isLoading, updateReportState, renderStateSponge, currentTab, filters]);
  const staleReports =
    Object.entries(filters).filter(([, value]) => value && value !== 'none').length === 0
      ? renderStateSponge.reports[currentTab]
      : renderStateSponge.filtered[currentTab]?.reports;
  const filteredReports = staleReports || [];

  return (
    <>
      <BreadcrumbBroadcaster pushCrumb="Health Reports" />
      <Page
        title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Health Reports'}
        bodyComponent={<Paper square />}
      >
        <FilterPanel
          onChangeTab={onChangeTab}
          onStartNew={() => setDialogOpen(true)}
          currentTab={currentTab}
        />
        {currentTab !== 'historical' && (
          <FilterOptionsPanel
            onChangeFilter={(updatedFilter: any) => {
              setFilters({ ...filters, ...updatedFilter });
            }}
            yearOptions={years}
          />
        )}
        {isLoading && !staleReports && (
          <div>
            <CircularProgress style={{ marginLeft: '35vw', marginTop: '30vh', height: '5%', width: '5%' }} />
          </div>
        )}
        {isLoading && staleReports && staleReports.length > 0 && (
          <UpdatingWrapper>
            <CircularProgress style={{ marginLeft: '5px', marginTop: '5px', height: '25px', width: '25px' }} />
            <UpdatingText>Updating...</UpdatingText>
          </UpdatingWrapper>
        )}
        {((!isLoading && staleReports) || (isLoading && staleReports && staleReports.length > 0)) && (
          <Downloader>
            {currentTab === 'in-progress' && (
              <ReportList
                reports={filteredReports}
                baseUrl={url}
                onCloseReport={confirmCloseReport}
              />
            )}

            {currentTab === 'archived' && (
              <ReportList
                reports={filteredReports}
                baseUrl={url}
              />
            )}

            {currentTab === 'historical' && (
              <HistoricalReportsProvider filters={historicalFilters}>
                <HistoricalReportList
                  filters={historicalFilters}
                  setFilters={setHistoricalFilter}
                />
              </HistoricalReportsProvider>
            )}
          </Downloader>
        )}
        {dialogOpen && (
          <StartReportDialog
            open={true}
            onStartReport={handleStartReport}
            onCancel={() => setDialogOpen(false)}
          />
        )}
        {closingReport && (
          <ReportStateChangeCommentDialog
            title="Close Report"
            commentRequired={true}
            message="WARNING: You are about to close this report. This change is irreversible."
            onCancel={cancelCloseReport}
            onSave={closeReport}
          />
        )}
      </Page>
    </>
  );
};

export default LandingPage;
