import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import Api from '../../../api';
import { Control, FieldErrors, UseFormSetValue, useForm } from 'react-hook-form';
import { TurnoverItem, TurnoverItemInput } from '../../../types/cwt/turnover';
import { downloadBlob } from '../utils';
import { useUsers } from './useUsers';
import { format } from 'date-fns';
import { WorkOrder } from '../../../types/cwt/workorder';
import { useError } from '../components/ErrorContextProvider';
interface useTurnoverValue {
  turnover: TurnoverItem[];
  loading: boolean;
  //turnover form
  control: Control<TurnoverItemInput>;
  errors: FieldErrors<TurnoverItemInput>;
  reset: () => void;
  setValue: UseFormSetValue<TurnoverItemInput>;
  handleAddEditTurnover: () => Promise<void>;
  openAddEditTurnoverDialog: boolean;
  handleOpenAddTurnoverDialog: () => void;
  handleOpenEditTurnoverDialog: (id: number) => void;
  handleCloseAddEditTurnoverDialog: () => void;
  //filters
  filters: TurnoverFilter;
  setFilters: (filters: TurnoverFilter) => void;
  //export
  gridRef: any;
  setGridRef: (gridRef: any) => void;
  exportCSV: () => void;
}

interface Props {
  children: ReactNode;
  allTurnovers?: boolean;
  workorder?: WorkOrder;
}
interface TurnoverFilter {
  wonum: string[];
  unit: string[];
  wopriority: string[];
  worktype: string[];
  crewworkgroup: string[];
}

const TurnoverContext = createContext<useTurnoverValue | undefined>(undefined);

export const TurnoverContextProvider = ({ children, allTurnovers, workorder }: Props) => {
  const [turnover, setTurnover] = useState<TurnoverItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [openAddEditTurnoverDialog, setOpenAddEditTurnoverDialog] = useState<boolean>(false);
  const { handleError } = useError();
  const defaultLeadCrew = workorder?.crewworkgroup;
  const { getUser } = useUsers();
  const [filters, setFilters] = useState<TurnoverFilter>({
    wonum: [],
    unit: [],
    wopriority: [],
    worktype: [],
    crewworkgroup: [],
  });
  const {
    control,
    reset,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<TurnoverItemInput>();
  const handleOpenAddTurnoverDialog = () => {
    setValue('turnoverDate', new Date());
    setValue('crewId', defaultLeadCrew || '');
    setOpenAddEditTurnoverDialog(true);
  };
  const handleOpenEditTurnoverDialog = (id: number) => {
    const item = turnover.find(item => item.id === id);
    if (item) {
      setValue('id', item.id);
      setValue('turnoverDate', new Date(item.turnoverDate));
      setValue('crewId', item.crewId);
      setValue('turnoverNotes', item.turnoverNotes);
      setOpenAddEditTurnoverDialog(true);
    }
  };
  const handleCloseAddEditTurnoverDialog = () => {
    setOpenAddEditTurnoverDialog(false);
    reset();
  };

  useEffect(() => {
    const getTurnover = async () => {
      try {
        const turnover = await Api.cwt.getTurnoverLogs(allTurnovers ? undefined : workorder?.id);
        setTurnover(turnover);
      } catch (error) {
        handleError(error as Error);
      }
      setLoading(false);
    };

    void getTurnover();
  }, [loading]);

  const onAddEditTurnover = async (data: TurnoverItemInput) => {
    if (workorder) {
      try {
        try {
          data.id
            ? await Api.cwt.updateTurnoverLog({ ...data, workorderId: workorder.id })
            : await Api.cwt.createTurnoverLog({ ...data, workorderId: workorder.id });
        } catch (error) {
          handleError(error as Error);
        }
        setLoading(true);
        handleCloseAddEditTurnoverDialog();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const [gridRef, setGridRef] = useState<any>(null);

  const exportCSV = async () => {
    const data = await Api.cwt.getTurnoverLogs(workorder?.id);
    const header = gridRef.current.visibleColumns.map((column: any) => column.header).join(',');

    const rows = data.map((row: any) => {
      return gridRef.current.visibleColumns
        .map((column: any) => {
          if (column.name === 'turnoverDate') {
            return `"${format(new Date(row[column.id]), 'ddMMMyyyy').toUpperCase()}"`;
          } else if (column.name === 'crewId') {
            return `"${row[column.id].toUpperCase()}"`;
          } else if (column.name === 'updatedBy') {
            return `"${getUser(row[column.id])?.name}"`;
          } else if (column.name === 'updatedAt') {
            return `"${format(new Date(row[column.id]), 'ddMMMyyyy HH:mm').toUpperCase()}"`;
          }
          const value = row[column.id];
          if (typeof value === 'string') {
            return `"${value.replace(/"/g, '""')}"`;
          }
          return value;
        })
        .join(',');
    });

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
    downloadBlob(blob, `turnover-${new Date().toISOString()}`);
  };
  return (
    <TurnoverContext.Provider
      value={{
        turnover,
        loading,
        control,
        errors,
        reset,
        setValue,
        openAddEditTurnoverDialog,
        handleOpenAddTurnoverDialog,
        handleOpenEditTurnoverDialog,
        handleCloseAddEditTurnoverDialog,
        handleAddEditTurnover: handleSubmit(onAddEditTurnover),
        filters,
        setFilters,
        gridRef,
        setGridRef,
        exportCSV,
      }}
    >
      {children}
    </TurnoverContext.Provider>
  );
};

export const useTurnover = () => {
  const context = useContext(TurnoverContext);
  if (context === undefined) {
    throw new Error('useTurnover must be used within a TurnoverContextProvider');
  }
  return context;
};
