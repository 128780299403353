import { useState, useCallback } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import UnstyledCollapse from '@material-ui/core/Collapse';
import UnstyledControlButton, { type Props as ControlButtonProps } from '../../../../../../components/ControlButton';
import { type TaskDataRow } from '../../../../../../context/work-request';
import { ReactComponent as navPolygonSvg } from '../../../../../../assets/chevron-right.svg';

const Collapse = styled(UnstyledCollapse)`
  background-color: ${({ theme }) => theme.colours.teals.tealLightBg};
`;
const GridWorkLogWrapper = styled(Grid)`
  padding: 21px 23px 13px 23px;
  border-bottom: 1px solid ${({ theme }) => theme.colours.greys.greyE1};
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  > * {
    flex: 1 1 50%;
  }
`;
const GridWorkLogItem = styled(Grid)`
  display: flex;
  gap: 10px;
  flex-direction: row;
`;
const GridWorkLogDescription = styled(GridWorkLogItem)`
  margin-bottom: 18px;
`;
const GridWorkLogType = styled(GridWorkLogItem)`
  margin-bottom: 18px;
`;
const GridWorkLogDetail = styled(GridWorkLogItem)`
  flex-grow: 2;
  flex-shrink: 2;
`;
const GridDetailsWrapper = styled(Grid)`
  padding: 13px 14px 18px 14px;
`;
const GridDetailsItem = styled(Grid)`
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  gap 10px;
  box-sizing: border-box;
`;
const GridDetailTicketNoAndTitle = styled(GridDetailsItem)`
  padding-left: 36px;
  flex: 1 1 18.67%;
`;
const GridDetailStatus = styled(GridDetailsItem)`
  flex: 1 1 88px;
`;
const GridDetailsWorkGroup = styled(GridDetailsItem)`
  flex: 1 1 18%;
`;
const GridDetailConditionAsFound = styled(GridDetailsItem)`
  flex: 1 1 auto;
`;
const ControlButton = styled(UnstyledControlButton)`
  border-radius: 0;
  border: none;
  min-width: auto;
  max-width: auto;
  width: 100%;
  padding: 0px;
  margin: 0px;
  display: block;
`;
const SvgWrapper = styled.div`
  display: inline-block;
  padding: 0px 9px;
`;

const SvgEmbed = styled(navPolygonSvg)`
  & > g > g {
    stroke: ${({ theme }) => theme.colours.greys.grey05};
  }
  &.is-open {
    transform: rotate(90deg);
  }
`;
const BaseTypography = styled(Typography)`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  text-align: left;
  &.bold {
    font-weight: 500;
  }
`;
const TypographyWorkOrderTaskLightText = styled(BaseTypography)`
  color: ${({ theme }) => theme.colours.greys.darkGrey5};
  font-size: 14px;
  &.details-heading {
    min-width: 75px;
  }
`;
const TypographyWorkOrderTaskText = styled(BaseTypography)`
  color: ${({ theme }) => theme.colours.black};
`;
const BubbleText = styled(Typography)`
  display: inline-flex;
  width: 20px;
  height: 20px;
  padding: 3px 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  flex-shrink: 0;
  aspect-ratio: 1/1;
  border-radius: 100%;
  /* border to hide dom-shifting */
  border: 1px solid ${({ theme }) => theme.colours.teals.teal1};
  background-color: ${({ theme }) => theme.colours.teals.teal1};
  color: ${({ theme }) => theme.colours.white};
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: center;

  &.opened-tasks {
    color: ${({ theme }) => theme.colours.teals.teal1};
    border-color: ${({ theme }) => theme.colours.teals.teal1};
    background-color: transparent;
  }
  &.empty-tasks {
    color: ${({ theme }) => theme.colours.notClickableGrey};
    background-color: ${({ theme }) => theme.colours.disabledGrey};
    border-color: ${({ theme }) => theme.colours.disabledGrey};
  }
`;
type TaskRowProps = {
  task: TaskDataRow;
  isOpen?: boolean;
  onToggleOpen?: ControlButtonProps['onClick'];
};
const TaskRow = ({ task, isOpen, onToggleOpen }: TaskRowProps) => {
  const [isOpenState, setIsOpenState] = useState(false);
  const onClick = useCallback(() => {
    if (typeof onToggleOpen === 'function') {
      onToggleOpen();
      return;
    }
    if (!(task.workLogs && task.workLogs.length > 0)) {
      return;
    }
    setIsOpenState((prev: boolean) => !prev);
  }, [setIsOpenState, onToggleOpen]);

  return (
    <>
      <ControlButton
        onClick={onClick}
        data-testid={`button-workorder-task-${task.taskNumber}`}
      >
        <GridDetailsWrapper
          container
          direction="row"
          spacing={0}
        >
          <Grid
            item
            xs
          >
            <SvgWrapper>
              <SvgEmbed className={isOpenState || isOpen ? 'is-open' : ''} />
            </SvgWrapper>

            {task.workLogs && task.workLogs.length > 0 ? (
              <BubbleText className={isOpenState || isOpen ? 'opened-tasks' : ''}>{task.workLogs.length}</BubbleText>
            ) : (
              <BubbleText className="empty-tasks">0</BubbleText>
            )}
          </Grid>
          <GridDetailTicketNoAndTitle
            item
            xs
          >
            <TypographyWorkOrderTaskLightText>{task.taskNumber}</TypographyWorkOrderTaskLightText>
            <TypographyWorkOrderTaskText className="bold">{task.title}</TypographyWorkOrderTaskText>
          </GridDetailTicketNoAndTitle>
          <GridDetailStatus
            item
            xs
          >
            <TypographyWorkOrderTaskLightText>Status</TypographyWorkOrderTaskLightText>
            <TypographyWorkOrderTaskText className="bold">{task.status || 'N/A'}</TypographyWorkOrderTaskText>
          </GridDetailStatus>
          <GridDetailsWorkGroup
            item
            xs
          >
            <TypographyWorkOrderTaskLightText>Crew work group</TypographyWorkOrderTaskLightText>
            <TypographyWorkOrderTaskText className="bold">{task.crewworkgroup || 'N/A'}</TypographyWorkOrderTaskText>
          </GridDetailsWorkGroup>
          <GridDetailConditionAsFound
            item
            xs
          >
            <TypographyWorkOrderTaskLightText>As found condition</TypographyWorkOrderTaskLightText>
            <TypographyWorkOrderTaskText className="bold">
              {task.asFoundConditionCode || 'N/A'}
            </TypographyWorkOrderTaskText>
          </GridDetailConditionAsFound>
        </GridDetailsWrapper>
      </ControlButton>
      {task.workLogs && task.workLogs.length > 0 ? (
        <Collapse
          in={isOpenState || isOpen}
          unmountOnExit
        >
          {task.workLogs.map(({ worklogType, worklogDescription, worklogDetails }) => (
            <GridWorkLogWrapper
              container
              direction="row"
              spacing={0}
            >
              <GridWorkLogDescription
                item
                xs
              >
                <TypographyWorkOrderTaskLightText>Description</TypographyWorkOrderTaskLightText>
                {worklogDescription}
              </GridWorkLogDescription>
              <GridWorkLogType
                item
                xs
              >
                <TypographyWorkOrderTaskLightText>Type</TypographyWorkOrderTaskLightText>
                {worklogType}
              </GridWorkLogType>
              <GridWorkLogDetail
                item
                xs
              >
                <TypographyWorkOrderTaskLightText className="details-heading">Details</TypographyWorkOrderTaskLightText>
                {worklogDetails ? (
                  <TypographyWorkOrderTaskText
                    className="bold"
                    dangerouslySetInnerHTML={{
                      __html: worklogDetails,
                    }}
                  />
                ) : null}
              </GridWorkLogDetail>
            </GridWorkLogWrapper>
          ))}
        </Collapse>
      ) : null}
    </>
  );
};
type WorkOrderTaskTableRefreshUiProps = { tasks: TaskDataRow[] };
const WorkOrderTaskTableRefreshUi = ({ tasks }: WorkOrderTaskTableRefreshUiProps) => {
  const rows = tasks.map(task => (
    <TaskRow
      key={task.taskNumber}
      task={task}
    />
  ));

  return <div>{rows}</div>;
};

export default WorkOrderTaskTableRefreshUi;
