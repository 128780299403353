import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { type ElementId } from '../types/app';
import { type MyElement } from '../types/my';

export type WorkLogDetails = {
  worklogType: string;
  worklogDescription: string;
  worklogDetails: string;
};
export type TaskDataRow = {
  asFoundConditionCode: string;
  class: 'ACTIVITY' | 'CHANGE' | 'PERMIT' | 'WORKORDER';
  elementCategory?: string;
  elementId?: MyElement['elementId'];
  elementName?: MyElement['elementName'];
  elementType?: string;
  elementUnit?: string;
  equipmentTag?: string;
  facilityId: 'ba' | 'bb' | 'cs' | 'all' | 'none';
  facilityName: 'BA' | 'BB' | 'CS' | 'All' | 'None';
  instructions?: string;
  location?: string;
  priority: 1 | 2 | 3 | 4;
  reportedQuarter: 1 | 2 | 3 | 4;
  reportedYear?: string | number;
  taskNumber?: string;
  title?: string;
  unitNumber?: string | number;
  usi?: string;
  workOrderNumber: string;
  workLogs?: WorkLogDetails[];
  status: 'CAN' | 'CANCLOSE' | 'CLOSE' | 'CLOSEOUT' | 'COMP' | 'FIELDCOMP' | 'WCANCEL' | 'WINFO';
  // note these?
  crewworkgroup: string | 'MMBC';
};
export type WorkRequest = {
  workRequestNumber: string;
  reportDate: Date;
  title?: string;
  status: TaskDataRow['status'];
  statusDate: Date;
  details?: string;
  facilityId: TaskDataRow['facilityId'];
  ticketuid: number | string;
  facilityName: TaskDataRow['facilityName'];
  unitNumber?: string;
  usi: string;
  equipmentTag?: string;
  location?: string;
  elementId: TaskDataRow['elementId'];
  elementName: TaskDataRow['elementName'];
  elementType?: TaskDataRow['elementType'];
  elementCategory?: TaskDataRow['elementCategory'];
  elementUnit?: TaskDataRow['elementUnit'];
  reportedQuarter: TaskDataRow['reportedQuarter'];
  reportedYear: number;
  critCat?: string;
  description?: string;
  ownergroup?: string;
  plusoutagecode?: string;
  targetfinish?: string;
  actualfinish?: string;
  maximoResult: MaximoResult;
};
export type MaximoResult = {
  ticketid: string;
  description: string;
  status: string;
  location: string;
  class_description: string;
  bpcategory: string;
  bpfh: string;
  bpmodel: string;
  bpsafetysystem: string;
  bppressureboundary: string;
  bpunitoutage: string;
  plusoutagecode: string;
  ownergroup: string;
  targetfinish: string;
  actualfinish: string;
  reportedby: string;
  siteid: string;
  statusdate: string;
  reportdate: string;
  bpunit: string;
};
type GetWorkRequestParams = any;
type GetWorkOrderParams = any;
type GetWorkOrderTasksParams = any;
type GetAllRelatedWorkParams = any;
type SetRequestOptions<T> = {
  promise: Promise<T | null>;
  params: GetWorkRequestParams | GetWorkOrderParams | GetWorkOrderTasksParams | GetAllRelatedWorkParams;
};
type LoadingStatus<T> = { isLoading: boolean; isError: boolean; promise: null | Promise<T | null> };
type ApiRequests = {
  workRequest: LoadingStatus<any> & GetWorkRequestParams;
  workOrder: LoadingStatus<any> & GetWorkOrderParams;
  workOrderTasks: LoadingStatus<TaskDataRow[]> & GetWorkOrderTasksParams;
  allRelatedWork: LoadingStatus<any> & GetAllRelatedWorkParams;
};
export type ContextType = {
  requests: ApiRequests;
  isLoading: () => boolean;
  isError: () => boolean;
  getWorkRequest: (params: GetWorkRequestParams) => Promise<null>;
  getWorkOrder: (params: GetWorkOrderParams) => Promise<null>;
  getWorkOrderTasks: (params: GetWorkOrderTasksParams) => Promise<null | TaskDataRow[]>;
  getAllRelatedWork: (params: GetAllRelatedWorkParams) => Promise<null>;
};
export const defaultContext = {
  requests: {
    workRequest: { promise: null, isLoading: false, isError: false },
    workOrder: { promise: null, isLoading: false, isError: false },
    workOrderTasks: { promise: null, isLoading: false, isError: false },
    allRelatedWork: { promise: null, isLoading: false, isError: false },
  },
  isLoading: () => false,
  isError: () => false,
  getWorkRequest: () => Promise.resolve(null),
  getWorkOrder: () => Promise.resolve(null),
  getWorkOrderTasks: () => Promise.resolve(null),
  getAllRelatedWork: () => Promise.resolve(null),
};
export const WorkRequestContext = createContext<ContextType>(defaultContext);
type ProviderProps = {
  elementId: ElementId;
  indicatorId: string;
  recordId: string;
};

const WorkRequestProvider = ({
  children,
  elementId,
  indicatorId,
  recordId,
}: React.PropsWithChildren & ProviderProps) => {
  const [requests, setRequests] = useState<ApiRequests>({
    workRequest: { promise: null, isLoading: false, isError: false },
    workOrder: { promise: null, isLoading: false, isError: false },
    workOrderTasks: { promise: null, isLoading: false, isError: false },
    allRelatedWork: { promise: null, isLoading: false, isError: false },
  });
  const setRequest = useCallback(
    <T,>(requestKey: keyof ApiRequests, options: SetRequestOptions<T>) => {
      console.log('---- setRequest() requestKey: ', requestKey, ' options: ', options);
      const { promise, params } = options;
      const wrappedPromise = new Promise<T | null>((resolve, reject) =>
        promise
          .then(r => {
            setRequests((prev: any) => ({
              ...prev,
              [requestKey]: { ...prev[requestKey], isLoading: false, isError: false },
            }));
            resolve(r as T | null);
          })
          .catch(e => {
            setRequests((prev: any) => ({
              ...prev,
              [requestKey]: { ...prev[requestKey], isLoading: false, isError: true },
            }));
            reject(e);
          })
      );
      setRequests((prev: any) => ({
        ...prev,
        [requestKey]: { promise: wrappedPromise, params, isLoading: true, isError: false },
      }));
      return { promise: wrappedPromise };
    },
    [setRequests]
  );
  const getWorkRequest = useCallback(
    (params: GetWorkRequestParams) => {
      console.log('---- getWorkRequest() params: ', params);
      const promise = Promise.resolve(null);
      return setRequest<any>('workRequest', { params, promise }).promise;
    },
    [setRequest]
  );
  const getWorkOrder = useCallback(
    (params: GetWorkOrderParams) => {
      console.log('---- getWorkOrder() params: ', params);
      const promise = Promise.resolve(null);
      return setRequest<any>('workOrder', { params, promise }).promise;
    },
    [setRequest]
  );
  const getWorkOrderTasks = useCallback(
    (params: GetWorkOrderTasksParams) => {
      console.log('---- getWorkOrderTasks() params: ', params);
      const promise = Promise.resolve(null);
      return setRequest<TaskDataRow[]>('workOrderTasks', { params, promise }).promise;
    },
    [setRequest]
  );
  const getAllRelatedWork = useCallback(
    (params: GetAllRelatedWorkParams) => {
      console.log('---- getAllRelatedWork() params: ', params);
      const promise = Promise.resolve(null);
      return setRequest<any>('allRelatedWork', { params, promise }).promise;
    },
    [setRequest]
  );
  const isLoading = useCallback(() => {
    console.log('---- isError() : ', requests);
    return false;
  }, [requests]);
  const isError = useCallback(() => {
    console.log('---- isError() : ', requests);
    return false;
  }, [requests]);
  useEffect(() => {
    console.log('--useEffect', { elementId, indicatorId, recordId });
  }, [elementId, indicatorId, recordId]);

  const contextValue = {
    requests,
    isLoading,
    isError,
    getWorkRequest,
    getWorkOrder,
    getWorkOrderTasks,
    getAllRelatedWork,
  };
  return <WorkRequestContext.Provider value={contextValue}>{children}</WorkRequestContext.Provider>;
};

export const useWorkRequestContext = () => {
  const context = useContext(WorkRequestContext);
  if (!context) {
    throw new Error('useWorkRequestContext must be used inside an WorkRequestContext');
  }
  return context;
};
export default WorkRequestProvider;
