import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IndicatorProvider } from '../../../../hooks/indicator';
import useIndicatorRecords from '../../../../hooks/indicator-records';
import useElementUnitRecords from './hooks/element-unit-records';
import UnstyledFilterPanel from '../../tabs/review-records/components/FilterPanel';
import ContentPanel from '../../tabs/review-records/components/ContentPanel';
import { ElementId } from '../../../../types/app';
import WorkRequestProvider from '../../../../context/work-request';

const FilterPanel = styled(UnstyledFilterPanel)``;

interface Props {
  elementId: ElementId;
  indicatorId: string;
  recordId: string;
  onChangeIndicatorId: (newIndicatorId: string | null) => void;
  onChangeRecordId: (newRecordId: string) => void;
}

const RecordDetailsLayout: React.FC<Props> = ({
  elementId,
  indicatorId,
  recordId,
  onChangeIndicatorId,
  onChangeRecordId,
}) => {
  const [elementUnit, setElementUnit] = useState('');
  const { records, isLoading } = useIndicatorRecords(elementId, indicatorId);
  const filteredRecords = useElementUnitRecords(records, elementUnit);

  useEffect(() => {
    // When selected element is changed, reset unit, indicator, and record selections
    setElementUnit('');
    onChangeIndicatorId('');
    onChangeRecordId('');
  }, [elementId]);

  return (
    <>
      <FilterPanel
        elementId={elementId}
        unit={elementUnit}
        indicatorId={indicatorId}
        onChangeUnit={setElementUnit}
        onChangeIndicator={onChangeIndicatorId}
      />
      <WorkRequestProvider
        indicatorId={indicatorId}
        elementId={elementId}
        recordId={recordId}
      >
        <IndicatorProvider indicatorId={indicatorId}>
          <ContentPanel
            records={filteredRecords}
            indicatorId={indicatorId}
            elementId={elementId}
            recordId={recordId}
            isLoading={isLoading}
            onChangeRecord={onChangeRecordId}
          />
        </IndicatorProvider>
      </WorkRequestProvider>
    </>
  );
};

export default RecordDetailsLayout;
