import { useActions } from '../../hooks/useActions';
import ActionsTable from './ActionsTable';
import '@inovua/reactdatagrid-community/index.css';
import styled from 'styled-components';
import ActionButton from '../../../../components/ActionButton';
import Page from '../../../../components/Page';
import { Box, Grid, Tooltip, Typography, FormControlLabel, Switch, IconButton } from '@material-ui/core';
import UnstyledFormDialog from '../../../../components/FormDialog';
import AddActionForm from './AddActionForm';
import { WODetailsModalContextProvider } from '../../hooks/useWODetailsModal';
import WODetailsModal from '../../components/WODetailsModal';
import { useWorkOrders } from '../../hooks/useWorkOrders';
import {
  getPriorityOptions,
  getUnitOptions,
  getWorkTypeOptions,
  getWorkorderOptions,
  getWorkGroupOptions,
  getOnlineStartOptions,
  T_PLUS_ONE_ROW_COLOR,
  getSpecificationsOptions,
  getPlanningCtrOptions,
  getLocationOptions,
  getScheduleBacklogOptions,
} from '../../wo/components/utils';
import { useUsers } from '../../hooks/useUsers';
import DownloadButton from '../../../../components/DownloadButton';
import { useMemo, useState } from 'react';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { BookmarkMenu } from '../../bookmarks/BookmarkMenu';
import { FilterEntityType } from '../../bookmarks/hook/useBookmarks';
import isFeatureEnabled from '../../../../utils/feature-flags';
import AutocompleteFilterInput from '../../components/AutocompleteFilterInput';
import { useTPlusOne } from '../../hooks/useTPlusOne';

const AddActionButton = styled(ActionButton)`
  margin-bottom: 10px;
`;

const FormDialog = styled(UnstyledFormDialog)`
  .MuiDialogContent-root {
    max-height: 600px;
    max-width: 500px;
    min-width: 500px;
  }
`;

const WaitSwitch = styled(Switch)`
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: red;
  }
  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: rgba(0, 255, 0, 0.04);
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: red;
  }
`;

const TWaitSwitch = styled(Switch)`
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${T_PLUS_ONE_ROW_COLOR};
  }
  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: rgba(0, 255, 0, 0.04);
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: ${T_PLUS_ONE_ROW_COLOR};
  }
`;

const RefreshBacklogIcon = styled(AutorenewIcon)`
  cursor: pointer;
  padding-top: 0;
  color: ${({ theme }) => theme.colours.teals.teal1};
  width: 25px;
  height: 25px;
`;

const ActionsHeader = () => {
  const {
    groups,
    statuses,
    flags,
    loading,
    setOpenAddEditActionDialog,
    reset,
    openAddEditActionDialog,
    handleSubmit,
    filters,
    setFilters,
    fetchActions,
    handleOpenAddActionDialog,
    exportCSV,
    filteredActionsWithWorkorder,
  } = useActions();

  const { workorders: workordersWO } = useWorkOrders();
  const { workorders: workordersT } = useTPlusOne();

  const [selectedWorkorderId, setSelectedWorkorderId] = useState<number | undefined>(0);
  const [isTplusOne, setIsTPlusOne] = useState<boolean | undefined>(false);

  const workorders = useMemo(() => new Map([...workordersWO, ...workordersT]), [workordersWO, workordersT]);

  const getWorkorderById = (id: number | undefined) => {
    if (!id) return undefined;
    return workordersWO.get(id) ?? workordersT.get(id);
  };

  const cancelAddAction = () => {
    setOpenAddEditActionDialog(false);
    reset();
  };
  const { users } = useUsers();

  return (
    <Page title="Actions">
      <Grid
        container
        style={{ marginLeft: '10px', paddingRight: '10px' }}
      >
        <Grid
          item
          xs={3}
        >
          <Box
            height="100px"
            minWidth={'120px'}
            borderRadius="5px"
            textAlign="center"
            boxShadow="rgb(191, 190, 190) 0px 2px 7px 0px;"
            marginRight="10px"
            marginTop="10px"
          >
            <Typography style={{ fontWeight: '500', fontSize: '45px' }}>
              {loading ? '...' : filteredActionsWithWorkorder.length}
            </Typography>
            <Typography style={{ fontSize: '12px' }}>Current Actions</Typography>
          </Box>
        </Grid>
        <Grid
          container
          spacing={2}
          item
          xs={9}
        >
          <Grid
            item
            xs={4}
          >
            <AutocompleteFilterInput
              label="Work Order"
              options={getWorkorderOptions(workorders)}
              onChange={value => {
                setFilters({ ...filters, wonum: value });
              }}
              value={filters.wonum}
              multiple
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <AutocompleteFilterInput
              label="Unit"
              options={getUnitOptions(workorders)}
              onChange={value => {
                setFilters({ ...filters, unit: value });
              }}
              value={filters.unit}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <AutocompleteFilterInput
              label="Priority"
              options={getPriorityOptions(workorders)}
              onChange={value => {
                setFilters({ ...filters, wopriority: value });
              }}
              value={filters.wopriority}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <AutocompleteFilterInput
              label="Work Type"
              options={getWorkTypeOptions(workorders)}
              onChange={value => {
                setFilters({ ...filters, worktype: value });
              }}
              value={filters.worktype}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>

          <Grid
            item
            xs={4}
          >
            <AutocompleteFilterInput
              label="Lead Crew"
              options={getWorkGroupOptions(workorders)}
              onChange={value => {
                setFilters({ ...filters, crewworkgroup: value });
              }}
              value={filters.crewworkgroup}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <AutocompleteFilterInput
              label="Planning Center"
              options={useMemo(() => getPlanningCtrOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, bpplanningctr: value });
              }}
              value={filters.bpplanningctr}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          xs={12}
        >
          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label="Schedule Backlog"
              options={useMemo(() => getScheduleBacklogOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, bpschedulebacklog: value });
              }}
              value={filters.bpschedulebacklog}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>

          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label="Online Start"
              options={useMemo(
                () => getOnlineStartOptions(filteredActionsWithWorkorder),
                [filteredActionsWithWorkorder]
              )}
              onChange={value => {
                setFilters({ ...filters, schedstart: value });
              }}
              value={filters.schedstart}
              multiple
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label="Location"
              options={useMemo(
                () => getLocationOptions(workorders).map(option => ({ ...option, label: option.label || '' })),
                [workorders]
              )}
              onChange={value => {
                setFilters({ ...filters, location: value });
              }}
              value={filters.location}
              multiple
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label="Action Status"
              options={statuses.map(status => ({ value: status.id?.toString(), label: status.label }))}
              onChange={value => {
                setFilters({ ...filters, statusId: value });
              }}
              value={filters.statusId}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label="WO Specifications"
              options={useMemo(() => getSpecificationsOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, woSpecifications: value });
              }}
              value={filters.woSpecifications}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label="Action Flags"
              options={flags.map(flag => ({ value: flag.id?.toString(), label: flag.label }))}
              onChange={value => {
                setFilters({ ...filters, flags: value });
              }}
              value={filters.flags}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label="Action Group"
              options={groups.map(group => ({ value: group.id?.toString(), label: group.label }))}
              onChange={value => {
                setFilters({ ...filters, groupId: value });
              }}
              value={filters.groupId}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label="Action Owner"
              options={users
                .map(user => ({ value: user.id?.toString(), label: user.name }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              onChange={value => {
                setFilters({ ...filters, owner: value });
              }}
              value={filters.owner}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={9}
        >
          <Box
            display="flex"
            alignItems="center"
          >
            <FormControlLabel
              control={
                <WaitSwitch
                  checked={filters.highPriority}
                  onChange={e => {
                    setFilters({
                      ...filters,
                      highPriority: e.target.checked,
                    });
                  }}
                  name="highPriority"
                />
              }
              label="High Priority"
            />

            {isFeatureEnabled('cwt_tplus1') && (
              <>
                <FormControlLabel
                  control={
                    <TWaitSwitch
                      checked={filters.hideTPlusOne}
                      onChange={e => {
                        setFilters({
                          ...filters,
                          hideTPlusOne: e.target.checked,
                          tPlusOne: e.target.checked ? false : filters.tPlusOne,
                        });
                      }}
                      name="hidetplusone"
                    />
                  }
                  label="Hide T+1"
                />

                <FormControlLabel
                  control={
                    <TWaitSwitch
                      checked={filters.tPlusOne}
                      onChange={e => {
                        setFilters({
                          ...filters,
                          tPlusOne: e.target.checked,
                          hideTPlusOne: e.target.checked ? false : filters.hideTPlusOne,
                        });
                      }}
                      name="tplusoneonly"
                    />
                  }
                  label={
                    <Box
                      display="flex"
                      alignItems="center"
                    >
                      <Box
                        width="14px"
                        height="14px"
                        border={0.1}
                        bgcolor={T_PLUS_ONE_ROW_COLOR} // Solid color box
                        style={{ marginRight: 10 }}
                      />
                      T+1 Only
                    </Box>
                  }
                />
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <Box
        display="flex"
        justifyContent={'space-between'}
        alignContent={'center'}
        marginBottom="10px"
        marginTop="10px"
        marginLeft="10px"
      >
        {isFeatureEnabled('cwtBookmarks') && (
          <BookmarkMenu
            type={FilterEntityType.ActionFilter}
            filter={filters}
            setFilters={setFilters}
          />
        )}
        <Box>
          <Tooltip
            title="Refresh Backlog"
            placement="right"
          >
            <IconButton onClick={fetchActions}>
              <RefreshBacklogIcon />
            </IconButton>
          </Tooltip>
          <AddActionButton
            color="primary"
            variant="text"
            onClick={() => handleOpenAddActionDialog()}
          >
            Add Action
          </AddActionButton>

          <Tooltip
            title="Export CSV"
            placement="top"
          >
            <DownloadButton onClick={exportCSV}></DownloadButton>
          </Tooltip>
        </Box>
      </Box>

      {openAddEditActionDialog && (
        <FormDialog
          title="Add Action"
          onSave={handleSubmit}
          onCancel={cancelAddAction}
        >
          <AddActionForm />
        </FormDialog>
      )}
      <WODetailsModalContextProvider
        workorder={getWorkorderById(selectedWorkorderId)}
        tplus1={isTplusOne}
      >
        <WODetailsModal />
        <ActionsTable
          setSelectedWorkorderId={setSelectedWorkorderId}
          setIsTPlusOne={setIsTPlusOne}
        />
      </WODetailsModalContextProvider>
    </Page>
  );
};

export default ActionsHeader;
