import React, { useState } from 'react';
import styled from 'styled-components';

import UnstyledPaper from '@material-ui/core/Paper';
import FilterButtons from './components/FilterButtons';
import UnstyledDashboardTile from './components/DashboardTile';
import isFeatureEnabled from '../../utils/feature-flags';
import UnstyledPage from '../../components/Page';
import useMyDashboards from '../../hooks/my-dashboards';
import { BreadcrumbBroadcaster } from '../../context/breadcrumbs';

const DashboardTile = styled(UnstyledDashboardTile)``;

const Paper = styled(UnstyledPaper)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const Page = styled(UnstyledPage)`
  height: calc(100vh - 70px);
`;

const DashboardContainer = styled.div`
  display: flex;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 25px;
  padding-right: 25px;
  flex-wrap: wrap;

  ${DashboardTile} {
    margin: 15px 25px;
  }
`;

const DashboardsPage = () => {
  const [showFavourites, setShowFavourites] = useState(false);
  const { myDashboards, addFavourite, removeFavourite } = useMyDashboards();
  const [searchValue, setSearchValue] = useState('');

  const handleFavouriteStatusChange = id => () => {
    const currentDashboard = myDashboards.find(dashboard => dashboard.id === id);
    if (currentDashboard.favourite) {
      removeFavourite(id);
    } else {
      addFavourite(id);
    }
  };

  const SearchDashboards = myDashboards
    .filter(dashboard => {
      return (
        (!showFavourites || dashboard.favourite) && dashboard.title.toLowerCase().includes(searchValue.toLowerCase())
      );
    })
    .map(dashboard => (
      <DashboardTile
        key={dashboard.id}
        dashboard={dashboard}
        onToggleFavourite={handleFavouriteStatusChange(dashboard.id)}
      />
    ));

  return (
    <>
      <BreadcrumbBroadcaster pushCrumb="Dashboards" />
      <Page
        title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'PowerBI Reports'}
        bodyComponent={<Paper square />}
      >
        <FilterButtons
          showFavourites={showFavourites}
          onChange={setShowFavourites}
          onSearchChange={setSearchValue}
        />
        <DashboardContainer>{SearchDashboards}</DashboardContainer>
      </Page>
    </>
  );
};

export default DashboardsPage;
