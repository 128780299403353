import { useState, useCallback, useEffect } from 'react';
import * as R from 'ramda';
import useIndicatorQueryFields from './indicator-query-fields';
import { DefaultCrewWorkGroups } from '../components/RecordListPanel/filterConstants';
import isFeatureEnabled from '../../../../../utils/feature-flags';

const stateMatches = R.curry((applicableStates, record) => {
  if (R.isNil(record.applicable) && R.includes('not-evaluated', applicableStates)) {
    return true;
  }
  if (record.applicable && R.includes('applicable', applicableStates)) {
    return true;
  }
  if (!R.isNil(record.applicable) && !record.applicable && R.includes('inapplicable', applicableStates)) {
    return true;
  }
  return false;
});

const workCrewMatches = selectedWorkCrew => record => {
  if (R.isEmpty(record.tasks) || R.isNil(record.tasks)) {
    return true;
  }

  let isMatch = false;
  record.tasks.forEach(task => {
    if (selectedWorkCrew.includes(task.crewworkgroup)) {
      isMatch = true;
    }
  });
  return isMatch;
};

const textMatches = (indicatorQueryFields, query) => record =>
  R.reduce(
    (acc, fieldName) => acc || R.includes(query.toLowerCase(), R.propOr('', fieldName, record).toLowerCase()),
    false,
    indicatorQueryFields
  );

const useApplicableStates = records => {
  const [applicableStates, setApplicableStates] = useState([]);

  const setApplicableState = useCallback(
    (state, value) => {
      if (value) {
        setApplicableStates(R.append(state, applicableStates));
      } else {
        setApplicableStates(R.without([state], applicableStates));
      }
    },
    [applicableStates, setApplicableStates]
  );

  const filteredRecords = R.filter(
    R.isEmpty(applicableStates) ? R.always(true) : stateMatches(applicableStates),
    records
  );

  return {
    filteredRecords,
    applicableStates,
    setApplicableState,
  };
};

const useWorkCrewFilter = (indicatorId, records) => {
  const [selectedWorkCrew, setSelectedWorkCrews] = useState(
    isFeatureEnabled('crewWorkGroupFilter') ? DefaultCrewWorkGroups : []
  );

  useEffect(() => {
    setSelectedWorkCrews(isFeatureEnabled('crewWorkGroupFilter') ? DefaultCrewWorkGroups : []);
  }, [indicatorId]);

  const setSelectedWorkCrew = useCallback(
    (state, value) => {
      if (value) {
        setSelectedWorkCrews(R.append(state, selectedWorkCrew));
      } else {
        setSelectedWorkCrews(R.without([state], selectedWorkCrew));
      }
    },
    [selectedWorkCrew, setSelectedWorkCrews]
  );

  const filteredRecords = R.filter(
    R.isEmpty(selectedWorkCrew) ? R.always(true) : workCrewMatches(selectedWorkCrew),
    records
  );

  return {
    filteredRecords,
    selectedWorkCrew,
    setSelectedWorkCrew,
  };
};

const useTextFilter = (indicatorId, records) => {
  const [query, setQuery] = useState('');

  const indicatorQueryFields = useIndicatorQueryFields(indicatorId);

  const filteredRecords = R.filter(textMatches(indicatorQueryFields, query), records);

  return {
    filteredRecords,
    query,
    setQuery,
  };
};

const useFilteredRecords = (indicatorId, records) => {
  const { filteredRecords: applicableRecords, applicableStates, setApplicableState } = useApplicableStates(records);
  const {
    filteredRecords: workCrewMatches,
    selectedWorkCrew,
    setSelectedWorkCrew,
  } = useWorkCrewFilter(indicatorId, applicableRecords);
  const { filteredRecords, query, setQuery } = useTextFilter(indicatorId, workCrewMatches);

  return {
    filteredRecords,
    query,
    applicableStates,
    selectedWorkCrew,
    setQuery,
    setApplicableState,
    setSelectedWorkCrew,
  };
};

export default useFilteredRecords;
