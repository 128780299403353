import { Paper } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import NoRecords from '../../../../components/NoRecords';
import Page from '../../../../components/Page';
import useDeferralsRequests, { DeferralsRequestsProvider } from '../../../../hooks/deferral-requests';
import { MyRecentAssignmentsProvider } from '../../../../hooks/my-recent-assignments';
import RequestDeferralForm from './components/RequestDeferralForm';
import TopPanel from './components/TopPanel';
import isFeatureEnabled from '../../../../utils/feature-flags';
import { BreadcrumbBroadcaster } from '../../../../context/breadcrumbs';

const Container = styled.div`
  padding: 50px;
`;

const DeferralsRequestPage = () => {
  const { submitDeferralRequest, setSelectedElement, selectedElement } = useDeferralsRequests();

  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    setSubmitted(false);
  }, [selectedElement]);

  const passElement = useMemo(() => (selectedElement.length > 1 ? null : selectedElement[0]), [selectedElement]);

  return (
    <>
      <BreadcrumbBroadcaster pushCrumb="Request New" />
      <Page
        title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Deferral Request'}
        bodyComponent={<Paper square />}
      >
        <MyRecentAssignmentsProvider>
          <TopPanel selectedElement={passElement} />
          {selectedElement && !submitted ? (
            <Container>
              <RequestDeferralForm
                onSave={x => {
                  submitDeferralRequest(x);
                  setSubmitted(true);
                }}
                onSelectElement={setSelectedElement}
              />
            </Container>
          ) : (
            <NoRecords />
          )}
        </MyRecentAssignmentsProvider>
      </Page>
    </>
  );
};

export default () => (
  <DeferralsRequestsProvider>
    <DeferralsRequestPage />
  </DeferralsRequestsProvider>
);
