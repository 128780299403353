import { ReactNode, createContext, useContext, useEffect, useMemo, useState, MutableRefObject } from 'react';
import Api from '../../../api';
import { ChangeLog } from '../../../types/cwt/changelog';
import { useUsers } from './useUsers';
import { downloadBlob } from '../utils';
import { isSameDay } from 'date-fns';
import { TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import { useError } from '../components/ErrorContextProvider';

export interface useChangeTrackingValue {
  filteredLogs: ChangeLog[];
  logs: ChangeLog[];
  loading: boolean;
  setGridRef: (gridRef: MutableRefObject<TypeComputedProps | null> | null) => void;
  exportCSV: () => void;
  setFilters: (filters: LogsFilters) => void;
  filters: LogsFilters;
}

interface Props {
  children: ReactNode;
}
const NotesContext = createContext<useChangeTrackingValue | undefined>(undefined);

interface LogsFilters {
  wonum: string[];
  changedDate: string[];
  recordType: string[];
  oldValue: string[];
  newValue: string[];
  changedBy: string[];
  field: string[];
}

export const ChangeTrackingContextProvider = ({ children }: Props) => {
  const [logs, setLogs] = useState<ChangeLog[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<LogsFilters>({
    changedDate: [],
    wonum: [],
    recordType: [],
    oldValue: [],
    newValue: [],
    changedBy: [],
    field: [],
  });
  const { userSite } = useUsers();
  const { handleError } = useError();

  useMemo(() => {
    setLoading(true);
  }, [userSite]);

  useEffect(() => {
    const getLogs = async () => {
      try {
        const res = await Api.cwt.getAllChangeLogs();
        setLogs(res);
      } catch (error) {
        handleError(error as Error);
      }
      setLoading(false);
    };

    if (loading) {
      void getLogs();
    }
  }, [loading]);

  const [gridRef, setGridRef] = useState<MutableRefObject<TypeComputedProps | null> | null>(null);

  const exportCSV = () => {
    const header = gridRef?.current?.visibleColumns.map(column => column.header).join(',');

    const rows = filteredLogs.map(row => {
      return gridRef?.current?.visibleColumns
        .map(column => {
          const value = row[column.id as keyof ChangeLog];
          if (typeof value === 'string') {
            return `"${value.replace(/"/g, '""')}"`;
          }
          return value;
        })
        .join(',');
    });

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
    downloadBlob(blob, `changetracking-${new Date().toISOString()}`);
  };

  const filteredLogs = logs.filter(log => {
    return (
      (filters.changedDate.length === 0 ||
        filters.changedDate.some(filterDate => isSameDay(new Date(filterDate), new Date(log.changedDate)))) &&
      (filters.wonum.length === 0 || filters.wonum.includes(log.wonum)) &&
      (filters.recordType.length === 0 || filters.recordType.includes(log.recordType)) &&
      (filters.field.length === 0 || filters.field.includes(log.field)) &&
      (filters.oldValue.length === 0 || filters.oldValue.includes(log.oldValue ? log.oldValue : '')) &&
      (filters.newValue.length === 0 || filters.newValue.includes(log.newValue ? log.newValue : '')) &&
      (filters.changedBy.length === 0 || filters.changedBy.includes(log.changedBy))
    );
  });

  return (
    <NotesContext.Provider
      value={{
        filteredLogs,
        logs,
        loading,
        setGridRef,
        exportCSV,
        setFilters,
        filters,
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};

export const useChangeTracking = () => {
  const context = useContext(NotesContext);
  if (context === undefined) {
    throw new Error('useChangeTracking must be used within a ChangeTrackingContextProvider');
  }
  return context;
};
