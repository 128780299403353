import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import HealthTrendsPage from './pages/health-trends/HealthTrendsPage';
import ForecastPage from './pages/forecast/ForecastPage';
import { BreadcrumbBroadcaster } from '../../context/breadcrumbs';

const InsightsPage = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <BreadcrumbBroadcaster pushCrumb="Insights" />
      <Switch>
        <Route path={`${path}/health-trends`}>
          <HealthTrendsPage />
        </Route>
        <Route path={`${path}/forecast`}>
          <ForecastPage />
        </Route>
        <Redirect to={`${path}/health-trends`} />
      </Switch>
    </>
  );
};

export default InsightsPage;
