import React from 'react';
import styled from 'styled-components';
import { AllColourValuesProvider } from '../../../../hooks/all-colour-values';
import ChartContainer from './ChartContainer';
import { type MyElement } from '../../../../types/my';

const Container = styled.div`
  padding: 50px;
`;
type ChargePanelProps = {
  selectedAssignment?: Pick<MyElement, 'elementId' | 'elementName'> | null;
  className?: string;
};

const ChartPanel = ({ className, selectedAssignment }: ChargePanelProps) => (
  <Container className={className}>
    <AllColourValuesProvider>
      {selectedAssignment && <ChartContainer selectedAssignment={selectedAssignment} />}
    </AllColourValuesProvider>
  </Container>
);

export default ChartPanel;
