import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import useToggle from '../../../../../../hooks/toggle';
import ControlButton from '../../../../../../components/ControlButton';
import LongDescription from '../../../../../../components/LongDescription';
import CarlaText from '../../../../../../components/CarlaText';
import WorkOrderTaskTableCurrentUi from './WorkOrderTaskTable';
import WorkOrderTaskTableRefreshUi from './WorkOrderTaskTableRefreshUi';
import DetailHeader from './DetailHeader';
import TaskTitle from './TaskTitle';
import { WorkGridContainerDetail, WorkGridItemDetail } from './WorkGridItemDetail';
import isFeatureEnabled from '../../../../../../utils/feature-flags';

const NoTasksTypography = styled(Typography)`
  color: ${({ theme }) => theme.colours.greys.darkGrey5};
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
interface Props {
  dense?: boolean;
  workOrder: {
    workOrderId: string;
    workOrderNumber: string;
    shutdownNumber: string;
    status: string;
    tasks: string;
    type: string;
    unitNumber: string;
    facilityName: string;
    ucr: string;
    equipmentTag: string;
    deferralCode?: string;
    deferralNumber?: string;
    title?: string;
    critCat: string;
    location: string;
    description: string;
    longDescription: string;
    worktype: string;
    crewworkgroup: string;
    plusoutagecode: string;
    targcompdate: string;
    actfinish: string;
  };
}

const workOrderUrl = (number: string | number) =>
  `https://prod-maximo.corp.brucepower.com/maximo/ui/maximo.jsp?event=loadapp&value=pluswotr&uniqueid=${number}`;
const WorkOrderTaskTable = (props: any) =>
  isFeatureEnabled('echoWorkOrderRefreshUi') ? (
    <WorkOrderTaskTableRefreshUi {...props} />
  ) : (
    <WorkOrderTaskTableCurrentUi {...props} />
  );

const WorkOrderTasksEmpty = () =>
  isFeatureEnabled('echoWorkOrderRefreshUi') ? (
    <Box
      px={5}
      pb={5}
    >
      <NoTasksTypography>No tasks to display.</NoTasksTypography>
    </Box>
  ) : (
    <Box
      px={5}
      pb={5}
    >
      <CarlaText>No tasks</CarlaText>
    </Box>
  );
type WorkOrderDetailSummaryProps = {
  workOrder: Props['workOrder'];
  dense: Props['dense'];
  showViewMore: ReturnType<typeof useToggle>['value'];
  onToggleViewMore: ReturnType<typeof useToggle>['onToggle'];
};

const WorkOrderDetailSummary = ({ workOrder, showViewMore, onToggleViewMore, dense }: WorkOrderDetailSummaryProps) => {
  const {
    workOrderNumber,
    workOrderId,
    shutdownNumber,
    status,
    type,
    unitNumber,
    facilityName,
    ucr,
    equipmentTag,
    deferralCode,
    deferralNumber,
    title,
    critCat,
    location,
    description,
    worktype,
    crewworkgroup,
    plusoutagecode,
    targcompdate,
    actfinish,
  } = workOrder;
  return (
    <Grid
      container
      direction="column"
      spacing={1}
    >
      <Grid
        item
        container
        spacing={1}
      >
        <Grid item>
          <DetailHeader>
            {R.isNil(workOrderId) ? (
              `WO #${workOrderNumber}`
            ) : (
              <a
                target="_blank"
                rel="noreferrer"
                href={workOrderUrl(workOrderId)}
              >{`WO #${workOrderNumber}`}</a>
            )}
          </DetailHeader>
        </Grid>
        <Grid item>
          <DetailHeader>{R.isNil(title) ? '' : `${title}`}</DetailHeader>
        </Grid>
      </Grid>
      <Grid item>
        <WorkGridContainerDetail>
          <WorkGridItemDetail>{`Shutdown # ${shutdownNumber || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Facility: ${facilityName || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Unit: ${unitNumber || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Work Order Type: ${type || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Work Order status: ${status || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Unit conditions: ${ucr || 'N/A'}`}</WorkGridItemDetail>
        </WorkGridContainerDetail>
      </Grid>
      <Grid item>
        <WorkGridContainerDetail>
          <WorkGridItemDetail>{`Equipment Tag: ${equipmentTag || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`CC: ${critCat || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Deferral Code: ${deferralCode || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Deferral Number: ${deferralNumber || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Location: ${location || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Description: ${description || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Work Type: ${worktype || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Crew Work Group: ${crewworkgroup || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Plus Outage Code: ${plusoutagecode || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Target Completion Date: ${targcompdate || 'N/A'}`}</WorkGridItemDetail>
          <WorkGridItemDetail>{`Actual Finish Date: ${actfinish || 'N/A'}`}</WorkGridItemDetail>
        </WorkGridContainerDetail>
      </Grid>
      {!dense && (
        <>
          <Grid
            item
            container
            justifyContent="flex-end"
          >
            <ControlButton onClick={onToggleViewMore}>View {showViewMore ? 'less' : 'more'}</ControlButton>
          </Grid>
          <Grid item>
            <Collapse
              in={showViewMore}
              unmountOnExit
            >
              <Grid
                container
                direction="column"
                spacing={2}
              >
                <Grid item>
                  <TaskTitle>Work Order Details</TaskTitle>
                </Grid>
                <Grid item>
                  <LongDescription text={R.propOr('', 'details', workOrder)} />
                  <LongDescription text={R.propOr('', 'longDescription', workOrder)} />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </>
      )}
    </Grid>
  );
};
const WorkOrderDetails = ({ workOrder, dense = false }: Props) => {
  const { tasks } = workOrder;
  const { value: showViewMore, onToggle: onToggleViewMore } = useToggle();

  return (
    <Grid
      container
      direction="column"
    >
      <Grid item>
        <Box p={5}>
          <WorkOrderDetailSummary
            workOrder={workOrder}
            showViewMore={showViewMore}
            onToggleViewMore={onToggleViewMore}
            dense={dense}
          />
        </Box>
      </Grid>
      <Grid item>{R.isEmpty(tasks) ? <WorkOrderTasksEmpty /> : <WorkOrderTaskTable tasks={tasks} />}</Grid>
    </Grid>
  );
};

export default WorkOrderDetails;
