import styled from 'styled-components';
import UnstyledPaper from '@material-ui/core/Paper';
import Page from '../../components/Page';
import { DashboardPageContextProvider } from './hooks/useDashboardPage';
import DashboardContent from './components/DashboardContent';
import isFeatureEnabled from '../../utils/feature-flags';
import { BreadcrumbBroadcaster } from '../../context/breadcrumbs';

const Paper = styled(UnstyledPaper)`
  display: flex;
  flex-direction: column;
`;

const DashboardPage = () => {
  return (
    <>
      <BreadcrumbBroadcaster pushCrumb="Home" />
      <Page
        title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Dashboard View'}
        bodyComponent={<Paper square />}
      >
        <DashboardPageContextProvider>
          <DashboardContent />
        </DashboardPageContextProvider>
      </Page>
    </>
  );
};

export default DashboardPage;
