import { Box, CircularProgress, Typography } from '@material-ui/core';
import EngHoldsTable from './components/EngHoldsTable';
import PowerBiReport from '../../../../../components/PowerBiReport';
import * as R from 'ramda';
import { useRelatedRecords } from '../../../hooks/useRelatedRecords';
const EngineeringTab = () => {
  const reportId = R.pathOr('', ['echoConfig', 'erPowerBiReportId'], window);
  const reportPageName = R.pathOr('', ['echoConfig', 'erPowerBiPageName'], window);
  const { getEngRecords, loading } = useRelatedRecords();
  const engRecords = getEngRecords();
  const ticketIds = engRecords.map(record => record.ticketid);
  return (
    <Box>
      {loading && <CircularProgress />}
      {!loading && reportId && reportPageName && engRecords.length > 0 && (
        <PowerBiReport
          title="ERs"
          pageName={reportPageName}
          reportId={reportId}
          filter={`Daily_x0020_DMS_x0020_Export/ER in ('${ticketIds.join(`','`)}')`}
          height="50vh"
        />
      )}
      {!loading && engRecords.length > 0 && <EngHoldsTable />}
      {!loading && engRecords.length === 0 && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Typography
            variant="h6"
            color="textSecondary"
          >
            No related ERs
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default EngineeringTab;
