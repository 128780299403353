import styled from 'styled-components';
import SearchField from './SearchField';
import StatusFilter from './StatusFilter';
import CrewWorkGroupFilter from './CrewWorkGroupFilter';
import Collapse from '@material-ui/core/Collapse';
import chevronIcon from '../../../../../../assets/filter-chevron.svg';
import useToggle from '../../../../../../hooks/toggle';
import isFeatureEnabled from '../../../../../../utils/feature-flags';

const Container = styled.div`
  border-bottom: ${({ theme }) => theme.borders.thin.solid2};
  padding: 15px;
  width: 100%;
`;

const FilterContainer = styled.div`
  border-bottom: ${({ theme }) => theme.borders.thin.solid2};
  width: 100%;
`;

const Header = styled.div`
  padding-left: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  border-bottom: ${({ theme }) => theme.borders.thin.solid2};
  font: ${({ theme }) => theme.fonts.bold14};
  align-self: flex-start;
  flex-direction: row;
  display: flex;
  cursor: pointer;
`;

const ChevronIcon = styled.img.attrs({
  src: chevronIcon,
})`
  margin-right: 10px;
  display: block;
  height: 12px;
  width: 8px;
  transform: ${({ open }) => (open ? 'rotate(90deg)' : 'none')};
  transition: transform 0.3s;
  cursor: pointer;
`;

const SearchFilter = ({
  query,
  onChangeQuery,
  applicableStates,
  onChangeApplicableState,
  selectedWorkCrew,
  onChangeSelectedWorkCrew,
  records,
  filteredRecords,
}) => {
  const { value: showWorkCrewOptions, onToggle: onToggleShowWorkCrewOptions } = useToggle(false);

  return (
    <>
      <Container>
        <SearchField
          value={query}
          onChange={onChangeQuery}
        />
        <StatusFilter
          records={records}
          filteredRecords={filteredRecords}
          applicableStates={applicableStates}
          onChange={onChangeApplicableState}
        />
      </Container>
      {isFeatureEnabled('crewWorkGroupFilter') && records.length > 0 && 'workOrderNumber' in records[0] && (
        <FilterContainer>
          <Header onClick={onToggleShowWorkCrewOptions}>
            <ChevronIcon open={showWorkCrewOptions} />
            Filter Work Crew ({selectedWorkCrew.length})
          </Header>
          <Collapse
            in={showWorkCrewOptions}
            unmountOnExit
          >
            <CrewWorkGroupFilter
              selectedWorkCrew={selectedWorkCrew}
              onChange={onChangeSelectedWorkCrew}
            />
          </Collapse>
        </FilterContainer>
      )}
    </>
  );
};

export default SearchFilter;
