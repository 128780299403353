import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import Tabs from '../../../components/Tabs/Tabs';
import RiskOwnerForm from './form/RiskOwnerForm';
import { RiskApproverForm } from './form/RiskApproverForm';
import useRiskManagement from '../../../context/risk-management';
import RiskEnvironmentGroupForm from './form/RiskEnvironmentGroupForm';
import UnstyledTabPanel from '../../../components/Tabs/TabPanel';
import { EnvironmentalGroupTab, RiskApproverTab, RiskOwnerTab } from './RiskFormTabs';
import { BreadcrumbBroadcaster, BreadcrumbTitleBroadcaster } from '../../../context/breadcrumbs';
import elementTypePrefix from '../../../utils/element-type-prefix';

export type RiskFormTab = 'owner' | 'approver' | 'environmentalGroup';

const TabPanel = styled(UnstyledTabPanel)`
  padding: 0;
  border-radius: 2px;
`;

interface Props {
  value: RiskFormTab;
  onChange: (_: any, newValue: RiskFormTab) => void;
  tabs: RiskFormTab[];
}

const RiskFormTabGroup = ({ value, onChange, tabs }: Props) => {
  const { data, riskOwnerForm, myElements } = useRiskManagement();
  const { pathname, search, hash, state } = useLocation();
  const history = useHistory();
  const {
    params: { riskId: paramRiskId },
  } = useRouteMatch<{
    riskId: string;
  }>();
  const isEditPage = pathname.endsWith(`/${paramRiskId}/edit`);
  const isReviewPage = pathname.endsWith(`/${paramRiskId}/review`);
  const isArchivePage = pathname.endsWith(`/${paramRiskId}/archive`);
  const isNewPage = pathname.endsWith('/new');
  const currentStatus = (data?.status || '').toUpperCase();
  const targetPage =
    (paramRiskId && currentStatus === 'DRAFT' && '/edit') ||
    (['APPROVED', 'REJECTED', 'CLOSED', 'EDITED'].includes(currentStatus) && '/archive') ||
    (['SUBMITTED', 'ENV_REQUIRED'].includes(currentStatus) && '/review') ||
    (!paramRiskId && '/new') ||
    '';
  const isStatusPageMismatch =
    (targetPage === '/edit' && !(isNewPage || isEditPage)) ||
    (targetPage === '/archive' && !isArchivePage) ||
    (targetPage === '/review' && !isReviewPage) ||
    (targetPage === '/new' && !isNewPage) ||
    targetPage === ''; // empty string is else condition back to dashboard
  // truthy risk id means data was loaded successfully but undefined is a record that didn't load
  if (isStatusPageMismatch && (data.riskId || typeof data.riskId === 'undefined')) {
    if (!targetPage) {
      history.replace('/app/business-equipment-risks');
    } else {
      const replacePath =
        targetPage === '/new'
          ? '/app/business-equipment-risks/new'
          : `${pathname
              .split('/')
              .slice(0, pathname.split('/').length - 2)
              .join('/')}/${paramRiskId}${targetPage}`;
      history.replace(`${replacePath}${search}${hash}`, state);
    }
  }
  const crumbText =
    ((isEditPage || riskOwnerForm?.approverEditing) && 'Edit') ||
    (isReviewPage && 'Review') ||
    (isArchivePage && 'View') ||
    (isNewPage && 'New');
  const currentElement =
    myElements &&
    myElements.length > 0 &&
    riskOwnerForm?.watchElementId &&
    myElements.find(({ elementId }) => elementId === riskOwnerForm?.watchElementId);
  const pageTitle = currentElement
    ? `[${elementTypePrefix(currentElement.elementType)}] ${currentElement.elementName} (${
        currentElement.facilityName
      })`
    : '';

  return (
    <>
      {crumbText && <BreadcrumbBroadcaster pushCrumb={crumbText} />}
      {pageTitle && <BreadcrumbTitleBroadcaster pushTitle={pageTitle} />}
      <Tabs
        value={value}
        onChange={onChange}
      >
        {tabs.map(tab => {
          switch (tab) {
            case 'owner':
              return (
                <RiskOwnerTab
                  key="risk-owner-tab"
                  value={tab}
                />
              );
            case 'approver':
              return (
                <RiskApproverTab
                  key="risk-approver-tab"
                  value={tab}
                />
              );
            default:
              return (
                <EnvironmentalGroupTab
                  key="environmental-group-tab"
                  value={tab}
                />
              );
          }
        })}
      </Tabs>
      <TabPanel
        value="owner"
        currentTab={value}
      >
        <RiskOwnerForm />
      </TabPanel>
      {tabs.includes('approver') && (
        <TabPanel
          value="approver"
          currentTab={value}
        >
          <RiskApproverForm riskManagementId={data.riskId} />
        </TabPanel>
      )}
      {tabs.includes('environmentalGroup') && (
        <TabPanel
          value="environmentalGroup"
          currentTab={value}
        >
          <RiskEnvironmentGroupForm
            status={data.status}
            riskManagementId={data.riskId}
          />
        </TabPanel>
      )}
    </>
  );
};

export default RiskFormTabGroup;
