import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { format } from 'date-fns';
import { useUsers } from '../../../../hooks/useUsers';
import styled from 'styled-components';
import { useSchedule } from '../../../../hooks/useSchedule';
const gridStyle = { minHeight: 300, minWidth: '100%', padding: '5px' };
const NotesGrid = styled(ReactDataGrid)`
  .InovuaReactDataGrid__cell__content {
    overflow-wrap: break-word;
    white-space: normal;
  }
`;

export const ScheduleNotesTable = () => {
  const { getUser } = useUsers();
  const { scheduleNotes, loading } = useSchedule();

  const columns = [
    {
      name: 'note',
      header: 'Note',
      defaultWidth: 100,
      defaultFlex: 1,
      render: ({ value }: any) => {
        return <div>{value}</div>;
      },
    },
    {
      name: 'createdBy',
      header: 'Created By',
      defaultWidth: 150,
      render: ({ value }: any) => getUser(value)?.name,
    },
    {
      name: 'createdAt',
      header: 'Created On',
      defaultWidth: 150,
      render: ({ value }: any) => (value ? format(new Date(value), 'ddMMMyyyy HH:mm').toUpperCase() : ''),
    },
  ];

  return (
    <NotesGrid
      idProperty="id"
      style={{ ...gridStyle }}
      enableKeyboardNavigation={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuLockOptions={false}
      showColumnMenuTool={false}
      columns={columns}
      dataSource={scheduleNotes}
      rowHeight={null}
      loading={loading}
    />
  );
};
