import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import FormGroup from '@material-ui/core/FormGroup';
import Box from '@material-ui/core/Box';
import CheckboxField from '../../../../../../components/CheckboxField';
import recordApplicability, { RECORD_APPLICABILITY } from '../../utils';

const StatusRecordFilter = styled(Box)`
  display: flex;
  align-items: center;
`;

const RecordAmount = styled(Box)`
  color: ${({ theme }) => theme.colours.greens.green3};
`;

const StatusFilter = ({ applicableStates, onChange, filteredRecords }) => {
  const byApplicability = R.groupBy(record => recordApplicability(R.prop('applicable')(record)));
  const sortedRecords = byApplicability(filteredRecords);

  const handleChange = state => e => {
    onChange(state, e.target.checked);
  };

  const getCount = filter => R.pipe(R.prop(filter), R.length)(sortedRecords);

  return (
    <FormGroup>
      <StatusRecordFilter>
        <CheckboxField
          name="not-evaluated"
          label="Not Evaluated"
          value={R.includes('not-evaluated', applicableStates)}
          onChange={handleChange('not-evaluated')}
        />
        <RecordAmount>({getCount(RECORD_APPLICABILITY.NOT_EVALUATED) || '0'})</RecordAmount>
      </StatusRecordFilter>
      <StatusRecordFilter>
        <CheckboxField
          name="applicable"
          label="Applied"
          value={R.includes('applicable', applicableStates)}
          onChange={handleChange('applicable')}
        />
        <RecordAmount>({getCount(RECORD_APPLICABILITY.APPLIED) || '0'})</RecordAmount>
      </StatusRecordFilter>
      <StatusRecordFilter>
        <CheckboxField
          name="inapplicable"
          label="Not Applied"
          value={R.includes('inapplicable', applicableStates)}
          onChange={handleChange('inapplicable')}
        />
        <RecordAmount>({getCount(RECORD_APPLICABILITY.NOT_APPLIED) || '0'})</RecordAmount>
      </StatusRecordFilter>
    </FormGroup>
  );
};

export default StatusFilter;
