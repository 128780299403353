import React from 'react';
import styled from 'styled-components';
import UnstyledTypography from '@material-ui/core/Typography';
import UnstyledProfileImage from '../default/ProfileImage';
import { dateToString } from '../../utils/date-format';
import { useUsers } from '../../pages/cwt/hooks/useUsers';
import SiteButtonGroup from './SiteButtonGroup';
import { useRouteMatch } from 'react-router-dom';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { getUserName } from '../../auth/utils';
import { Alert } from '@material-ui/lab';
import { isAllowedForRoles } from '../../auth/roles';
const ProfileImage = styled(UnstyledProfileImage)``;
const Typography = styled(UnstyledTypography)``;

const Container = styled.div`
  padding-right: 18px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;

  ${ProfileImage} {
    margin-right: 7px;
    margin-left: 32px;
  }

  ${Typography} {
    font: ${({ theme }) => theme.fonts.medium12};
    color: ${({ theme }) => theme.colours.greys.darkGrey3};
    line-height: 20px;
    text-transform: uppercase;
  }

  .header-name {
    margin-right: 32px;
  }

  .header-date {
    margin-right: 50px;
  }
`;

const ContainerTwo = styled.div`
  padding-right: 18px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;

  ${ProfileImage} {
    margin-right: 7px;
    margin-left: 32px;
  }

  ${Typography} {
    font: ${({ theme }) => theme.fonts.medium12};
    color: ${({ theme }) => theme.colours.greys.darkGrey3};
    line-height: 20px;
    text-transform: uppercase;
  }

  .header-name {
    margin-right: 32px;
  }

  .header-date {
    margin-right: 50px;
  }
`;

interface Props {
  className?: string;
  hideIcon?: boolean;
  onIconClick(): void;
}

const Header = ({ className }: Props) => {
  const { userSite } = useUsers();
  const match = useRouteMatch({
    path: '/cwt/wr/:id/screening',
  });

  return (
    <AuthenticatedTemplate>
      <Container className={className}>
        {!isAllowedForRoles(['CWTUser', 'CWTAdmin']) ? (
          <Alert severity="warning">The app is in read-only mode, contact an admin for full permission.</Alert>
        ) : (
          <div></div>
        )}

        <ContainerTwo>
          {userSite !== undefined && !match && <SiteButtonGroup />}
          <ProfileImage />
          <Typography className="header-name">{getUserName()}</Typography>
          <Typography className="header-date">{dateToString(new Date())}</Typography>
        </ContainerTwo>
      </Container>
    </AuthenticatedTemplate>
  );
};
export default Header;
