import { Report } from '../../hooks/report';
import elementTypePrefix from '../../utils/element-type-prefix';

const helpers = {
  generateReportTitle: (report: Report) =>
    `[${elementTypePrefix(report.elementType)}] ${report.elementName} (${report.facilityName}) - ${report.year}-Q${
      report.quarter
    }`,
};
export default helpers;
