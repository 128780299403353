import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { useActions } from '../../../hooks/useActions';
import Api from '../../../../../api';
import UnstyledDateInput from '../../../../../components/DateInput';
import UnstyledSelect from '../../../../../components/Select';
import { ActionFlag, ActionGroup, ActionStatus } from '../../../../../types/cwt/actions';
import { useUsers } from '../../../hooks/useUsers';
import styled from 'styled-components';
import { TextField, Tooltip } from '@material-ui/core';
import { User } from '../../../../../types/cwt/user';
import UnstyledEditRoundedIcon from '@material-ui/icons/EditRounded';
import { ActionHistory } from '../../../actions/components/ActionsHistory';
import { getActionStatusColor } from '../../../actions/utils';
import { useMemo } from 'react';
import { onRenderActionRow } from '../../../wo/components/utils';
import { useError } from '../../ErrorContextProvider';

const EditRoundedIcon = styled(UnstyledEditRoundedIcon)`
  color: ${({ theme }) => theme.colours.teals.teal1};
  cursor: pointer;
`;
const gridStyle = { minWidth: '100%', padding: '1px' };
const Select = styled(UnstyledSelect)`
  .MuiSelect-root {
    margin-top: 0px;
  }
`;

const DateInput = styled(UnstyledDateInput)`
  #date-picker-inline-undefined.MuiInput-input {
    text-transform: uppercase;
  }
`;

interface Props {
  style: any;
  showOnlyTPlusOneActions?: boolean;
  onEditAction?: () => void;
}

const ActionsTable = ({ style, showOnlyTPlusOneActions, onEditAction }: Props) => {
  const {
    actions,
    loading,
    setLoading,
    fetchActions,
    setActions,
    groups,
    statuses,
    flags,
    handleOpenEditActionDialog,
    sortByStatus,
  } = useActions();
  const { users } = useUsers();
  const { handleError } = useError();

  const actionList = useMemo(() => {
    return actions.filter((action: any) => !showOnlyTPlusOneActions || action.tPlusOne);
  }, [showOnlyTPlusOneActions, actions]);

  const userOptions = users.map((user: User) => ({
    label: user.name,
    value: user.id,
  }));
  const statusOptions = statuses.map((status: ActionStatus) => ({
    label: status.label,
    value: status.id,
  }));
  const groupOptions = groups.map((group: ActionGroup) => ({
    label: group.label,
    value: group.id,
  }));
  const flagOptions = flags.map((flag: ActionFlag) => ({
    label: flag.label,
    value: flag.id,
  }));
  const columns = [
    {
      name: 'description',
      header: 'Description',
      defaultWidth: 400,
      render: ({ value }: any, { cellProps }: any) => {
        return (
          <Tooltip
            title={<h2>{value}</h2>}
            placement="top-start"
          >
            <TextField
              value={value}
              onChange={(x: any) => {
                onEditValueChange({ value: x.target.value, columnId: 'description', data: cellProps.data });
              }}
              onBlur={(x: any) => {
                onEditComplete({ value: x.target.value, columnId: 'description', data: cellProps.data });
              }}
              onKeyDown={(x: any) => {
                if (x.key === 'Enter') {
                  x.target.blur();
                }
              }}
              fullWidth
            />
          </Tooltip>
        );
      },
    },
    {
      name: 'statusId',
      header: 'Status',
      defaultWidth: 120,
      defaultFlex: 1,
      sort: sortByStatus,
      style: ({ data }: any) => {
        return { backgroundColor: getActionStatusColor(data.statusId) };
      },
      render: ({ value }: any, { cellProps }: any) => {
        return (
          <Select
            value={value}
            options={statusOptions}
            onChange={(x: any) => {
              onEditValueChange({ value: x.target.value, columnId: 'statusId', data: cellProps.data });
              onEditComplete({ value: x.target.value, columnId: 'statusId', data: cellProps.data });
            }}
            fullWidth
            disableUnderline
          />
        );
      },
    },
    {
      name: 'groupId',
      header: 'Group',
      defaultWidth: 120,
      defaultFlex: 1,
      render: ({ value }: any, { cellProps }: any) => {
        return (
          <Select
            value={value}
            options={groupOptions}
            onChange={(x: any) => {
              onEditValueChange({ value: x.target.value, columnId: 'groupId', data: cellProps.data });
              onEditComplete({ value: x.target.value, columnId: 'groupId', data: cellProps.data });
            }}
            fullWidth
            disableUnderline
          />
        );
      },
    },
    {
      name: 'owner',
      header: 'Owner',
      defaultWidth: 200,
      render: ({ value }: any, { cellProps }: any) => {
        return (
          <Select
            value={value}
            options={userOptions}
            onChange={(x: any) => {
              onEditValueChange({ value: x.target.value, columnId: 'owner', data: cellProps.data });
              onEditComplete({ value: x.target.value, columnId: 'owner', data: cellProps.data });
            }}
            fullWidth
            disableUnderline
          />
        );
      },
    },
    {
      name: 'tbe',
      header: 'TBE',
      defaultWidth: 100,
      defaultFlex: 1,
      rendersInlineEditor: true,
      render: ({ value }: any, { cellProps }: any) => {
        return (
          <DateInput
            value={value}
            onChange={x => {
              onEditValueChange({ value: x, columnId: 'tbe', data: cellProps.data });
              onEditComplete({ value: x, columnId: 'tbe', data: cellProps.data });
            }}
            InputProps={{ disableUnderline: true }}
            dateFormat="ddMMMyyyy"
            disableTyping
          />
        );
      },
    },
    {
      name: 'tcd',
      header: 'TCD',
      defaultWidth: 100,
      defaultFlex: 1,
      rendersInlineEditor: true,
      editable: true,
      render: ({ value }: any, { cellProps }: any) => {
        return (
          <DateInput
            value={value}
            onChange={x => {
              onEditValueChange({ value: x, columnId: 'tcd', data: cellProps.data });
              onEditComplete({ value: x, columnId: 'tcd', data: cellProps.data });
            }}
            InputProps={{ disableUnderline: true }}
            dateFormat="ddMMMyyyy"
            disableTyping
          />
        );
      },
    },
    {
      name: 'flags',
      header: 'Flags',
      defaultWidth: 130,
      defaultFlex: 1,
      editable: true,
      render: ({ value }: any, { cellProps }: any) => {
        return (
          <Select
            value={value}
            options={flagOptions}
            onChange={(x: any) => {
              onEditValueChange({ value: x.target.value, columnId: 'flags', data: cellProps.data });
            }}
            onClose={() => {
              onEditComplete({ value: cellProps.data.flags, columnId: 'flags', data: cellProps.data });
            }}
            renderValue={selected => {
              const selectedFlags = flags.filter((flag: ActionFlag) => selected.includes(flag.id));
              return selectedFlags.map((flag: ActionFlag) => flag.label).join(', ');
            }}
            fullWidth
            disableUnderline
            multiple
          />
        );
      },
    },
    {
      header: 'Edit',
      defaultWidth: 80,
      render: ({ data }: any) => <EditRoundedIcon onClick={() => handleOpenEditActionDialog(data.id)} />,
    },
    {
      name: 'history',
      header: 'History',
      defaultWidth: 80,
      enableColumnFilterContextMenu: false,
      render: ({ data }: any) => <ActionHistory history={data.history} />,
    },
  ];

  const onEditComplete = async ({ value, columnId, data }: any) => {
    const updatedActionIndex = actions.findIndex((action: any) => action.id === data.id);
    try {
      if (updatedActionIndex !== -1) {
        setLoading(true);
        await Api.cwt.updateActionInline({
          id: data.id,
          [columnId]: value,
        });
      }

      if (onEditAction) await onEditAction();
    } catch (error) {
      handleError(error as Error);
    }
    fetchActions();
  };

  const onEditValueChange = ({ value, columnId, data }: any) => {
    const updatedActionIndex = actions.findIndex((action: any) => action.id === data.id);
    if (updatedActionIndex !== -1) {
      const updatedAction = { ...actions[updatedActionIndex], [columnId]: value };
      const updatedActionsArray = [...actions];
      updatedActionsArray[updatedActionIndex] = updatedAction;
      setActions(updatedActionsArray);
    }
  };

  return (
    <ReactDataGrid
      style={{ ...gridStyle, ...style }}
      idProperty="wonum"
      enableKeyboardNavigation={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuLockOptions={false}
      showColumnMenuTool={false}
      columns={columns}
      dataSource={actionList}
      onEditComplete={onEditComplete}
      onEditValueChange={onEditValueChange}
      loading={loading}
      rowStyle={onRenderActionRow}
      defaultSortInfo={{ name: 'statusId', dir: 1 }}
    />
  );
};

export default ActionsTable;
