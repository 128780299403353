import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import Api from '../../../api';
import { User } from '../../../types/cwt/user';
import { getUserId } from '../../../auth/utils';
import { useError } from '../components/ErrorContextProvider';
interface useUsersValue {
  users: User[];
  loadingUsers: boolean;
  getUser: (id: string) => User | undefined;
  userSite: string | null | undefined;
  setUserSite: (site: string | null) => void;
  canApplyBookmark: (type: string) => boolean;
}

interface Props {
  children: ReactNode;
}
const UsersContext = createContext<useUsersValue | undefined>(undefined);

export const UsersContextProvider = ({ children }: Props) => {
  const [userMap, setUserMap] = useState<Map<string, User>>(new Map());
  const [userSite, setUserSite] = useState<string | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
  const [usersAppliedDefaultBookmarks, setUsersAppliedDefaultBookmarks] = useState<string[]>([]);
  const { handleError } = useError();
  const userId = getUserId();

  const handleSetUsers = (users: User[]) => {
    const userMap = new Map<string, User>();
    users.forEach(user => {
      userMap.set(user.id, user);
    });
    setUserMap(userMap);
    setUsers(users);
    setUserSite((userId && userMap.get(userId)?.facilityId) || null);
  };

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        const res = await Api.cwt.getAllUsers();
        handleSetUsers(res);
      } catch (error) {
        handleError(error as Error);
      }
      setLoadingUsers(false);
    };

    if (loadingUsers) {
      getAllUsers();
    }
  }, [loadingUsers]);

  const getUser = (id: string) => {
    return userMap.get(id);
  };

  const canApplyBookmark = (type: string): boolean => {
    const applied = usersAppliedDefaultBookmarks.find(appliedBookmark => appliedBookmark === type);
    if (!applied) {
      const newList = [...usersAppliedDefaultBookmarks, type];
      setUsersAppliedDefaultBookmarks(newList);
      return true;
    }
    return false;
  };

  return (
    <UsersContext.Provider value={{ users, loadingUsers, getUser, userSite, setUserSite, canApplyBookmark }}>
      {children}
    </UsersContext.Provider>
  );
};

export const useUsers = () => {
  const context = useContext(UsersContext);
  if (context === undefined) {
    throw new Error('useUsers must be used within a UsersContextProvider');
  }
  return context;
};
