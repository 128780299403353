import { AppBar, Box, Tab, Typography, makeStyles } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useWODetailsModal } from '../../hooks/useWODetailsModal';
import styled from 'styled-components';
import ActionsTab from './ActionsTab';
import { getHandleTabChangeFunction } from './utils';
import { ActionsContextProvider } from '../../hooks/useActions';
import { ScheduleContextProvider } from '../../hooks/useSchedule';
import { TurnoverContextProvider } from '../../hooks/useTurnover';
import { NotesContextProvider } from '../../hooks/useNotes';
import TurnoverTab from './TurnoverTab';
import ScheduleTab from './ScheduleTab';
import NotesTab from './NotesTab';
import SupplyChainTab from './SupplyChainTab';
import EngineeringTab from './EngineeringTab';
import OverviewTab from './OverviewTab';
import { OverviewContextProvider } from '../../hooks/useOverview';
import AssessingTab from './AssessingTab';
import { WorkorderTasksContextProvider } from '../../hooks/useWorkorderTasks';
import isFeatureEnabled from '../../../../utils/feature-flags';
import WOFlags from './WOFlags';
import { TPlusOneTab } from './TPlusOneTab';
import { WorkorderDelayReasonsContextProvider } from './TPlusOneTab/hook/useWorkorderDelayReasons';
import { renderMaximoLink } from '../../wo/components/utils';
import { RelatedRecordsContextProvider } from '../../hooks/useRelatedRecords';

const StyledAppBar = styled(AppBar)`
  background-color: rgb(38, 36, 82, 0.95);
  max-width: 100%;
`;

const StyledTab = styled(Tab)`
  width: 'auto';
  min-width: 0;
  &.disabled {
    display: none;
  }
`;

const BoldTypography = styled(Typography)`
  font-weight: bold;
`;

const useStyles = makeStyles(() => ({
  indicator: {
    backgroundColor: 'rgb(15, 154, 154);',
    width: '100%',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap', // This allows the tabs to wrap onto multiple lines
  },
}));

const DetailTabs = () => {
  const classes = useStyles();

  const { activeTab, handleSetActiveTab, workorder, tplus1 } = useWODetailsModal();
  return (
    <TabContext value={activeTab.toString()}>
      <StyledAppBar position="static">
        <TabList
          onChange={getHandleTabChangeFunction(handleSetActiveTab)}
          classes={{ indicator: classes.indicator, flexContainer: classes.flexContainer }}
        >
          <StyledTab
            id="tplusone-tab"
            label="T+1"
            value="tplusone"
            className={tplus1 ? '' : 'disabled'}
          />

          <StyledTab
            id="overview-tab"
            label="Overview"
            value="overview"
          />
          <StyledTab
            id="assessing-tab"
            label="Assessing"
            value="assessing"
          />
          <StyledTab
            label="Supply Chain"
            value="sc"
          />
          <StyledTab
            label="Engineering"
            value="eng"
          />
          <StyledTab
            label="Actions"
            value="actions"
          />
          <StyledTab
            label="Notes"
            value="notes"
          />
          <StyledTab
            label="Schedule"
            value="schedule"
          />
          <StyledTab
            label="Turnover"
            value="turnover"
          />
        </TabList>
      </StyledAppBar>
      {workorder && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            p={1}
            borderBottom={1}
          >
            <Box flex={1}>
              <BoldTypography>WONUM:</BoldTypography>
              {renderMaximoLink(workorder.id, workorder.wonum)}
            </Box>
            <Box flex={1}>
              <BoldTypography>Priority:</BoldTypography>
              {workorder.wopriority}
            </Box>
            <Box flex={1}>
              <BoldTypography>Type:</BoldTypography>
              {workorder.worktype}
            </Box>
            {isFeatureEnabled('cwt_tplus1') && (
              <Box flex={1}>
                <BoldTypography>Life:</BoldTypography>
                {workorder.totallife} Days
              </Box>
            )}
            <Box flex={1}>
              <BoldTypography>Status:</BoldTypography>
              {workorder.status}
            </Box>
            <Box flex={2}>
              <BoldTypography>Description:</BoldTypography>
              {workorder.description}
            </Box>
            <Box flex={1}>
              <BoldTypography>Location:</BoldTypography>
              {workorder.location}
            </Box>
            <Box flex={1}>
              <BoldTypography>Crew:</BoldTypography>
              {workorder.crewworkgroup}
            </Box>
            {isFeatureEnabled('workorderFlags') && (
              <Box flex={1}>
                <BoldTypography>Flags:</BoldTypography>
                <WOFlags workorder={workorder} />
              </Box>
            )}
          </Box>
          <ActionsContextProvider workorderId={workorder.id}>
            <RelatedRecordsContextProvider workorder={workorder}>
              <TabPanel value={'overview'}>
                <OverviewContextProvider>
                  <OverviewTab />
                </OverviewContextProvider>
              </TabPanel>
              <TabPanel value={'assessing'}>
                {isFeatureEnabled('CWT_Assessing') && (
                  <WorkorderTasksContextProvider>
                    <AssessingTab />
                  </WorkorderTasksContextProvider>
                )}
              </TabPanel>
              <TabPanel value={'sc'}>
                <SupplyChainTab />
              </TabPanel>
              <TabPanel value={'eng'}>
                <EngineeringTab />
              </TabPanel>
              <TabPanel value={'actions'}>
                <ActionsTab />
              </TabPanel>
              <TabPanel value={'notes'}>
                <NotesContextProvider>
                  <NotesTab />
                </NotesContextProvider>
              </TabPanel>
              <TabPanel value={'schedule'}>
                <ScheduleContextProvider workorder={workorder}>
                  <TurnoverContextProvider workorder={workorder}>
                    <ScheduleTab />
                  </TurnoverContextProvider>
                </ScheduleContextProvider>
              </TabPanel>
              <TabPanel value={'turnover'}>
                <TurnoverContextProvider workorder={workorder}>
                  <TurnoverTab />
                </TurnoverContextProvider>
              </TabPanel>
              <TabPanel value={'tplusone'}>
                <WorkorderDelayReasonsContextProvider workorder={workorder}>
                  <TPlusOneTab />
                </WorkorderDelayReasonsContextProvider>
              </TabPanel>
            </RelatedRecordsContextProvider>
          </ActionsContextProvider>
        </>
      )}
    </TabContext>
  );
};

export default DetailTabs;
