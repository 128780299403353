import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import api from '../api';
import * as R from 'ramda';
import { useLocation } from 'react-router-dom';
import {
  ImpactProbabilityAssessmentDetail,
  LabelOptions,
  RiskManagementConstants,
  USER_ROLES,
} from '../types/risk-management';
import { MyElement } from '../types/my';
import { RiskOwnerForm, useRiskOwnerForm } from '../pages/business-equip-risks/components/hooks/useRiskOwnerForm';
import { useRiskManagementUsers } from './business-equipment-risk-admin';
import isFeatureEnabled from '../utils/feature-flags';

interface RiskManagementData {
  riskId: number | undefined;
  status: string | undefined;
  originalRiskId?: number;
  prevDecisionType?: string;
  approverEdited?: boolean;
  riskOwnerUpdatedAt?: string;
  riskOwnerUpdatedBy?: string;
  awaitingEscalation?: boolean;
}

interface RiskManagementContextType {
  constants: RiskManagementConstants;
  data: RiskManagementData;
  myElements: MyElement[];
  riskOwnerForm: RiskOwnerForm | null;
  riskApprovers: LabelOptions[];
  riskEnvGroupUsers: LabelOptions[];
  impactAssessments: ImpactProbabilityAssessmentDetail[];
  fetchImpactAssessments: () => Promise<void>;
  confirmOpportunityOrThreatChange: boolean;
  handleConfirmOpportunityOrThreatChange: () => void;
  handleCancelOpportunityOrThreatChange: () => void;
  onOpportunityOrThreatChange: () => void;
  currentRiskManagementUserRoles: USER_ROLES[];
}

export const RiskManagementContext = createContext<RiskManagementContextType>({
  data: {
    riskId: 0,
    status: 'DRAFT',
  },
  constants: {
    IMPACT_PROBABILITY: null,
  },
  myElements: [],
  riskOwnerForm: null,
  riskApprovers: [],
  riskEnvGroupUsers: [],
  impactAssessments: [],
  fetchImpactAssessments: () => Promise.resolve(),
  confirmOpportunityOrThreatChange: false,
  handleConfirmOpportunityOrThreatChange: () => void 0,
  handleCancelOpportunityOrThreatChange: () => void 0,
  onOpportunityOrThreatChange: () => void 0,
  currentRiskManagementUserRoles: [],
});

const useRiskManagement = () => {
  const context = useContext(RiskManagementContext);
  if (R.isNil(context)) {
    throw new Error('useRiskManagement must be used inside an RiskManagementProvider');
  }
  return context;
};

export const RiskManagementProvider = (props: React.PropsWithChildren) => {
  const { pathname } = useLocation();
  const riskId = useMemo(() => {
    const path = pathname.split('/')[3];
    if (!isNaN(parseInt(path, 10))) {
      return parseInt(path, 10);
    }
    return null;
  }, [pathname]);
  // if pathname has /new, then create a new form
  // else, get the form data from the api

  const [data, setData] = useState<RiskManagementData>({
    riskId: 0,
    status: 'DRAFT',
  });
  const [constants, setConstants] = useState({ IMPACT_PROBABILITY: null });
  const [myElements, setMyElements] = useState<MyElement[]>([]);
  const [riskApprovers, setRiskApprovers] = useState<LabelOptions[]>([]);
  const [riskEnvGroupUsers, setRiskEnvGroupUsers] = useState<LabelOptions[]>([]);
  const [impactAssessments, setImpactAssessments] = useState<ImpactProbabilityAssessmentDetail[]>([]);
  const [confirmOpportunityOrThreatChange, setConfirmOpportunityOrThreatChange] = useState(false);
  const currentRiskManagementUserRoles = useRiskManagementUsers();

  const { watchOpportunityOrThreat, setValue, isReadOnly, ...riskOwnerForm } = useRiskOwnerForm(data.riskId);

  const getRiskApprovers = useCallback(async () => {
    try {
      const allUsers = await api.getRiskManagementUsers();
      const formattedApproversUsers = allUsers
        .filter(u => u.roles.includes(USER_ROLES.APPROVER))
        .map(u => ({ label: u.name, value: u.id }));
      const formattedEnvGroupUsers = allUsers
        .filter(u => u.roles.includes(USER_ROLES.ENVIRONMENT_GROUP))
        .map(u => ({ label: u.name, value: u.id }));
      setRiskApprovers(formattedApproversUsers);
      setRiskEnvGroupUsers(formattedEnvGroupUsers);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const getImpactProbabilityConstants = useCallback(async () => {
    try {
      const impactProbabilityConstants = await api.getImpactProbabilityConstants();
      setConstants({
        ...constants,
        IMPACT_PROBABILITY: impactProbabilityConstants,
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getRiskManagementForm = useCallback(async () => {
    if (!riskId) return;
    try {
      const riskManagementForm = await api.getRiskGeneralForm(riskId);
      if (typeof riskManagementForm.riskManagementId === 'undefined') {
        setData({
          riskId: undefined,
          status: undefined,
        });
        return;
      }
      setData({
        riskId: riskManagementForm.riskManagementId,
        status: riskManagementForm.status,
        originalRiskId: riskManagementForm.originalRiskManagementForm,
        prevDecisionType: riskManagementForm.prevDecisionType,
        approverEdited: riskManagementForm.approverEdited,
        riskOwnerUpdatedAt: riskManagementForm.riskOwnerUpdatedAt,
        riskOwnerUpdatedBy: riskManagementForm.riskOwnerUpdatedBy,
        awaitingEscalation: riskManagementForm.awaitingEscalation,
      });
    } catch (err) {
      console.log(err);
    }
  }, [data, riskId]);

  const newFormInfo = useCallback(async () => {
    try {
      const createRiskManagementForm = await api.addNewRiskManagementForm();
      setData({
        ...data,
        riskId: createRiskManagementForm.riskId,
        status: createRiskManagementForm.status,
      });
    } catch (err) {
      console.log(err);
    }
  }, [data]);

  const getMyRiskOwnerElements = useCallback(async () => {
    try {
      const elements =
        isFeatureEnabled('riskOwnersGroup') && !isReadOnly
          ? await api.getMyRiskOwnersGroup()
          : await api.getMyElements();
      setMyElements(elements);
    } catch (err) {
      console.error(err);
    }
  }, [isReadOnly]);

  const fetchImpactAssessments = useCallback(async () => {
    if (riskId || data.riskId) {
      const paramRiskId = riskId || data.riskId;
      if (paramRiskId) {
        const ips = await api.getImpactProbabilityAssessments(paramRiskId);
        setImpactAssessments(ips);
      }
    }
  }, [riskId, data.riskId]);

  const handleCancelOpportunityOrThreatChange = useCallback(() => {
    setValue('opportunityOrThreat', watchOpportunityOrThreat === 'opportunity' ? 'threat' : 'opportunity');
    setConfirmOpportunityOrThreatChange(false);
  }, [watchOpportunityOrThreat]);

  const handleConfirmOpportunityOrThreatChange = useCallback(async () => {
    if (riskId || data.riskId) {
      const paramRiskId = riskId || data.riskId;
      if (paramRiskId) {
        await api.deleteImpactProbabilityAssessments(paramRiskId);
        void fetchImpactAssessments();
      }
    }
    setConfirmOpportunityOrThreatChange(false);
  }, [riskId]);

  const onOpportunityOrThreatChange = () => {
    if (watchOpportunityOrThreat && impactAssessments?.length && impactAssessments.length > 0)
      setConfirmOpportunityOrThreatChange(true);
  };

  useEffect(() => {
    void getImpactProbabilityConstants();
    void getMyRiskOwnerElements();
    void getRiskApprovers();
    void fetchImpactAssessments();
    if (pathname.includes('/new')) {
      void newFormInfo();
    } else {
      void getRiskManagementForm();
    }

    return () => {
      setData({
        riskId: 0,
        status: 'DRAFT',
      });
      setImpactAssessments([]);
    };
  }, [pathname]);

  const value = {
    data,
    riskId,
    constants,
    myElements,
    riskOwnerForm: { watchOpportunityOrThreat, setValue, isReadOnly, ...riskOwnerForm },
    riskApprovers,
    impactAssessments,
    fetchImpactAssessments,
    confirmOpportunityOrThreatChange,
    handleCancelOpportunityOrThreatChange,
    handleConfirmOpportunityOrThreatChange,
    onOpportunityOrThreatChange,
    currentRiskManagementUserRoles,
    riskEnvGroupUsers,
  };

  return (
    <RiskManagementContext.Provider
      value={value}
      {...props}
    />
  );
};

export default useRiskManagement;
