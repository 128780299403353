import styled from 'styled-components';
import { onRenderRow, renderMaximoLink, renderMaximoTaskLink } from '../../wo/components/utils';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { useAssessing } from '../../hooks/useAssessing';
import { useWODetailsModal } from '../../hooks/useWODetailsModal';
import { TypeRowProps } from '@inovua/reactdatagrid-community/types';
import { renderIsASD } from './utils';
import { useState } from 'react';
import format from 'date-fns/format';

const AsessGrid = styled(ReactDataGrid)`
  .InovuaReactDataGrid__row-cell-wrap:hover {
    background-color: transparent !important;
    border: unset !important;
    border: 2px solid gray !important;
  }

  .InovuaReactDataGrid__row-cell-wrap {
    border: 2px solid transparent !important;
  }
  .InovuaReactDataGrid__row-cell-wrap:active {
    transform: scale(0.997);
    cursor: pointer;
  }

  .InovuaReactDataGrid__row--selected > .InovuaReactDataGrid__row-cell-wrap {
    border: 2px solid rgb(38, 36, 82) !important;
  }
  .InovuaReactDataGrid__row.InovuaReactDataGrid__row--selected > .InovuaReactDataGrid__row-cell-wrap {
  }
`;

const gridStyle = { minHeight: 600, minWidth: '100%' };

const TaskTable = () => {
  const {
    taskLoading,
    setGridRef,
    getWorkorderByWonum,
    setTaskLoading,
    setSelectedWonum,
    dataSource,
    setTaskFilters,
    taskFilters,
  } = useAssessing();

  const { handleOpen } = useWODetailsModal();

  const onRowClick = (rowProps: TypeRowProps) => {
    setSelectedWonum(rowProps.data.parent);
  };

  const onRowDoubleClick = () => {
    setTaskLoading(true);
    handleOpen('assessing');
  };

  const [sortDir, setSortDir] = useState(0);

  const task_columns = [
    {
      name: 'wonum',
      header: 'Task Num',
      defaultWidth: 100,
      render: ({ data }: any) => renderMaximoTaskLink(data.workorderid, data.wonum),
    },
    {
      name: 'parent',
      header: 'Parent',
      defaultWidth: 90,
      render: ({ data }: any) => renderMaximoLink(getWorkorderByWonum(data.parent)?.id, data.parent),
    },
    { name: 'taskid', header: 'Task Id', defaultWidth: 60 },
    { name: 'taskLife', header: 'Task Life', defaultWidth: 60 },
    { name: 'wopriority', header: 'Priority', defaultWidth: 75 },
    { name: 'worktype', header: 'WO Type', defaultWidth: 85 },
    { name: 'status', header: 'Status', defaultWidth: 90 },
    { name: 'siteid', header: 'Facility', defaultWidth: 70 },
    { name: 'location', header: 'Location', defaultWidth: 100 },
    { name: 'description', minWidth: 80, header: 'Task Description' },
    { name: 'workorderDescription', minWidth: 80, header: 'WO Description' },
    { name: 'isasd', header: 'Assess.', defaultWidth: 90, textAlign: 'center' as const, render: renderIsASD },
    { name: 'crewworkgroup', header: 'Task Crew', defaultWidth: 110 },
    { name: 'leadCrewworkgroup', header: 'Lead Crew', defaultWidth: 110 },
    { name: 'ownergroup', header: 'Owner Group', defaultWidth: 110 },
    { name: 'plusplanner', header: 'Assessor', defaultWidth: 110 },
    { name: 'bpspm', header: 'SPM', defaultWidth: 70 },
    {
      name: 'schedstart',
      header: 'Schd. Start',
      sort: (value1: any, value2: string | undefined) => {
        if (!value2) return -sortDir;
        if (!value1) return sortDir;
        return Date.parse(value1) - Date.parse(value2);
      },
      defaultWidth: 105,
      render: (value: any) => {
        const date = new Date(value.data.schedstart);
        return <span>{value.data.schedstart ? format(date, 'ddMMMyyyy').toUpperCase() : null}</span>;
      },
    },
    {
      name: 'schedfinish',
      header: 'Schd. Finish',
      sort: (value1: any, value2: string | undefined) => {
        if (!value2) return -sortDir;
        if (!value1) return sortDir;
        return Date.parse(value1) - Date.parse(value2);
      },
      defaultWidth: 105,
      render: (value: any) => {
        const date = new Date(value.data.schedfinish);
        return <span>{value.data.schedfinish ? format(date, 'ddMMMyyyy').toUpperCase() : null}</span>;
      },
    },
  ];

  return (
    <AsessGrid
      style={gridStyle}
      idProperty="id"
      rowStyle={onRenderRow}
      pagination
      filterValue={taskFilters}
      onFilterValueChange={setTaskFilters}
      enableKeyboardNavigation={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuLockOptions={false}
      showColumnMenuTool={false}
      columns={task_columns}
      dataSource={dataSource}
      showCellBorders={false}
      loading={taskLoading}
      enableColumnFilterContextMenu={false}
      onReady={setGridRef}
      onRowClick={onRowClick}
      onRowDoubleClick={onRowDoubleClick}
      onSortInfoChange={(sortInfo: any) => {
        setSortDir(sortInfo?.dir ?? 0);
      }}
    />
  );
};

export default TaskTable;
