import { Path, Remote } from '../../types';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types';
import { buildFilterObject } from '../../../utils/build-filter-object';
import { TaskFilters } from '../../../pages/cwt/hooks/useAssessing';

const getTasks =
  (path: Path, remote: Remote) =>
  (page?: number, pageSize?: number, filterValue?: TypeFilterValue, bookMarkFilter?: TaskFilters) => {
    const filtersObject = buildFilterObject(filterValue);
    return remote.post(path(`/query`), { ...filtersObject, page, pageSize, bookMarkFilter });
  };

const getByWonumTasks = (path: Path, remote: Remote) => (wonum: string | number) => remote.get(path(`/${wonum}`));

const searchTasks = (path: Path, remote: Remote) => (searchTerm: string, searchColumn: string, maxResults?: number) =>
  remote.get(path(`/search`), { params: { searchTerm, searchColumn, maxResults } });

const task = (path: Path, remote: Remote) => ({
  getTasks: getTasks(path, remote),
  getByWonumTasks: getByWonumTasks(path, remote),
  searchTasks: searchTasks(path, remote),
});

export default task;
