import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import NewRisk from './pages/new-risk/NewRisk';
import LandingPage from './pages/landing-page/LandingPage';
import OutagePlansProvider from '../../context/outage-plans';
import { RiskManagementProvider } from '../../context/risk-management';
import BusinessObjectivesProvider from '../../context/business-objective';
import MSMProgramsProvider from '../../context/msm-programs';
import RiskReviewPage from '../approvals/risk-review';
import { AllElementsProvider } from '../../hooks/all-elements';
import { BreadcrumbBroadcaster } from '../../context/breadcrumbs';

const BusinessEquipRisksPage = ({ match }: RouteComponentProps) => (
  <BusinessObjectivesProvider>
    <OutagePlansProvider>
      <RiskManagementProvider>
        <MSMProgramsProvider>
          <AllElementsProvider>
            <BreadcrumbBroadcaster pushCrumb="Business / Equipment Risk" />
            <Switch>
              <Route
                exact
                path={match.path}
                component={LandingPage}
              />
              <Route
                path={`${match.path}/risk-review`}
                component={RiskReviewPage}
              />
              <Route
                path={`${match.path}/new`}
                component={NewRisk}
              />
              <Route
                path={`${match.path}/:riskId/edit`}
                component={NewRisk}
              />
              <Route
                path={`${match.path}/:riskId/archive`}
                component={NewRisk}
              />
              <Route
                path={`${match.path}/:riskId/review`}
                component={NewRisk}
              />
            </Switch>
          </AllElementsProvider>
        </MSMProgramsProvider>
      </RiskManagementProvider>
    </OutagePlansProvider>
  </BusinessObjectivesProvider>
);
export default BusinessEquipRisksPage;
