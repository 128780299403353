import { createContext, useContext, useEffect, useState } from 'react';
import Api from '../../../api';
import { Crew } from '../../../types/cwt/crews';
import { useError } from '../components/ErrorContextProvider';

interface useCrewsValue {
  crews: Crew[];
  loading: boolean;
}

const CrewsContext = createContext<useCrewsValue | undefined>(undefined);

export const CrewsContextProvider = ({ children }: React.PropsWithChildren) => {
  const [crews, setCrews] = useState<Crew[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { handleError } = useError();
  useEffect(() => {
    const getCrews = async () => {
      try {
        const crews = await Api.cwt.getAllCrews();

        setCrews(crews);
      } catch (error) {
        handleError(error as Error);
      }
      setLoading(false);
    };

    void getCrews();
  }, [loading]);

  return (
    <CrewsContext.Provider
      value={{
        crews,
        loading,
      }}
    >
      {children}
    </CrewsContext.Provider>
  );
};

export const useCrews = () => {
  const context = useContext(CrewsContext);
  if (context === undefined) {
    throw new Error('useCrews must be used within a CrewsContextProvider');
  }
  return context;
};
