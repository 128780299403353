import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import useReport from '../../../hooks/report';
import Page from '../../../components/Page';
import ReportReview from '../../../components/ReportReview';
import { Downloader } from '../../../hooks/downloader';
import isFeatureEnabled from '../../../utils/feature-flags';
import { BreadcrumbBroadcaster, BreadcrumbTitleBroadcaster } from '../../../context/breadcrumbs';
import helpers from '../../../components/ReportReview/helpers';

const ReportReviewPage = () => {
  const { reportId } = useParams();
  const { report } = useReport(reportId);
  const { pathname } = useLocation();

  return (
    <>
      {pathname.startsWith('/app/health-reports') ? (
        <>
          <BreadcrumbBroadcaster pushCrumb="Health Reports" />
          <BreadcrumbBroadcaster pushCrumb="View" />
        </>
      ) : (
        <>
          <BreadcrumbBroadcaster pushCrumb="Approvals" />
          <BreadcrumbBroadcaster pushCrumb="Report Review" />
        </>
      )}

      <Page title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Report Review'}>
        {report && (
          <>
            <BreadcrumbTitleBroadcaster pushTitle={helpers.generateReportTitle(report)} />
            <ReportReview report={report} />
          </>
        )}
      </Page>
    </>
  );
};

const RRPage = () => (
  <Downloader>
    <ReportReviewPage />
  </Downloader>
);

export default RRPage;
