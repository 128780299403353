import { Box, Button, ButtonGroup, Snackbar, Tooltip, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useOverview } from '../../../../hooks/useOverview';
import { useEffect } from 'react';
import format from 'date-fns/format';
const ReadyButtonGroup = () => {
  const {
    workorder,
    workorderCanReady,
    woReady,
    handleGetReadyState,
    handleSetOverride,
    woReadyApiError,
    setWoReadyApiError,
    woReadyInfo,
  } = useOverview();

  useEffect(() => {
    if (!workorder) return;
    void handleGetReadyState();
  }, [handleGetReadyState, workorderCanReady]);

  return (
    <Box margin={'5px'}>
      <Typography variant="h6">Work Order Ready Verification</Typography>
      <Box
        display="flex"
        justifyContent="flex-start"
      >
        <ButtonGroup>
          <Button
            variant={!woReady ? 'contained' : 'outlined'}
            color="primary"
            onClick={async () => {
              await handleSetOverride(false);
              await handleGetReadyState();
            }}
          >
            Not Ready
          </Button>
          <Tooltip
            title={
              !workorderCanReady
                ? 'Work order can only be ready if all actions are completed'
                : 'Set workorder to ready'
            }
            placement="right"
          >
            <Button
              variant={woReady === true ? 'contained' : 'outlined'}
              color="primary"
              disabled={!workorderCanReady}
              onClick={async () => {
                await handleSetOverride(true);
                await handleGetReadyState();
              }}
            >
              Ready
            </Button>
          </Tooltip>
        </ButtonGroup>
        {woReady && (
          <Typography style={{ marginLeft: '10px', fontStyle: 'italic' }}>{`Verified by ${
            woReadyInfo?.woReadyBy
          } on ${format(new Date(woReadyInfo?.woReadyAt ?? ''), 'ddMMMyyyy HH:mm:ss').toUpperCase()}`}</Typography>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!woReadyApiError}
        autoHideDuration={3000}
        onClose={() => {
          setWoReadyApiError('');
        }}
      >
        <Alert
          onClose={() => {
            setWoReadyApiError('');
          }}
          severity="error"
        >
          {woReadyApiError}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ReadyButtonGroup;
