import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { isAxiosError } from 'axios';
import { createContext, useContext, useState, ReactNode, useMemo } from 'react';

interface ErrorContextType {
  handleError: (error: Error) => void;
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const useError = (): ErrorContextType => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error('useError must be used within an ErrorProvider');
  }
  return context;
};

interface ErrorProviderProps {
  children: ReactNode;
}

export const ErrorContextProvider = ({ children }: ErrorProviderProps) => {
  const [error, setError] = useState<Error | null>(null);

  const handleError = (error: Error) => {
    if (isAxiosError(error) && error.response) {
      if (error.response.status === 403) {
        setError(new Error('You are not authorized to perform this action'));
        return;
      }
    }
    throw error;
  };

  const contextValue = useMemo(() => ({ handleError }), [handleError]);

  return (
    <ErrorContext.Provider value={contextValue}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!error}
        message={error?.message}
        autoHideDuration={3000}
        onClose={() => setError(null)}
      >
        <Alert
          onClose={() => setError(null)}
          severity="error"
        >
          {error?.message}
        </Alert>
      </Snackbar>

      {children}
    </ErrorContext.Provider>
  );
};
