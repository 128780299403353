import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import useFilteredRecords from '../../hooks/filtered-records';
import useIndicatorRecordLabels from '../../hooks/indicator-record-labels';
import useDeselector from '../../hooks/deselector';
import useIndicatorRecordId from '../../../../../../hooks/indicator-record-id';
import RecordList from './RecordList';
import SearchFilter from './SearchFilter';
import useIndicator from '../../../../../../hooks/indicator';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0;
  border: ${({ theme }) => theme.borders.thin.solid2};
`;

const toIndicatorSpecificRecord = R.curry((indicatorRecordId, indicatorLabels, record) => ({
  recordId: indicatorRecordId(record),
  labels: indicatorLabels(record),
  applicable: record.applicable,
}));

interface Props {
  className?: string;
  records: any;
  selectedRecord: any;
  isLoading?: boolean;
  onSelectRecordId: (recordId: string) => void;
}

const RecordListPanel = ({ className, records, selectedRecord, isLoading, onSelectRecordId }: Props) => {
  const { shortName } = useIndicator();

  const {
    filteredRecords,
    query,
    applicableStates,
    selectedWorkCrew,
    setQuery,
    setApplicableState,
    setSelectedWorkCrew,
  } = useFilteredRecords(shortName, records);

  const indicatorRecordId = useIndicatorRecordId(shortName);
  const indicatorLabels = useIndicatorRecordLabels(shortName);

  useDeselector(filteredRecords, selectedRecord, indicatorRecordId, onSelectRecordId);

  const indicatorSpecificRecords = R.map(
    toIndicatorSpecificRecord(indicatorRecordId, indicatorLabels),
    filteredRecords
  );

  const selectedRecordId = selectedRecord ? indicatorRecordId(selectedRecord) : undefined;

  return (
    <Container className={className}>
      <SearchFilter
        query={query}
        onChangeQuery={setQuery}
        applicableStates={applicableStates}
        onChangeApplicableState={setApplicableState}
        selectedWorkCrew={selectedWorkCrew}
        onChangeSelectedWorkCrew={setSelectedWorkCrew}
        records={records}
        filteredRecords={indicatorSpecificRecords}
      />
      <RecordList
        records={indicatorSpecificRecords}
        selectedRecordId={selectedRecordId}
        isLoading={isLoading}
        onSelectRecordId={onSelectRecordId}
      />
    </Container>
  );
};

export default RecordListPanel;
