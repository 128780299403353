import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import UsersPage from './components/UsersPage';
import DashboardsPage from './components/DashboardsPage';
import DesignSystemPage from './components/DesignSystemPage';
import ElementsPage from './components/ElementsPage';
import MCRUnitsPage from './components/MCRUnitsPage';
import RecentReportsPage from './components/RecentReportsPage';
import { MCRUnitsProvider } from '../../hooks/mcr-units';
import AdminCategoriesPage from './components/AdminCategoriesPage';
import RiskManagementPage from './components/RiskManagementPage';
import OutagePlansProvider from '../../context/outage-plans';
import BusinessObjectivesProvider from '../../context/business-objective';
import MSMProgramsProvider from '../../context/msm-programs';
import BusinessEquipmentRiskAdmin from './components/BusinessEquipmentRisk';
import BusinessEquipmentRiskAdminProvider from '../../context/business-equipment-risk-admin';
import { isAllowedForRoles } from '../../auth/roles';
import { BreadcrumbBroadcaster } from '../../context/breadcrumbs';

const AdminPage = () => {
  const { path } = useRouteMatch();
  if (!isAllowedForRoles(['Admin'])) {
    return <Redirect to="/app/dashboard" />;
  }

  return (
    <>
      <BreadcrumbBroadcaster pushCrumb="Admin" />
      <Switch>
        <Route path={`${path}/users`}>
          <UsersPage />
        </Route>
        <Route path={`${path}/dashboards`}>
          <DashboardsPage />
        </Route>
        <Route path={`${path}/design-system`}>
          <DesignSystemPage />
        </Route>
        <Route path={`${path}/elements`}>
          <ElementsPage />
        </Route>
        <Route path={`${path}/mcr-units`}>
          <MCRUnitsProvider>
            <MCRUnitsPage />
          </MCRUnitsProvider>
        </Route>
        <Route path={`${path}/recent-reports`}>
          <RecentReportsPage />
        </Route>
        <Route path={`${path}/admin-categories`}>
          <AdminCategoriesPage />
        </Route>
        <Route path={`${path}/risk-management`}>
          <OutagePlansProvider>
            <BusinessObjectivesProvider>
              <MSMProgramsProvider>
                <RiskManagementPage />
              </MSMProgramsProvider>
            </BusinessObjectivesProvider>
          </OutagePlansProvider>
        </Route>
        <Route path={`${path}/business-equipment-risks`}>
          <BusinessEquipmentRiskAdminProvider>
            <BusinessEquipmentRiskAdmin />
          </BusinessEquipmentRiskAdminProvider>
        </Route>
        <Route>
          <Redirect to={`${path}/users`} />
        </Route>
      </Switch>
    </>
  );
};

export default AdminPage;
