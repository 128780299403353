const greys = {
  grey05: '#505050',
  greyE1: '#E1E0E0',
  darkGrey1: '#333333',
  darkGrey2: '#3f3f3f',
  darkGrey3: '#4a4a4a',
  darkGrey4: '#575959',
  darkGrey5: '#757575',
  darkGrey6: '#858383',
  mediumGrey1: '#979797',
  mediumGrey2: '#9B9B9B',
  mediumGrey3: '#9d9d9d',
  mediumGrey4: '#BFBEBE',
  lightGrey1: '#D0CECE',
  lightGrey2: '#DCDBDD',
  lightGrey3: '#E0E0E0',
  lightGrey4: '#E2E0E0',
  lightGrey5: '#EAE7E7',
  lightGrey6: '#ebebeb',
  lightGrey7: '#EEEEEE',
  lightGrey8: '#f1efef',
  lightGrey9: '#FAF8F8',
};

const purples = {
  purple1: '#262452',
  purple2: '#61607c',
  purple3: '#3A3282',
};

const reds = {
  red1: '#ED0722',
  red2: '#E53B3C',
  red3: '#ED311C',
};

const blues = {
  blue1: '#2A5AB8',
  blue2: '#113F9A',
  blue3: '#2373D5',
  blue4: '#bad2ef',
  blue5: '#2B61A4',
  blue6: '#324255',
};

const teals = {
  teal1: '#0F9A9A',
  teal2: '#127070',
  teal3: '#D6EBEB',
  teal4: '#EAF3F3',
  teal5: '#E6F2F2',
  tealLightBg: '#F3F8F9',
};

const pinks = {
  pink1: '#BE207A',
};

const white = '#ffffff';
const black = '#000000';

const greens = {
  green1: '#19A95E',
  green2: '#0A990F',
  green3: '#009EA4',
};

const yellows = {
  yellow1: '#F8E45B',
};
const theme = {
  colours: {
    iconBlue: '#3747ac',
    notClickableGrey: '#A6A6A6',
    disabledGrey: '#D9D9D9',
    black,
    white,
    greys,
    pinks,
    reds,
    greens,
    teals,
    blues,
    purples,
    yellows,
    transparent: 'transparent',
    menu: {
      background: purples.purple1,
      text: greys.lightGrey7,
    },
    scorecard: {
      grey: {
        text: white,
        background: greys.mediumGrey1,
      },
      green: {
        text: white,
        background: greens.green1,
      },
      white: {
        text: black,
        background: white,
      },
      yellow: {
        text: black,
        background: yellows.yellow1,
      },
      red: {
        text: white,
        background: reds.red2,
      },
    },
    action: {
      primary: '#4050B5',
      secondary: '#2D387F',
    },
    error: {
      primary: '#D3302F',
    },
    success: {
      primary: '#4CAF50',
    },
  },
  shadows: {
    boxShadow1: `0 3px 8px 0 ${greys.lightGrey4}`,
    boxShadow2: `2px 1px 9px 0 ${greys.lightGrey5}`,
    boxShadow3: `0 2px 7px 0 ${greys.mediumGrey4}`,
    boxShadow4: `0 3px 8px 0 ${greys.lightGrey9}`,
    boxShadow5: `0 2px 7px 0 ${greys.lightGrey8}`,
    boxShadow6: `0 3px 8px 0 ${greys.lightGrey8}`,
  },
  borders: {
    thin: {
      solid1: `1px solid ${greys.lightGrey1}`,
      solid2: `1px solid ${greys.lightGrey4}`,
      solid3: `1px solid ${greys.mediumGrey2}`,
      solid4: `1px solid ${greys.lightGrey2}`,
      solid5: `1px solid ${greys.mediumGrey1}`,
      solid6: `1px solid ${greys.darkGrey5}`,
      solid7: `1px solid ${greys.darkGrey6}`,
      solid8: `1px solid ${greys.mediumGrey4}`,
    },
    medium: {
      solid1: `2px solid ${greys.mediumGrey3}`,
      solid2: `2px solid ${greys.lightGrey4}`,
    },
    teal: {
      dashed1: `1px dashed ${teals.teal1}`,
      solid1: `1px solid ${teals.teal1}`,
      solid2: `1px solid ${teals.teal1}`,
    },
  },
  fonts: {
    normal12: '12px Roboto',
    normal13: '13px Roboto',
    normal14: '14px Roboto',
    normal22: '400 22px Roboto',
    medium10: '500 10px Roboto',
    medium12: '500 12px Roboto',
    medium13: '500 13px Roboto',
    medium14: '500 14px Roboto',
    medium16: '500 16px Roboto',
    medium20: '500 20px Roboto',
    bold11: 'bold 11px Roboto',
    bold12: 'bold 12px Roboto',
    bold14: 'bold 14px Roboto',
    bold16: 'bold 16px Roboto',
    monospace: '12px monospace',
  },
};

export default theme;
